import React from 'react';
import './Version.css';
import version from './version.json';

function VersionCard() {
  return (
    <div className="version">
      <a href="https://highwoodemissions.com/" target="_blank" rel="noreferrer">
        A passion for developing
        <br />
        Env: {version.gitBranch} <br />
        Build: {version.buildTime}
      </a>
    </div>
  );
}

export default VersionCard;
