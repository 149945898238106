import React, { useEffect, useState } from 'react';

import Plot from 'react-plotly.js';
import { Button, Col } from 'react-bootstrap';
import { EquipmentTypeView, EquipmentView } from '../../../dataAPI/Views';
import { getConfig, getLayout } from '../../plots/Layouts';
import { Dark24 } from '../../../utils/Colors';
import isDarkMode from '../../../utils/lightDarkMode';
import { convertArrayToObject } from '../../../utils/convertArrayToObject';

export interface IProps {
  equipment: EquipmentView[];
  equipmentTypes: EquipmentTypeView[];
}

export default function EquipmentEmissionsVsType({
  equipment,
  equipmentTypes,
}: IProps) {
  const [inputs, setInputs] = useState(true);
  const [theme, setTheme] = useState(isDarkMode());
  const [equipmentTypeLookup, setEquipmentTypeLookup] = useState(
    convertArrayToObject(equipmentTypes, 'key', ['key', 'name'])
  );

  useEffect(() => {
    setTheme(isDarkMode());
  });

  useEffect(() => {
    setEquipmentTypeLookup(
      convertArrayToObject(equipmentTypes, 'key', ['key', 'name'])
    );
  }, [equipmentTypes]);

  if (!equipment || equipment.length === 0) return <> </>;

  const handleChange = (isBar: boolean) => {
    setInputs(isBar);
  };

  const summary: { [key: string]: [value: number] } = equipment.reduce(
    (p: any, c: any): any => {
      const key = c.type;
      const name = c.name;
      let value = c.yearlyEmissions;
      value += p[key] ? p[key].value : 0;
      return { ...p, [key]: { key, value, name } };
    },
    {}
  );

  const orderedList: any = Object.values(summary).sort(
    (a: any, b: any) => a.value - b.value
  );

  const x = orderedList.map((e: { key: string }) => {
    const name = equipmentTypeLookup[e.key]?.name ?? e.key;
    return name.substring(0, 30);
  });

  const y = orderedList.map((e: { value: number }) => e.value);

  let trace1;

  if (inputs)
    trace1 = {
      labels: x,
      values: y,
      type: 'pie',
      hoverinfo: 'text',
      hovertemplate: '%{label}<br><b>%{value:,.0f}</b><extra></extra>',
      hole: 0.4,
      marker: {
        colors: Dark24,
      },
      domain: { x: [0, 0.5] },
    };
  else
    trace1 = {
      x,
      y,
      type: 'bar',
      hoverinfo: 'text',
      hovertemplate: '%{x}<br><b>%{y}</b><extra></extra>',
      marker: {
        colors: Dark24,
      },
      domain: { x: [0, 0.5] },
    };

  const layout = getLayout(
    'Equipment Type vs Annual Emissions',
    'Equipment Type',
    'Annual Emissions (tCO\u2082e)',
    inputs, // Only show legend for Pie graph
    false,
    '',
    1,
    theme,
    450,
    'pie'
  );

  const traces = [trace1];

  return (
    <div className="chart__container  r-border">
      <div className="btn__container">
        <Button
          className="chart__toggle-btn btn btn-primary"
          onClick={() => handleChange(!inputs)}
        >
          {inputs ? 'Switch to Bar' : 'Switch to Pie'}
        </Button>
      </div>
      <Col>
        <Plot
          data={traces as any}
          layout={layout}
          useResizeHandler
          config={getConfig()}
        />
      </Col>
    </div>
  );
}
