import * as React from 'react';
import { useState, useEffect } from 'react';
import Plotly, { PlotMouseEvent } from 'plotly.js-dist-min';
import createPlotlyComponent from 'react-plotly.js/factory';
import { EquipmentView, SiteView } from '../../../dataAPI/Views';
import { IEmissionsBarType, IGraphBar } from '../chartTypes';
import { IEquipmentList } from '../../../data/Types';
import { getConfig, getLayout } from '../Layouts';

import barGraphDataBuilder from '../barGraphDataBuilder';

const Plot = createPlotlyComponent(Plotly);

export default function BarGraphOld({
  type,
  typeData,
  xTitle,
  yTitle,
  axisValues,
  title,
  groupBy,
  onClick,
  extraHooverRows,
  color = '',
  format = 'number',
  theme = 'light',
}: IEmissionsBarType) {
  const [dataList, setDataList] = useState<EquipmentView[] | SiteView[]>([]);
  const [barGraphData, setBarGraphData] = useState<IGraphBar[]>([]);
  const [layout, setLayout] = useState({});

  useEffect(() => {
    setViewData();
  }, [typeData, theme]);

  useEffect(() => {
    setGraphLayout();
  }, [dataList]);

  const setViewData = () => {
    if (type === 'equipment') {
      setEquipmentData();
    } else if (type === 'site') {
      setSiteData();
    }
  };

  const setEquipmentData = () => {
    let eList: EquipmentView[] = [];
    const equipmentObj: IEquipmentList = {};
    if (typeData && typeData.length > 0) {
      typeData.forEach((equipment) => {
        if (equipmentObj[equipment[groupBy]]) {
          equipmentObj[equipment[groupBy]].equipmentCount! += 1;
          equipmentObj[equipment[groupBy]].yearlyEmissions! +=
            equipment.yearlyEmissions!;
        } else {
          equipmentObj[equipment[groupBy]] = {
            name: equipment.name,
            type: equipment.type,
            site: equipment.site,
            key: equipment[groupBy],
            yearlyEmissions: equipment.yearlyEmissions,
            equipmentCount: 1,
          };
        }
        eList = Object.values(equipmentObj);
      });
    }
    setDataList(eList);
  };

  const setSiteData = () => {
    const sList: SiteView[] = [];
    if (typeData && typeData.length > 0) {
      typeData.forEach((site) => {
        sList.push({
          key: site.key,
          name: site.name,
          lat: site.lat,
          long: site.long,
          yearlyEmissions: site.yearlyEmissions,
          type: site.type,
          equipmentCount: site.equipmentCount,
        });
      });
    }
    setDataList(sList);
  };

  const setGraphLayout = () => {
    if (dataList) {
      const value = barGraphDataBuilder(
        dataList,
        axisValues,
        color,
        format,
        extraHooverRows
      );

      setBarGraphData(value);

      const graphLayout = getLayout(
        `<b>${title}</b>`,
        `<b>${xTitle}</b>`,
        `<b>${yTitle}</b>`,
        false,
        false,
        '',
        1,
        theme
      );

      setLayout(graphLayout);
    }
  };

  const onClickHandler = onClick
    ? (e: PlotMouseEvent) => onClick(e)
    : undefined;

  return (
    <Plot
      data={barGraphData as any}
      layout={layout}
      useResizeHandler
      style={{ width: '100%', height: '100%' }}
      onClick={onClickHandler}
      config={getConfig()}
    />
  );
}
