import * as React from 'react';
import { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { formatNumNavigator } from '../../../utils/format';
import { customTableStyles } from '../../../utils/customStyles';
import { EquipmentView } from '../../../dataAPI/Views';
import { IEquipmentList } from '../../../data/Types';

interface EquipmentTableProps {
  typeData: EquipmentView[];
  valueType: string;
  title: string;
}

export default function EquipmentTable({
  valueType,
  title,
  typeData,
}: EquipmentTableProps) {
  const [equipmentList, setEquipmentList] = useState<EquipmentView[]>([]);
  const [filteredList, setFilteredList] = useState<EquipmentView[]>([]);

  useEffect(() => {
    setFilteredList(typeData);
  }, [typeData]);

  useEffect(() => {
    if (valueType === 'equipment') {
      setEquipmentList(getEquipmentTypeValues() as EquipmentView[]);
    } else if (valueType === 'activityType') {
      setEquipmentList(getEquipmentActivityTypeValues() as EquipmentView[]);
    }
  }, [filteredList]);

  const getEquipmentTypeValues = (): EquipmentView[] => {
    const equipmentTypeValues: IEquipmentList = {};
    filteredList.forEach((equipment) => {
      // if (equipment.type && equipment.yearlyEmissions) {
      if (equipment.type) {
        if (equipmentTypeValues[equipment.type]) {
          equipmentTypeValues[equipment.type].equipmentTypeCount! += 1;

          equipmentTypeValues[equipment.type].yearlyEmissions! +=
            equipment.yearlyEmissions ? equipment.yearlyEmissions : 0;
        } else {
          equipmentTypeValues[equipment.type] = {
            key: equipment.type,
            name: equipment.name,
            type: equipment.type,
            site: equipment.site,
            yearlyEmissions: equipment.yearlyEmissions,
            equipmentTypeCount: 1,
            isCombustion: equipment.isCombustion!,
          };
        }
      }
    });
    return Object.values(equipmentTypeValues);
  };

  const getEquipmentActivityTypeValues = (): EquipmentView[] => {
    const equipmentActivityTypeValues: IEquipmentList = {};
    filteredList.forEach((equipment) => {
      if (equipment.activityType && equipment.yearlyEmissions) {
        if (equipmentActivityTypeValues[equipment.activityType]) {
          equipmentActivityTypeValues[
            equipment.activityType
          ].yearlyEmissions! += equipment.yearlyEmissions;
          equipmentActivityTypeValues[
            equipment.activityType
          ].equipmentTypeCount! += 1;
        } else {
          equipmentActivityTypeValues[equipment.activityType] = {
            key: equipment.activityType as string,
            site: equipment.site,
            name: equipment.name,
            type: equipment.type,
            yearlyEmissions: equipment.yearlyEmissions,
            equipmentTypeCount: 1,
            isCombustion: equipment.isCombustion,
          };
        }
      }
    });
    return Object.values(equipmentActivityTypeValues);
  };

  const columns: any = [
    {
      name: 'Activity Type',
      selector: (row: EquipmentView) => row.key,
      sortable: true,
      left: true,
      reorder: true,
    },
    {
      name: 'Annual Emissions (tCO2e)',
      selector: (row: EquipmentView) => row.yearlyEmissions,
      // format: (row: EquipmentView) => formatNumNavigator(row.yearlyEmissions),
      format: (row: EquipmentView) =>
        Math.round(row.yearlyEmissions!).toLocaleString(),
      sortable: true,
      right: true,
      reorder: true,
    },
    {
      name: 'Equipment Count',
      selector: (row: EquipmentView) => row.equipmentTypeCount,
      format: (row: EquipmentView) =>
        formatNumNavigator(row.equipmentTypeCount),
      sortable: true,
      right: true,
      reorder: true,
    },
  ];

  return (
    <DataTable
      title={title}
      columns={columns}
      data={equipmentList}
      defaultSortFieldId={1}
      striped
      pagination
      paginationPerPage={25}
      paginationRowsPerPageOptions={[25, 50, 100, 250, 500, 1000]}
      fixedHeader
      customStyles={customTableStyles}
      highlightOnHover
      dense
    />
  );
}
