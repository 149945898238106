import React, { useState, useContext, useEffect } from 'react';
import { Form, Col, Row, Button } from 'react-bootstrap';
import { DataAPIContext } from '../../contexts/data';
import { PathwayValuesView } from '../../dataAPI/Views';

export default function PathwayValuesCard() {
  const { dataClient, unpackResponse } = useContext(DataAPIContext);
  const [pathwayValues, setPathwayValues] =
    useState<Partial<PathwayValuesView>>();

  useEffect(() => {
    getPathwayValues();
  }, []);

  const getPathwayValues = async () => {
    const pathway = await unpackResponse(dataClient.getPathwayValues());
    setPathwayValues(pathway);
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value: number | boolean | string;
    const { id } = event.target;

    if (pathwayValues) {
      if (event.target.type === 'checkbox') {
        value = event.target.checked!;
      } else if (event.target.type === 'text') {
        value = event.target.value;
      } else {
        // parse numbers into numbers
        value =
          typeof parseFloat(event.target.value) === 'number'
            ? (value = +event.target.value!)
            : (value = event.target.value!);
      }
      setPathwayValues((prevValues) => ({ ...prevValues, [id]: value }));
    }
  };

  const onSave = async () => {
    await unpackResponse(dataClient.updatePathwayValues(pathwayValues));
  };

  return pathwayValues ? (
    <Col className="col-4 pathwayCard mx-auto my-3 p-3">
      <h1 className="mb-5 mt-3"> Pathway Values </h1>
      <Form>
        <Form.Group as={Row} className="mb-3" controlId="baseYear">
          <Form.Label column sm={5}>
            Base Year
          </Form.Label>
          <Col sm={7}>
            <Form.Control
              type="number"
              value={pathwayValues.baseYear}
              onChange={onChange}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="periods">
          <Form.Label column sm={5}>
            Number of Years
          </Form.Label>
          <Col sm={7}>
            <Form.Control
              type="number"
              value={pathwayValues.periods}
              onChange={onChange}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="costIncludesCap">
          <Form.Label column sm={5}>
            Include Capital Costs
          </Form.Label>
          <Col sm={7}>
            <Form.Check
              type="checkbox"
              checked={pathwayValues.costIncludesCap}
              onChange={onChange}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="costIncludesOp">
          <Form.Label column sm={5}>
            Include Operational Costs
          </Form.Label>
          <Col sm={7}>
            <Form.Check
              type="checkbox"
              checked={pathwayValues.costIncludesOp}
              onChange={onChange}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="costIncludesCarbonTax">
          <Form.Label column sm={5}>
            Include Carbon Tax?
          </Form.Label>
          <Col sm={5}>
            <Form.Check
              type="checkbox"
              checked={pathwayValues.costIncludesCarbonTax}
              onChange={onChange}
            />
          </Col>
        </Form.Group>
        <Form.Group
          as={Row}
          className="mb-3"
          controlId="aggregateCarbonTaxFactor"
        >
          <Form.Label column sm={5}>
            Carbon Tax Savings Correction
          </Form.Label>
          <Col sm={5}>
            <Form.Control
              type="number"
              value={pathwayValues.aggregateCarbonTaxFactor}
              onChange={onChange}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="costIncludesGrant">
          <Form.Label column sm={5}>
            Include Grants?
          </Form.Label>
          <Col sm={7}>
            <Form.Check
              type="checkbox"
              checked={pathwayValues.costIncludesGrant}
              onChange={onChange}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="region">
          <Form.Label column sm={5}>
            Region
          </Form.Label>
          <Col sm={5}>
            <Form.Control value={pathwayValues.region} onChange={onChange} />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="extendProjectLife">
          <Form.Label column sm={5}>
            Extend Project Life
          </Form.Label>
          <Col sm={7}>
            <Form.Check
              type="checkbox"
              checked={pathwayValues.extendProjectLife}
              onChange={onChange}
            />
            Recalc / Reload if you change this option.
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="debug">
          <Form.Label column sm={5}>
            Debug Flag?
          </Form.Label>
          <Col sm={7}>
            <Form.Check
              type="checkbox"
              checked={pathwayValues.debug}
              onChange={onChange}
            />
          </Col>
        </Form.Group>
      </Form>

      <div className="d-flex bd-highlight flex-row-reverse">
        <Button
          className="btn  btn-lg btn_min--width save__btn mx-1"
          onClick={onSave}
        >
          Save
        </Button>
      </div>
    </Col>
  ) : null;
}
