import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { Form } from 'react-bootstrap';
// import { Button } from "react-bootstrap";

function ProjList(props) {
  const { onClickRow, onSelect } = props;
  const [exclude, setExclude] = useState(false);
  const columns = [
    {
      name: 'key',
      id: 'key',
      selector: (row) => row.key,
      cell: (row) => (
        <Link
          className="tableTooltip"
          to={`/projectdetail/${encodeURIComponent(row.key)}`}
        >
          {row.key}
          <span className="tableTooltiptext">{row.name}</span>
        </Link>
      ),
      // left: true,
      compact: true,
    },
    {
      name: 'Implementation Date',
      selector: (row) => row.implementationDate,
      cell: (row) => row.implementationDate.toLocaleDateString(),
      center: true,
      // compact: true,
      omit: !props.showImplementationDate,
    },
    {
      name: 'First Year Abatement',
      id: 'abatementCost',
      selector: (row) => row.yearlyAbatementCost,
      cell: (row) => Math.round(row.yearlyAbatementCost).toLocaleString(),
      center: true,
      compact: true,
    },
    {
      name: 'Lifetime Emission Reductions',
      id: 'emissionReduction',
      selector: (row) => row.lifetimeReduction,
      cell: (row) => Math.round(row.lifetimeReduction).toLocaleString(),
      center: true,
      compact: true,
    },
    {
      name: 'Capital Cost ($)',
      id: 'cost',
      selector: (row) => row.capCost,
      cell: (row) => Math.round(row.capCost).toLocaleString(),
      center: true,
      compact: true,
    },
    {
      name: 'Status',
      id: 'status',
      selector: (row) => row.status,
      center: true,
      compact: true,
      omit: !props.showStatus,
    },

    {
      name: (
        <Form.Check
          type="switch"
          id="custom-switch"
          label="Exclude"
          checked={exclude}
          onChange={(e) => onExcludeChangeHandler(e)}
        />
      ),
      id: 'exclude',
      selector: (row) => row.exclude,
      cell: (row) => (row.exclude ? 'True' : ''),
      center: true,
      compact: true,
      width: '80px',
      omit: !props.showExclude,
    },
  ];

  const onExcludeChangeHandler = (e) => {
    setExclude(e.target.checked);
  };

  const filteredProjects = () => {
    if (!exclude) return props.projects;
    return props.projects.filter((p) => p.exclude === false);
  };

  // const filteredProjects = () =>
  //   !exclude
  //     ? props.projects
  //     : props.projects.filter((p) => p.exclude === false);

  return (
    <DataTable
      columns={columns}
      data={
        Object.keys(props.projects).length > 0 ? filteredProjects() : undefined
      }
      defaultSortFieldId={3}
      defaultSortAsc={false}
      pagination
      paginationPerPage={25}
      paginationRowsPerPageOptions={[5, 10, 25, 100]}
      highlightOnHover
      responsive
      pointerOnHover
      persistTableHead
      selectableRows
      onRowClicked={(row) => onClickRow(row.key)}
      onSelectedRowsChange={(sel) => onSelect(sel.selectedRows)}
      dense
      // customStyles={customListStyles}
      clearSelectedRows={props.toggledClearSelectedRows}
    />
  );
}

export default ProjList;
