/* eslint-disable import/prefer-default-export */
import { Dispatch, Reducer, useReducer } from 'react';

interface IUseStates<T> extends Array<T | Dispatch<Partial<T>>> {
  0: T;
  1: Dispatch<Partial<T>>;
}
/**
 * An alternative to `useState`.
 * This function allows setting multiple States at once, preventing the DOM from re-rendering
 * Multiple times when more than one state is changed.
 * 
 * Author: Keegan
 * Date: 2022-08-26
 * @param initStates The inital value for all states (must be of type)
 * @returns array - [current value (T), setter function which accepts a Partial(T)] 
 * @example
 * Here's a simple example:
 * ```
 * const [scenarioState, setScenarioState] = useStates<IScenarioState>(
 *  {
 *    timeseries: [],
 *    stats: [],
 *    scenarios: []
 *  }
 *)
 * setScenarioState({stats: [1], scenarios: [1, 2]})
 * ```

 */
export function useStates<T>(initStates: T): IUseStates<T> {
  const [states, setStates] = useReducer<Reducer<T, Partial<T>>>(
    (prevState, newState) => ({ ...prevState, ...newState }),
    initStates
  );
  return [states, setStates];
}
