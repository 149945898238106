/**
 *
 * @param {Object[]} listOfObj An Array of objects
 * @param {string} sortBy name any property in Object[]
 * @param {string} sortDirection must be "asc" or "desc"
 * @param {string|number} nProjects any number, string must be "All"
 * @returns a sort array of objects
 */
export function SortFilter<T>(
  listOfObj: T[],
  sortBy: string,
  sortDirection: 'asc' | 'desc',
  nProjects: number | 'All'
) {
  let sortedProjects: T[] = [];
  if (listOfObj.length > 0) {
    sortedProjects = SortListOfObjects<T>(listOfObj, sortBy, sortDirection);
    if (nProjects !== 'All') {
      sortedProjects = sortedProjects.slice(0, +nProjects);
    } else {
      // force update on dataTable
      sortedProjects = [...sortedProjects];
    }
  }
  return sortedProjects;
}

export function SortListOfObjects<T>(
  listOfObj: T[],
  sortByField: string,
  direction = 'asc'
) {
  let out: T[];
  const key = sortByField as keyof T;
  if (direction === 'asc') {
    out = listOfObj.sort((a: T, b: T) => {
      let result = -1;
      const x = (
        typeof a === 'string'
          ? (a[key] as unknown as string).toLowerCase()
          : a[key]
      ) as string | number;
      const y = (
        typeof b === 'string'
          ? (b[key] as unknown as string).toLowerCase()
          : b[key]
      ) as string | number;
      if (x < y) result = 1;
      if (x > y) result = -1;
      return result;
    });
  } else {
    out = listOfObj.sort((a, b) => {
      let result = -1;
      const x = (
        typeof a === 'string'
          ? (a[key] as unknown as string).toLowerCase()
          : a[key]
      ) as string | number;
      const y = (
        typeof b === 'string'
          ? (b[key] as unknown as string).toLowerCase()
          : b[key]
      ) as string | number;
      if (x > y) result = 1;
      if (x < y) result = -1;
      return result;
    });
  }
  return out;
}
