import React, { useState, useContext, useEffect } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import * as Icon from 'react-bootstrap-icons';

// import { customListStyles } from "../../utils/customStyles";

import { Link } from 'react-router-dom';
import { DataAPIContext } from '../../contexts/data';
import { saveExcel } from '../../data/io/saveData';
import ProjectEquipmentList from './ProjectEquipmentList';
import ListFilter from '../utilities/ListFilter';
import { convertArrayToObject } from '../../utils/convertArrayToObject';

function ProjectListAll(props) {
  const implementationDate = new Date();
  implementationDate.setMonth(implementationDate.getMonth() + 3);
  implementationDate.setDate(1); // avoid 30 & 31 day months
  // --- States --- //
  const { dataClient, unpackResponse } = useContext(DataAPIContext);
  const [projectList, setProjectList] = useState([]);
  const [filteredList, setfilteredList] = useState([]);
  const [projectTypeLookUp, setProjectTypeLookUp] = useState({});

  //  On Component Mount
  useEffect(() => {
    loadProjects();
    loadProjectTypes();
  }, []);

  useEffect(() => {
    setFilteredList(projectList);
  }, [projectList]);

  const loadProjects = async () => {
    const projects = await unpackResponse(dataClient.getProjects());
    const types = {};
    projects.forEach((p) => {
      if (!(p.projectType in types)) {
        types[p.projectType] = {
          name: p.projectType,
          annualReduction: 0,
          cost: 0,
          count: 0,
        };
      }
      const info = types[p.projectType];
      info.annualReduction += p.annualReduction;
      info.cost += p.cost;
      info.count += 1;
    });
    setProjectList(projects);
  };
  const loadProjectTypes = async () => {
    const projectTypeList = await unpackResponse(dataClient.getProjectTypes());
    const projectTypeObj = convertArrayToObject(projectTypeList, 'key');
    setProjectTypeLookUp(projectTypeObj);
  };

  const setFilteredList = (filtered) => {
    setfilteredList(filtered);
  };

  const columns = [
    {
      name: 'Key',
      selector: (row) => row.key,
      sortable: true,
      left: true,
      reorder: true,
      grow: 10,
      compact: true,
      cell: (row) => <span>{row.key}</span>,
    },
    // {
    //   name: (
    //     <>
    //       <span className="clTab-header">
    //         Project
    //         <br />
    //         Name
    //       </span>
    //       <span className="clHide">
    //         The name assigned to the project when it was created.
    //       </span>
    //     </>
    //   ),
    //   selector: (row) => row.name,
    //   sortable: true,
    //   reorder: true,
    //   grow: 10,
    //   minWidth: '300px',
    //   cell: (row) => <span>{row.name}</span>,
    // },
    {
      name: 'Site Name',
      selector: (row) => row.siteName,
      sortable: true,
      minWidth: '300px',
      center: true,
      reorder: true,
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      sortable: true,
      center: true,
      reorder: true,
    },
    {
      name: <span className="clTab-header">Implement Date</span>,
      selector: (row) => row.implementationDate.toISOString().slice(0, 7),
      sortable: true,
      center: true,
      reorder: true,
      // width: "125px",
    },
    {
      name: (
        <>
          <span className="clTab-header">
            Annual Baseline Emissions (tCO2e/year){' '}
          </span>
          <span className="clHide">Baseline annual emissions</span>
        </>
      ),
      selector: (row) => row.startingEmissions,
      cell: (row) => Math.round(row.startingEmissions).toLocaleString(),
      sortable: true,
      right: true,
      reorder: true,
      // minWidth: "150px",
    },
    {
      name: (
        <>
          <span className="clTab-header">
            Annual Project Emissions (tCO2e/year){' '}
          </span>
          <span className="clHide">
            Project annual emissions if project is selected.
          </span>
        </>
      ),
      selector: (row) => row.projectEmissions,
      cell: (row) => Math.round(row.projectEmissions).toLocaleString(),
      sortable: true,
      right: true,
      reorder: true,
      // minWidth: "150px",
    },
    {
      name: (
        <>
          <span className="clTab-header">
            Annual Emissions Reduced (tCO2e/year){' '}
          </span>
          <span className="clHide">Annual Reduction</span>
        </>
      ),
      selector: (row) => row.emissionReduction,
      cell: (row) => Math.round(row.emissionReduction).toLocaleString(),
      sortable: true,
      right: true,
      reorder: true,
      // minWidth: "100px",
    },
    {
      name: <span className="clTab-header">Project Lifetime (Years)</span>,
      selector: (row) => row.projectLifetimeYears,
      cell: (row) => Math.round(row.projectLifetimeYears).toLocaleString(),
      sortable: true,
      center: true,
      reorder: true,
      // minWidth: "100px",
    },
    {
      name: (
        <span className="clTab-header">Lifetime Emissions Reduced (tCO2e)</span>
      ),
      selector: (row) => row.lifetimeReduction,
      cell: (row) => Math.round(row.lifetimeReduction).toLocaleString(),
      sortable: true,
      right: true,
      reorder: true,
      // minWidth: "100px",
    },
    {
      name: <>CapEx ($)</>,
      selector: (row) => row.capCost,
      cell: (row) => Math.round(row.capCost).toLocaleString(),
      sortable: true,
      right: true,
      reorder: true,
    },
    {
      name: <>OpEx ($/year) </>,
      selector: (row) => row.opCost,
      cell: (row) => Math.round(row.opCost).toLocaleString(),
      sortable: true,
      right: true,
      reorder: true,
    },
    {
      name: <>Grant funding ($) </>,
      selector: (row) => row.grantCost,
      cell: (row) => Math.round(row.grantCost).toLocaleString(),
      sortable: true,
      right: true,
      reorder: true,
    },
    {
      name: <>Carbon Tax Savings ($)</>,
      selector: (row) => row.carbonTaxSavings,
      cell: (row) => Math.round(row.carbonTaxSavings).toLocaleString(),
      sortable: true,
      right: true,
      reorder: true,
    },
    {
      name: <>Total Cost Estimate ($)</>,
      selector: (row) => row.cost,
      sortable: true,
      right: true,
      reorder: true,
      cell: (row) => (
        <Link
          className="clLink"
          to={`/projectdetail/${encodeURIComponent(row.key)}`}
        >
          {Math.round(row.cost).toLocaleString()}
        </Link>
      ),
    },
    {
      name: (
        <>
          <span className="clTab-header">
            First Year Abatement Cost ($/tCO2e)
          </span>
          <span className="clHide">
            (cost / YearlyReduction * 1000) / 1000;
          </span>
        </>
      ),

      selector: (row) => row.yearlyAbatementCost,
      cell: (row) => Math.round(row.yearlyAbatementCost).toLocaleString(),
      sortable: true,
      right: true,
      reorder: true,
    },
    {
      name: <span>Lifetime Abatement Cost ($/tCO2e)</span>,

      selector: (row) => row.lifetimeAbatementCost,
      cell: (row) => Math.round(row.lifetimeAbatementCost).toLocaleString(),
      sortable: true,
      right: true,
      reorder: true,
      // minWidth: "110px",
    },
    {
      name: (
        <>
          <span className="clTab-header">Lifetime OpEx ($) </span>
          <span className="clHide">
            OpEx for the lifetime of the project of RP periods whichever is
            less.
          </span>
        </>
      ),

      selector: (row) => row.lifetimeOpCost,
      cell: (row) =>
        (Math.round(row.lifetimeOpCost * 100) / 100).toLocaleString(),
      sortable: true,
      right: true,
      reorder: true,
    },
    {
      name: 'Type',
      selector: (row) => row.projectType,
      sortable: true,
      center: true,
      reorder: true,
      // width: '150px',
    },
    {
      name: (
        <>
          Site
          <br />
          Type
        </>
      ),
      selector: (row) => row.siteType,
      sortable: true,
      center: true,
      reorder: true,
    },
    {
      name: 'Combust',
      selector: (row) => `${row.isCombustion}`,
      sortable: true,
      right: true,
      reorder: true,
    },
    {
      name: (
        <>
          Baseline
          <br />
          Combust
        </>
      ),
      selector: (row) => `${row.isBaselineCombustion}`,
      sortable: true,
      right: true,
      reorder: true,
    },
    {
      name: (
        <>
          Reference
          <br />
          Code
        </>
      ),
      selector: (row) => row.externalReference,
      sortable: true,
      right: true,
      reorder: true,
    },
    {
      name: <>Order </>,
      selector: (row) => row.order,
      cell: (row) => row.order,
      sortable: true,
      right: true,
      reorder: true,
    },
  ];

  let selected = false;

  if (props.selected) {
    selected = true;
  }
  let ScrollHeight = '85vh';

  if (props.ScrollHeight) {
    ScrollHeight = props.ScrollHeight;
  }

  const expandableComponent = (obj) => {
    const equipment = obj.data;
    return (
      <div
        style={{ border: 'solid', borderWidth: 'thin', width: 'min-content' }}
      >
        <ProjectEquipmentList projectKey={equipment.key} />
      </div>
    );
  };

  return (
    <>
      <Row className="space-between">
        <Col>
          <h2 className="p-3">All Projects</h2>
        </Col>
        <Col>
          <ListFilter
            list={projectList}
            setFilteredList={setFilteredList}
            selectOptions={{
              key: 'Key',
              name: 'Name',
              status: 'Status',
              projectType: 'Type',
              siteType: 'Site Type',
            }}
          />
        </Col>
        <Col>
          <div className="d-flex flex-row-reverse p-3">
            <Button
              type="button"
              variant="primary"
              size="sm"
              className="btn btn-lg btn_min--width save__btn mx-1"
              onClick={() => {
                // A fix to avoid the date being set to the day before
                const projects = projectList.map((p) => ({
                  projectName: projectTypeLookUp[p.projectType].name,
                  ...p,
                  implementationDate: p.implementationDate
                    .toISOString()
                    .slice(0, 7),
                }));
                const jsonData = { projects };
                saveExcel(jsonData, 'rp-projects-view.xlsx');
              }}
            >
              Projects Save{' '}
              <Icon.CloudArrowDownFill className="save__btn-icon" />
            </Button>
          </div>
        </Col>
      </Row>
      <DataTable
        columns={columns}
        data={filteredList}
        defaultSortFieldId={1}
        pagination
        paginationPerPage={15}
        paginationRowsPerPageOptions={[15, 1000]}
        fixedHeader
        fixedHeaderScrollHeight={ScrollHeight}
        highlightOnHover
        responsive
        pointerOnHover
        persistTableHead
        selectableRows={selected}
        dense
        // customStyles={customListStyles}
        expandableRows
        expandableRowsComponent={expandableComponent}
      />
    </>
  );
}

export default ProjectListAll;
