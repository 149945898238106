import { EquipmentView, SiteView } from '../../dataAPI/Views';
import { IExtraHooverRows, IFormatLookUp, IGraphBar } from './chartTypes';

export default function barGraphDataBuilder(
  data: SiteView[] | EquipmentView[],
  axisValues: IFormatLookUp,
  color: string,
  format = 'number',
  extraHooverRows?: IExtraHooverRows[] | undefined
) {
  const out: IGraphBar[] = [];
  const formatLookup: IFormatLookUp = {
    currency: '%{y:$,.0f}',
    number: '%{y:,.0f}',
  };
  data.forEach((datum) => {
    type ObjectKey = keyof typeof datum;
    const width = 1;
    const xVal = datum[axisValues.xVal as ObjectKey] as string;
    const yVal = datum[axisValues.yVal as ObjectKey] as string;

    let extraRowsHTML = '';
    if (extraHooverRows) {
      extraHooverRows.forEach((row: IExtraHooverRows) => {
        if (datum[row.key as ObjectKey])
          extraRowsHTML += `${row.value}: ${datum[row.key as ObjectKey]}<br>`;
      });
    }
    let description = '';
    if ('description' in datum) {
      description = `${datum.description}<br>`;
    }

    const formatString = formatLookup[format];
    const bar: IGraphBar = {
      name: datum.name,
      x: [xVal],
      y: [yVal],
      width: [width],
      marker: {
        color,
        line: {
          color: 'white',
          width: 0.5,
        },
      },
      text: extraRowsHTML,
      hoverTemplate:
        `${datum.name}<br>${description}` +
        `%{yAxis.title.text}: ${formatString}<br>` +
        `%{xAxis.title.text}: %{width:.0f}<br>` +
        `%{text}<extra></extra>`,
      type: 'bar',
    };
    out.push(bar);
  });
  return out.sort((a: any, b: any) => a.y[0] - b.y[0]);
}
