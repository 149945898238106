import { ProjectMACCView } from '../../dataAPI/Views';
import { IExtraHooverRows } from './chartTypes';

export default function GetMACCData(
  data: Partial<ProjectMACCView>[],
  extraHooverRows: IExtraHooverRows[] | undefined
) {
  let xStart = 0;
  const out: Partial<Plotly.PlotData>[] = [];

  data.forEach((project) => {
    type ObjectKey = keyof typeof project;
    const width = project.x!;
    const xVal = xStart + project.x! / 2;
    let extraRowsHTML = '';

    if (extraHooverRows) {
      extraHooverRows.forEach((row: IExtraHooverRows) => {
        if (project[row.key as ObjectKey]) {
          extraRowsHTML += `<b>${row.value}</b>: ${
            project[row.key as ObjectKey]
          }<br>`;
        }
      });
    }
    const yVal = project.y!;
    if (project.x! > 0) {
      xStart += width!;
    }
    const bar: Partial<Plotly.PlotData> = {
      name: project.name,
      x: [xVal],
      y: [yVal],
      width: [width],
      xhoverformat: '$,.0f',
      marker: {
        color: project.color,
        size: 10,
        line: {
          color: 'black',
          width: 0.09,
        },
      },
      text: extraRowsHTML,
      hovertemplate:
        `<b>${project.name}</b><br>` +
        `<b>${
          'description' in project ? project.description : 'no description'
        }</b><br>` +
        '%{yaxis.title.text}: %{y:$,.0f}<br>' +
        '%{xaxis.title.text}: %{width:.0f}<br>' +
        `${extraRowsHTML}<extra></extra>`,
      type: 'bar',
    };
    out.push(bar);
  });
  return out;
}
