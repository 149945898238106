export const Dark24 = [
  '#FFC107', // amber
  '#4CAF50', // green
  '#2196F3', // blue
  '#F44336', // red
  '#673AB7', // purple
  '#009688', // teal
  '#E91E63', // pink
  '#FF9800', // orange
  '#3F51B5', // indigo
  '#795548', // brown
  '#00BCD4', // cyan
  '#FF5722', // deep orange
  '#9C27B0', // deep purple
];

// cody's colors some are to close hard to distinguish from one another
// export const Dark24 = [
//   '#7CB978',
//   '#62C5E9',
//   '#FEDE75',
//   '#579C53',
//   '#3CA0C3',
//   '#DCB844',
//   '#D24545',
//   '#52D7C0',
//   '#67AE63',
//   '#4EB0D2',
//   '#EDCA56',
//   '#EF4545',
//   '#75E8D4',
// ];
// export const Dark24 = [
//   "#7CB978",
//   "#62C5E9",
//   "#FEDE75",
//   "#FB0D0D",
//   "#DA16FF",
//   "#B68100",
//   "#750D86",
//   "#EB663B",
//   "#511CFB",
//   "#00A08B",
//   "#FB00D1",
//   "#FC0080",
//   "#B2828D",
//   "#6C7C32",
//   "#778AAE",
//   "#862A16",
//   "#A777F1",
//   "#620042",
//   "#1616A7",
//   "#DA60CA",
//   "#6C4516",
//   "#0D2A63",
//   "#AF0038",
// ];

// Light24 will be used for the light theme
// export const Light24 = [
//   "#7CB978",
//   "#62C5E9",
//   "#FEDE75",
//   "#FB0D0D",
//   "#DA16FF",
//   "#B68100",
//   "#750D86",
//   "#EB663B",
//   "#511CFB",
//   "#00A08B",
//   "#FB00D1",
//   "#FC0080",
//   "#B2828D",
//   "#6C7C32",
//   "#778AAE",
//   "#862A16",
//   "#A777F1",
//   "#620042",
//   "#1616A7",
//   "#DA60CA",
//   "#6C4516",
//   "#0D2A63",
//   "#AF0038",
// ];
