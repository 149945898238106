export const convertArrayToObject = <T extends object, K extends keyof T>(
  array: T[],
  key: K,
  propertiesToExport?: K[]
): { [key: string]: Partial<T> } => {
  const initialValue: { [key: string]: Partial<T> } = {};

  return array.reduce((obj, item) => {
    const newItem: Partial<T> = {};
    if (propertiesToExport) {
      propertiesToExport.forEach((property) => {
        newItem[property] = item[property];
      });
    } else {
      Object.assign(newItem, item);
    }

    return {
      ...obj,
      [item[key] as any]: newItem,
    };
  }, initialValue);
};
