import React, { useContext, useState, useEffect } from 'react';
import EquipmentTypeList from '../lists/EquipmentTypeList';
import { DataAPIContext } from '../../contexts/data';

function EquipmentTypeAll(props) {
  const { dataClient, unpackResponse } = useContext(DataAPIContext);
  const [equipmentTypes, setEquipmentTypes] = useState([]);

  useEffect(() => {
    getEquipmentTypes();
  }, []);

  const getEquipmentTypes = async () => {
    const equipmentTypesList = await unpackResponse(
      dataClient.getEquipmentTypes()
    );
    setEquipmentTypes(equipmentTypesList);
  };

  // useEffect(() => {
  //     // console.log(equipmentTypes)
  //   }, [equipmentTypes]);

  return (
    <>
      <h2 className="text-center mt-5 mb-3">Equipment Type</h2>
      <EquipmentTypeList
        equipmentTypeList={equipmentTypes}
        ScrollHeight={props.ScrollHeight}
      />
    </>
  );
}

export default EquipmentTypeAll;
