import React from 'react';

interface ScoreCardProps {
  name: string;
  value: number | null;
  currency?: string;
  units?: string;
  subtext?: string | null;
  noThousandSeperator?: boolean;
}
const initString = (
  value: number | null | undefined,
  noThousandSeperator?: boolean
): string => {
  let outString = '';
  if (value !== null && value !== undefined) {
    if (!noThousandSeperator) {
      outString = value.toLocaleString(undefined, { maximumFractionDigits: 0 });
    } else {
      outString = value.toString();
    }
  }
  return outString;
};

function ScoreCard({
  name,
  value,
  units,
  currency,
  subtext,
  noThousandSeperator,
}: ScoreCardProps) {
  return (
    <div className="scoreCard r-border-6">
      <div className="scoreCardLabel">{name}</div>
      <div className="scoreCardValue">
        <span className="scoreCardCurrency">{currency}</span>
        {initString(value, noThousandSeperator)}
      </div>
      <div className="scoreCardUnit">{units}</div>
      <div className="scoreCardSubtext">{subtext}</div>
    </div>
  );
}

ScoreCard.defaultProps = {
  subtext: '',
  units: '',
  currency: '',
  noThousandSeperator: false,
};

export default ScoreCard;
