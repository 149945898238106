import * as React from 'react';
import Plot from 'react-plotly.js';
import { Col, Button } from 'react-bootstrap';
import { getLayout, getConfig } from '../../plots/Layouts';
import { IScenarioData } from './scenarioTypes';
import { ReductionVsCostView } from '../../../dataAPI/Views';
import { Dark24 } from '../../../utils/Colors';
import isDarkMode from '../../../utils/lightDarkMode';

const colors = [...Dark24];
export interface IProps {
  reductionVsCost: IScenarioData<ReductionVsCostView>[];
}

export default function ScenarioCostVsPercent({ reductionVsCost }: IProps) {
  const [inputs, setInputs] = React.useState(true);
  const [theme, setTheme] = React.useState(isDarkMode());

  React.useEffect(() => {
    setTheme(isDarkMode());
  });

  if (!reductionVsCost || reductionVsCost.length === 0) return <> </>;

  const onHandleClick = (value: boolean) => {
    setInputs(value);
  };

  const traces: any = [];
  reductionVsCost.forEach((scenario, i) => {
    if (!scenario.show || scenario.name === 'Base Case') return;
    const color = colors[i];
    const reductionVsCostSeries: ReductionVsCostView = scenario.data[0];
    const scenarioName = scenario.name;

    let x;

    if (inputs) {
      x = reductionVsCostSeries.costSeries;
    } else {
      x = reductionVsCostSeries.implementationDataSeries;
    }
    const y = reductionVsCostSeries.reductionPercentSeries;
    // @todo 2023-05 get abatementCost from saveScenario
    // const info = orderedList.map((p: ProjectView) => p.abatementCost);
    const info = reductionVsCostSeries.firstYearAbatementCost;

    const trace = {
      x,
      y,
      line: {
        dash: 'solid',
        width: 3,
        color,
      },
      type: 'scatter',
      // mode: "markers",
      hovertemplate: '%{x}<br><b>%{y:,.0%} %{text:$.2f}</b><extra></extra>',
      // @todo 2023-05 get abatementCost from saveScenario
      text: info,
      name: `${scenarioName}`,
      domain: { x: [0, 0.5] },
    };

    traces.push(trace);
  });

  let title;
  let xaxis;
  if (inputs) {
    title = 'Cumulative Annual Reduction vs Cumulative Cost';
    xaxis = 'Cumulative Cost ($)';
  } else {
    title = 'Date vs Cumulative Annual Reduction %';
    xaxis = 'Date';
  }

  const layout = getLayout(
    title,
    xaxis,
    'Cumulative Annual Reduction (%)',
    true,
    false,
    '',
    1,
    theme
  );
  if (layout.yaxis) layout.yaxis.tickformat = '.0%';

  return (
    <div className="chart__container r-border">
      <div className="btn__container">
        <Button
          className="chart__toggle-btn"
          onClick={() => onHandleClick(!inputs)}
        >
          {inputs ? 'Switch to By Year' : 'Switch to By Cost'}
        </Button>
      </div>
      <Col>
        <Plot
          data={traces as any}
          layout={layout}
          useResizeHandler
          style={{ width: '100%', height: '100%' }}
          config={getConfig()}
        />
      </Col>
    </div>
  );
}
