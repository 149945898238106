import React from 'react';

import {
  Row,
  Col,
  Button,
  Form,
  FloatingLabel,
  ButtonGroup,
  ToggleButton,
} from 'react-bootstrap';

export function SortFilterWidget(props) {
  const countOptions = [10, 25, 50, 100, 'All'];
  return (
    <Row>
      <Col className="col-5">
        <Row>
          <span className="m-0">Show Top Projects</span>
        </Row>
        <Row>
          <ButtonGroup className="m-1">
            {countOptions.map((radio) => (
              <ToggleButton
                className=""
                key={`${props.cardID}-rad-sel-${radio}`}
                id={`${props.cardID}-rad-sel-${radio}`}
                type="radio"
                variant="secondary"
                name={`${props.cardID}-rad-sel`}
                value={radio}
                checked={props.selected === radio}
                onChange={props.onChange}
              >
                {radio}
              </ToggleButton>
            ))}
          </ButtonGroup>
        </Row>
      </Col>
      <Col className="col-6 mx-0">
        <FloatingLabel
          controlId="SelectByLabel"
          label="SortBy"
          className="mb-1"
        >
          <Form.Select
            className="my-2"
            as="select"
            value={props.sortBy}
            onChange={(e) => props.handleSortBy(e.target.value)}
          >
            {props.sortOptions
              ? props.sortOptions.map((opt) => (
                  <option key={opt.key} value={opt.value}>
                    {opt.text}
                  </option>
                ))
              : null}
          </Form.Select>
        </FloatingLabel>
      </Col>
      <Col className="col-1">
        <Button
          className="btn mx-0 my-2 p-3 save__btn"
          onClick={() => {
            props.handleSortDirection(
              props.sortDirection === 'asc' ? 'desc' : 'asc'
            );
          }}
        >
          <span>
            {props.sortDirection === 'asc' ? <b>&uarr;</b> : <b>&darr;</b>}
          </span>
        </Button>
      </Col>
    </Row>
  );
}
