import React, { useContext, useEffect, useState } from 'react';
import { Container, Col, Button, Row, Table } from 'react-bootstrap';

import { useParams } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import { DataAPIContext } from '../../contexts/data';
import { numberWithCommas } from '../../utils/format';
import { saveExcel } from '../../data/io/saveData';

function ProjectDetail() {
  const { dataClient, unpackResponse } = useContext(DataAPIContext);
  const { projectKey } = useParams();
  const [costModel, setCostModel] = useState();

  useEffect(() => {
    getNewCostModel();
  }, []);

  const getNewCostModel = async () => {
    const costModel = await unpackResponse(
      dataClient.getNewCostModel(projectKey)
    );
    setCostModel(costModel);
  };

  return (
    <Container>
      <Col className="p-3 m-3 align-self-center shadow border">
        {costModel && (
          <div>
            <div>
              <h2 className="text-center">Project Detail</h2>
              <h4>{`${costModel.site} - ${costModel.projectType} - ${costModel.projectTypeName}`}</h4>
              <br />
            </div>
            <div className="d-flex justify-content-start">
              <Row>
                <div className="table-fit">
                  {costModel.costCategories.map((category, i) => (
                    <Row key={i}>
                      <div className="d-flex justify-content-start">
                        <Table
                          className="table-fit caption-top"
                          striped
                          bordered
                          hover
                          responsive
                        >
                          <caption>
                            <h4>{category.name}</h4>
                          </caption>
                          <thead>
                            <tr>
                              <th className="text-center">Name</th>
                              <th className="text-center">Cost Per</th>
                              <th className="text-center">Units</th>
                              <th className="text-center">Cost</th>
                              <th className="text-center">Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {category.lines.map((line, j) => (
                              <tr key={`${i}-${j}`}>
                                <td className="text-start">
                                  {line.description}
                                </td>
                                <td className="text-start">{line.costPer}</td>
                                <td className="text-center">{line.units}</td>
                                <td className="text-end">
                                  ${numberWithCommas(line.unitCost)}
                                </td>
                                <td className="text-end">
                                  ${numberWithCommas(line.cost)}
                                </td>
                              </tr>
                            ))}

                            <tr>
                              <td className="text-end" colSpan="4">
                                Total
                              </td>
                              <td className="text-end" colSpan="1">
                                ${numberWithCommas(category.total)}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                        {/* CAP-EX HERE */}
                      </div>
                    </Row>
                  ))}

                  <Row>
                    <div className="d-flex justify-content-start">
                      {/* Project Emissions */}
                      <Table
                        className="table-fit caption-top"
                        striped
                        bordered
                        hover
                        responsive
                      >
                        <caption>
                          <h4>Carbon Tax</h4>
                        </caption>
                        <tbody>
                          <tr>
                            <td className="text-start">
                              Project - Baseline Emissions
                            </td>
                            <td className="text-end">
                              {Math.round(
                                costModel.annualEmissions
                              ).toLocaleString()}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-start">Project Emissions</td>
                            <td className="text-end">
                              {Math.round(
                                costModel.projectEmissions
                              ).toLocaleString()}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-start">
                              Baseline Carbon Tax Applied (Y/N)
                            </td>
                            <td className="text-end">{`${costModel.baselineCarbonTaxApplied}`}</td>
                          </tr>
                          <tr>
                            <td className="text-start">
                              Project Carbon Tax Applied (Y/N)
                            </td>
                            <td className="text-end">{`${costModel.projectCarbonTaxApplied}`}</td>
                          </tr>
                          <tr>
                            <td className="text-start">
                              Lifetime Emission Savings
                            </td>
                            <td className="text-end">
                              {Math.round(
                                costModel.lifetimeReduction
                              ).toLocaleString()}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-start">Project Start</td>
                            <td className="text-end">
                              {costModel.implementationDate}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-start">
                              Lifetime of the Project
                            </td>
                            <td className="text-end">
                              {costModel.projectLifetimeYears}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-start">
                              Carbon Tax Savings *{' '}
                              {costModel.aggregateCarbonTaxFactor}
                            </td>
                            <td className="text-end" colSpan="1">
                              $
                              {Math.round(
                                costModel.carbonTaxSavings
                              ).toLocaleString()}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Row>
                  <Row>
                    <div className="d-flex justify-content-start">
                      <Table
                        className="table-fit caption-top"
                        striped
                        bordered
                        hover
                        responsive
                      >
                        <thead>
                          <tr>
                            <th className="text-center">Year</th>
                            <th className="text-center">
                              Carbon Tax ($/tonne)
                            </th>
                            <th className="text-center">Baseline Carbon Tax</th>
                            <th className="text-center">Project Carbon Tax</th>
                            <th className="text-center">Carbon Tax Savings</th>
                            <th className="text-center">
                              Baseline
                              <br />
                              Emissions
                            </th>
                            <th className="text-center">
                              Emissions
                              <br />
                              Reduction
                            </th>
                            <th className="text-center">CapEx</th>
                            <th className="text-center">OpEx</th>
                            <th className="text-center">
                              Grant
                              <br />
                              Funding
                            </th>
                            <th className="text-center">
                              <Button
                                type="button"
                                variant="primary"
                                size="sm"
                                className="btn  btn-lg mx-1 btn_min--width save__btn"
                                onClick={() => {
                                  const jsonData = {
                                    periods: costModel.periods,
                                    // costModel: costModel, // Douglas todo dump objects..
                                  };
                                  saveExcel(
                                    jsonData,
                                    'rp-costPeriods-view.xlsx'
                                  );
                                }}
                              >
                                Cost by Period{' '}
                                <Icon.CloudArrowDownFill className="save__btn-icon" />
                              </Button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {costModel.periods.map((period, i) => (
                            <tr key={i}>
                              <td className="text-start">{period.year}</td>
                              <td className="text-end">
                                {Math.round(
                                  period.carbonTaxRate
                                ).toLocaleString()}
                              </td>
                              <td className="text-end">
                                $
                                {Math.round(
                                  period.baselineCarbonTax
                                ).toLocaleString()}
                              </td>
                              <td className="text-end">
                                $
                                {Math.round(
                                  period.projectCarbonTax
                                ).toLocaleString()}
                              </td>
                              <td className="text-end">
                                $
                                {Math.round(
                                  period.carbonTaxSavings
                                ).toLocaleString()}
                              </td>
                              <td className="text-end">
                                {Math.round(period.emissions).toLocaleString()}
                              </td>
                              <td className="text-end">
                                {Math.round(
                                  period.emissionsReduction
                                ).toLocaleString()}
                              </td>
                              <td className="text-end">
                                ${Math.round(period.capEx).toLocaleString()}
                              </td>
                              <td className="text-end">
                                ${Math.round(period.opEx).toLocaleString()}
                              </td>
                              <td className="text-end">
                                $
                                {Math.round(
                                  period.grantFunding
                                ).toLocaleString()}
                              </td>
                            </tr>
                          ))}
                          <tr>
                            <td className="text-end" colSpan="4">
                              Total
                            </td>
                            <td className="text-end" colSpan="1">
                              $
                              {Math.round(
                                costModel.carbonTaxSavings
                              ).toLocaleString()}
                            </td>
                            <td className="text-end" colSpan="2">
                              {Math.round(
                                costModel.lifetimeReduction
                              ).toLocaleString()}
                            </td>
                            <td className="text-end" colSpan="2">
                              $
                              {Math.round(
                                costModel.lifetimeOpCost
                              ).toLocaleString()}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                      {/* CAP-EX HERE */}
                    </div>
                  </Row>
                </div>
              </Row>
            </div>
          </div>
        )}
      </Col>
    </Container>
  );
}

export default ProjectDetail;
