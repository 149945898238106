import React from 'react';
import Modal from 'react-bootstrap/Modal';
import '../../App.css';
import { Button, Row } from 'react-bootstrap';
import isDarkMode from '../../utils/lightDarkMode';

interface RpModalProps {
  children: JSX.Element | JSX.Element[];
  show: boolean;
  setShow: (show: boolean) => void;
}

function RpModal({ show, setShow, children }: RpModalProps) {
  return (
    <Modal
      id="modal"
      className="modal-container"
      dialogClassName="modal-dialog"
      contentClassName="modal-body"
      backdropClassName="modal-backdrop"
      backdrop="static"
      show={show}
      fullscreen
      onHide={() => setShow(false)}
      data-theme={isDarkMode()}
    >
      <Modal.Header>
        <Row>
          <Button
            className="chart__toggle-btn btn-close"
            onClick={() => setShow(false)}
          />{' '}
        </Row>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
}

export default RpModal;
