import React, { useContext, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { customListStyles } from '../../utils/customStyles';
import { DataAPIContext } from '../../contexts/data';

function ProjectEquipmentList(props) {
  const { dataClient, unpackResponse } = useContext(DataAPIContext);

  const [equipmentList, setEquipmentList] = useState([]);

  useEffect(() => {
    getEquipmentByProject();
  }, []);

  const getEquipmentByProject = async () => {
    const equipmentList = await unpackResponse(
      dataClient.getEquipmentByProject(props.projectKey)
    );
    setEquipmentList(equipmentList);
  };

  const columns = [
    {
      name: 'Equipment Key',
      selector: (row) => row.key,
      sortable: true,
      left: true,
      reorder: true,
      // grow: 2,
    },
    {
      name: 'Equipment Name',
      selector: (row) => row.name,
      sortable: true,
      left: true,
      minWidth: '170px',
      reorder: true,
      // grow: 6,
    },
    {
      name: 'Combust',
      selector: (row) => `${row.isCombustion}`,
      sortable: true,
      center: true,
      // minWidth: "170px",
      reorder: true,
      // grow: 6,
    },
    {
      name: 'Equipment Emission',
      selector: (row) => row.yearlyEmissions,
      cell: (row) => Math.round(row.yearlyEmissions).toLocaleString(),
      sortable: true,
      right: true,
      // minWidth: "100px",
      reorder: true,
      // grow: 2,
    },
    {
      name: 'PT Emission',
      selector: (row) => row.emissions,
      cell: (row) => Math.round(row.emissions).toLocaleString(),
      sortable: true,
      right: true,
      // minWidth: "100px",
      reorder: true,
      // grow: 2,
    },
    {
      name: 'PT Emission Reductions',
      selector: (row) => row.emissionReductions,
      cell: (row) => Math.round(row.emissionReductions).toLocaleString(),
      sortable: true,
      right: true,
      reorder: true,
      // grow: 2,
    },
  ];

  let ScrollHeight = '65vh';

  if (props.ScrollHeight) {
    ScrollHeight = props.ScrollHeight;
  }

  if (props.ScrollHeight) {
    ScrollHeight = props.ScrollHeight;
  }
  return (
    <DataTable
      columns={columns}
      data={equipmentList}
      defaultSortFieldId={1}
      paginationPerPage={25}
      paginationRowsPerPageOptions={[25, 50, 100, 250, 500, 1000]}
      noHeader
      fixedHeader
      fixedHeaderScrollHeight={ScrollHeight}
      highlightOnHover
      dense
      customStyles={customListStyles}
    />
  );
}

export default ProjectEquipmentList;
