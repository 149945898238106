import React, { useEffect } from 'react';
import Plot from 'react-plotly.js';

function Phil() {
  const [data, setData] = React.useState([]);
  const [layout, setLayout] = React.useState({});

  useEffect(() => {
    draw();
  }, []);

  const draw = () => {
    const values = [16, 15, 12, 6, 5, 4, 42];

    const data = [
      {
        values,
        labels: [
          'US',
          'China',
          'European Union',
          'Russian Federation',
          'Brazil',
          'India',
          'Rest of World',
        ],
        name: 'GHG Emissions',
        hoverinfo: 'label+percent+name',
        textinfo: 'label+percent',
        direction: 'clockwise',
        hole: 0.8,
        type: 'pie',
      },
    ];
    setData(data);

    // let positions = [];
    let position_x = [];
    let position_y = [];

    function get_circle_positions(values) {
      values.sort();
      let emissions_sum = 0;
      const num_items = values.length;
      for (let i = 0; i < num_items; i++) {
        emissions_sum += values[i];
      }
      let position = 0;
      for (let i = 0; i < num_items; i++) {
        position += values[i] / emissions_sum;
        position_x[i] = Math.sin(position);
        position_y[i] = Math.cos(position);
      }
      return [position_x, position_y];
    }

    const positions = get_circle_positions(values);
    position_x = positions[0] / 2;

    console.log(position_x);
    position_y = positions[1] / 2;
    console.log(position_y);

    const layout = {
      annotations: [
        {
          font: {
            size: 20,
          },
          showarrow: false,
          text: 'test',
          x: 0.5,
          y: 0.5,
        },
      ],
      height: 400,
      width: 600,
      showlegend: false,
      shapes: [
        {
          layer: 'above',
          type: 'circle',
          xref: 'paper',
          yref: 'paper',
          x0: 0.5, // position_x[0],
          y0: 0.4, // position_y[0],
          x1: 0.6, // position_x[0]+0.5,
          y1: 0.6, // position_y[0]+0.5,
          fillcolor: 'rgba(220,220,67,128)',
        },
      ],
    };
    setLayout(layout);
  };
  return (
    <Plot
      data={data}
      layout={layout}
      useResizeHandler
      style={{ width: '100%', height: '100%' }}
    />
  );
}

export default Phil;
