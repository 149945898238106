import React from 'react';
import { Button } from 'react-bootstrap';

interface INewUserProps {
  email: string;
  securityCode: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onCancelClick: () => void;
  validateSecurityCodeClick: () => void;
}
function ValidateCode({
  email,
  securityCode,
  onChange,
  onCancelClick,
  validateSecurityCodeClick,
}: INewUserProps) {
  return (
    <div className="m1">
      <h3 className="text-center">Validate Code</h3>
      <p className="text-center">Log in to your Highwood Emissions dashboard</p>
      <div className="col">
        <div className="control">
          <label htmlFor="lastName">
            <span>Email:</span>
            <input
              onChange={onChange}
              name="email"
              type="text"
              placeholder="Email Address"
              value={email}
            />
          </label>
        </div>
        <div className="d-flex">
          <Button className="btn btn-lg m-1 w-100 save__btn" type="button">
            Request Security Code
          </Button>
        </div>
        <div className="control">
          <label htmlFor="security-code">
            <span className="w-50">Security Code:</span>
            <input
              onChange={onChange}
              className="security-code"
              name="securityCode"
              type="text"
              placeholder="Security Code"
              value={securityCode}
            />
          </label>
        </div>
        <div className="d-flex">
          <Button
            onClick={onCancelClick}
            className="btn btn-lg m-1 w-50 save__btn"
            type="button"
          >
            Cancel
          </Button>
          <Button
            onClick={() => validateSecurityCodeClick()}
            className="btn btn-lg m-1 w-50 save__btn"
            type="button"
          >
            Validate Code
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ValidateCode;
