import React from 'react';
import DataTable from 'react-data-table-component';
import {
  formatNumRoundTwoDecimals,
  numberWithCommas,
} from '../../utils/format';
import { customListStyles } from '../../utils/customStyles';

function ProjectTypeList(props) {
  const columns = [
    {
      name: 'Key',
      selector: (row) => row.key,
      grow: 1,
      sortable: true,
      left: true,
      reorder: true,
      minWidth: '200px',
    },
    {
      name: 'Project Type',
      selector: (row) => row.name,
      grow: 15,
      sortable: true,
      left: true,
      reorder: true,
      wordWrap: 'wrap',
    },
    {
      name: 'Reduction',
      selector: (row) => row.emissionReduction,
      sortable: true,
      right: true,
      reorder: true,
      minWidth: '200px',
      format: (row) =>
        numberWithCommas(formatNumRoundTwoDecimals(row.emissionReduction)),
    },
  ];

  let ScrollHeight = '65vh';

  if (props.ScrollHeight) {
    ScrollHeight = props.ScrollHeight;
  }
  return (
    <DataTable
      keyField="key"
      columns={columns}
      data={props.projectTypeList}
      defaultSortFieldId={1}
      // sortIcon={<SortIcon />}
      pagination
      paginationPerPage={25}
      paginationRowsPerPageOptions={[25, 50, 100, 250, 500, 1000]}
      noHeader
      fixedHeader
      fixedHeaderScrollHeight={ScrollHeight}
      highlightOnHover
      dense
      customStyles={customListStyles}
    />
  );
}

export default ProjectTypeList;
