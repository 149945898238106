import React from 'react';
import { LatLngExpression } from 'leaflet';
import { CircleMarker, Tooltip } from 'react-leaflet';
import scaleToRange from './mapFuncs';
import { SiteView } from '../../../dataAPI/Views';

const MINSIZE = 1;
const MAXSIZE = 10;

interface ISiteMarker {
  site: SiteView;
  sourceRange: number[];
  size: number;
}

export default function SiteMarker({ site, sourceRange, size }: ISiteMarker) {
  if (
    typeof site.lat === 'number' &&
    typeof site.long === 'number' &&
    typeof site.yearlyEmissions === 'number'
  ) {
    const loc: LatLngExpression = [site.lat, site!.long];
    const radius = scaleToRange(
      site.yearlyEmissions,
      sourceRange[0],
      sourceRange[1],
      MINSIZE,
      MAXSIZE
    );
    const fillColor = (rad: number) => {
      if (rad > MAXSIZE * 0.5) {
        return '#41769b';
      }
      if (rad > MAXSIZE * 0.75) {
        return '#325b77';
      }
      return '#c1cdd6';
    };

    return (
      <CircleMarker
        fillOpacity={0.5}
        fillColor={fillColor(radius)}
        weight={0.5}
        center={loc}
        radius={radius * (size / 100)}
      >
        <Tooltip>
          <h5>{site.name}</h5>
          <h6>{site.type}</h6>
          <span>
            <b>Equipment Count:</b>
            {site.equipmentCount} <br />
          </span>
          <span>
            <b>Project Count:</b>
            {site.projectCount} <br />
          </span>
          <span>
            <b>Annual Emissions:</b>
            {Math.round(site.yearlyEmissions).toLocaleString()} tCO2e <br />
          </span>
        </Tooltip>
      </CircleMarker>
    );
  }
  return null;
}
