import React, { useContext, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { customListStyles } from '../../utils/customStyles';
import { DataAPIContext } from '../../contexts/data';

function EquipmentPTList(props) {
  //  Define Global Context
  const { dataClient, unpackResponse } = useContext(DataAPIContext);
  const [ptList, setProjectTypes] = useState([]);

  useEffect(() => {
    loadPtCalcs();
  }, []);

  const loadPtCalcs = async () => {
    const ptCalcs = await unpackResponse(
      dataClient.getEquipmentProjectTypes(props.equipment.key)
    );
    setProjectTypes(ptCalcs);
    // dataClient.getEquipmentProjectTypes(e.key).then((r) => {
    // setProjectTypes(r);
  };

  const columns = [
    {
      name: 'Project Type',
      selector: (row) => `${row.key} / ${row.name}`,
      sortable: true,
      left: true,
      reorder: true,
      grow: 7,
    },
    {
      name: 'Type',
      selector: (row) => row.activityType,
      sortable: true,
      left: true,
      reorder: true,
      // grow: 1,
    },
    {
      name: 'Emissions',
      selector: (row) => row.emissions,
      cell: (row) => Math.round(row.emissions).toLocaleString(),
      sortable: true,
      right: true,
      reorder: true,
      // minWidth: "150px",
    },
    {
      name: 'Reduction',
      selector: (row) => row.emissionReduction,
      cell: (row) => Math.round(row.emissionReduction).toLocaleString(),
      sortable: true,
      right: true,
      reorder: true,
      // minWidth: "150px",
    },
  ];

  let selected = false;
  let ScrollHeight = '65vh';

  if (props.selected) {
    selected = true;
  }

  if (props.ScrollHeight) {
    ScrollHeight = props.ScrollHeight;
  }

  return (
    <DataTable
      columns={columns}
      data={ptList}
      defaultSortFieldId={1}
      // sortIcon={<SortIcon />}
      pagination
      paginationPerPage={25}
      paginationRowsPerPageOptions={[25, 50, 100, 250, 500, 1000]}
      noHeader
      fixedHeader
      fixedHeaderScrollHeight={ScrollHeight}
      highlightOnHover
      selectableRows={selected}
      onSelectedRowsChange={props.onSelectedRowsChange}
      dense
      customStyles={customListStyles}
    />
  );
}

export default EquipmentPTList;
