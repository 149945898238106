import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, InputGroup, Button, Container } from 'react-bootstrap';

import { DataAPIContext } from '../../contexts/data';
import LoadingSpinner from './LoadingSpinner';
import { IHeader, IUser } from '../../data/Types';
import { appGetEmail } from '../../security/security';

import ShareAddUser from './ShareAddUser';
import ShareUserList from './ShareUserList';

export default function Share() {
  const { dataClient, unpackResponse } = useContext(DataAPIContext);

  const today = new Date().toLocaleString('en-ca', {}).replace('/', '-');
  const [authorizedUser, setAuthorizedUser] = useState<string>('');

  const [header, setHeader] = useState<Partial<IHeader>>({
    rpid: '',
    email: '',
    name: '',
    share: {},
    created: '',
    modified: '',
    createdBy: '',
    modifiedBy: '',
  });

  const [saving, setSaving] = useState(false);

  useEffect(() => {
    getAuthorizedUser();
  }, []);

  useEffect(() => {
    getPathwayValuesHeader();
  }, [authorizedUser]);

  const getAuthorizedUser = async () => {
    setAuthorizedUser(appGetEmail());
  };

  const getPathwayValuesHeader = async () => {
    const rpHeader = await unpackResponse(
      dataClient.rpGetPathwayValuesHeader(authorizedUser)
    );

    if (rpHeader) {
      const share: IUser = {};
      // remove any undefined users ie: {undefined: true}
      Object.keys(rpHeader.share).forEach((s: any) => {
        if (s !== 'undefined') {
          share[s] = true;
        }
      });
      if (rpHeader.modifiedBy === '') {
        rpHeader.modifiedBy = authorizedUser;
      }
      if (rpHeader.createdBy === '') {
        rpHeader.createdBy = authorizedUser;
      }
      share[authorizedUser] = true;
      rpHeader.share = share;
      setHeader({ ...rpHeader });
    }
  };

  const onSave = async () => {
    setSaving(true);
    // rpShare saves pathwayValues header
    const pathwayValues = await unpackResponse(dataClient.getPathwayValues());
    pathwayValues.header = { ...header };
    await dataClient.updatePathwayValues(pathwayValues);
    const share = await unpackResponse(
      dataClient.rpSaveShare(header, authorizedUser, header.rpid)
    );
    setHeader(share.header);
    setSaving(false);
  };

  const onSaveAs = async () => {
    setSaving(true);

    const pathwayValues = await unpackResponse(dataClient.getPathwayValues());
    pathwayValues.header = { ...header };
    await dataClient.updatePathwayValues(pathwayValues);
    const share = await unpackResponse(
      dataClient.rpSaveShare(header, authorizedUser)
    );
    setHeader(share.header);
    setSaving(false);
  };

  const deleteId = async () => {
    setSaving(true);
    if (header.rpid) {
      header.name = `deleted-${today}-${header.name}`;
      const share = await unpackResponse(
        dataClient.rpSaveShare(header, authorizedUser, header.rpid)
      );
      // empty all controls
      share.header = {
        rpid: '',
        email: '',
        name: '',
        share: {},
        created: '',
        modified: '',
        createdBy: '',
        modifiedBy: '',
      };
      setHeader(share.header);
    }
    setSaving(false);
  };

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const headerState = { ...header };
    headerState.name = event.target.value;
    setHeader({ ...headerState });
  };

  const addUserToList = (sharedUsers: string[]) => {
    const headerState: Partial<IHeader> = header;
    sharedUsers.forEach((user) => {
      if (user) {
        headerState.share![`${user}`] = true;
      }
    });
    setHeader({ ...headerState });
  };

  const onRemoveSharedUserClickHandler = (email: string) => {
    if (email) {
      type ObjectKey = keyof typeof header.share;
      if (header.share) {
        const activeUsers = { ...header.share };
        delete activeUsers[email as ObjectKey];
        header.share = activeUsers;
        setHeader(header);
      }
    }
  };

  return (
    <Container className="">
      <Row>
        <div>
          <h2 className="mt-5 mb-5">Share</h2>
          <div className="m-auto mt-1 p-3 s-bg r-border">
            <div>
              <Row>
                <Col className="col-4">
                  <InputGroup className="mb-3">
                    <label htmlFor="name" className="col-12">
                      <h6 className="p-text ">Name:</h6>
                      <input
                        className="form-control"
                        type="text"
                        id="name"
                        name="name"
                        value={header.name || ''} // changing a controlled input to be uncontrolled
                        onChange={onNameChange}
                      />
                    </label>
                  </InputGroup>
                </Col>
                <Col className="col-4">
                  <InputGroup className="mb-2">
                    <h6 className="p-text">Created</h6>
                    <InputGroup className="mb-2">
                      <InputGroup.Text className="form-control">
                        By: {header.createdBy}
                      </InputGroup.Text>
                      <InputGroup.Text className="form-control">
                        On: {header.created}
                      </InputGroup.Text>
                    </InputGroup>
                  </InputGroup>
                </Col>
                <Col className="col-4">
                  <InputGroup className="mb-2">
                    <h6>Modified</h6>
                    <InputGroup className="mb-2">
                      <InputGroup.Text className="form-control">
                        By: {header.modifiedBy}
                      </InputGroup.Text>
                      <InputGroup.Text className="form-control">
                        On: {header.modified}
                      </InputGroup.Text>
                    </InputGroup>
                  </InputGroup>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <ShareUserList
                  authorizedUser={authorizedUser}
                  users={header.share!}
                  removeSharedUser={onRemoveSharedUserClickHandler}
                />
              </Row>
              <Row>
                <ShareAddUser addUserToList={addUserToList} />
              </Row>
            </div>
            <Row className="d-flex mt-3 mb-3  justify-content-between">
              <Col>
                <Button
                  className="btn btn-lg col-md-12  save__btn"
                  onClick={() => onSave()}
                >
                  Save Pathway
                </Button>
              </Col>
              <Col>
                <Button
                  className="btn  btn-lg col-md-12 save__btn"
                  onClick={() => onSaveAs()}
                >
                  SaveAs Pathway
                </Button>
              </Col>
              <Col>
                <Button
                  className="btn btn-lg col-md-12 cancel__btn"
                  onClick={() => deleteId()}
                >
                  Delete Pathway
                </Button>
              </Col>
            </Row>
            {saving ? <LoadingSpinner spinnerText="Saving" /> : null}
          </div>
        </div>
      </Row>
    </Container>
  );
}
