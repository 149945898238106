import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { IUser } from '../../data/Types';

interface ShareUserListProps {
  users: IUser;
  authorizedUser: string;
  removeSharedUser: (email: string) => void;
}

export default function ShareUserList({
  users,
  authorizedUser,
  removeSharedUser,
}: ShareUserListProps) {
  const [sharedUsers, setSharedUsers] = useState<IUser>(users);

  useEffect(() => {
    setSharedUsers(users);
  }, [users]);

  const remove = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // const activeUsers = users;
    if (sharedUsers) {
      type ObjectKey = keyof typeof sharedUsers;
      const { id } = event.target as HTMLInputElement;
      if (id) {
        const activeUsers = sharedUsers;
        delete activeUsers[id as ObjectKey];
        setSharedUsers({ ...activeUsers });
        removeSharedUser(id);
      }
    }
  };
  return (
    <div>
      <h3 className="mt-5 mb-5">Share User List</h3>
      <ul>
        {sharedUsers &&
          Object.keys(sharedUsers).map((key) =>
            key !== undefined ? (
              <li className="shared__user" key={key}>
                <span>{key}</span>
                <Button
                  className="shared__user-btn save__btn"
                  id={key}
                  disabled={key === authorizedUser}
                  onClick={remove}
                >
                  Remove
                </Button>
              </li>
            ) : null
          )}
      </ul>
    </div>
  );
}
