import React from 'react';
import { Button } from 'react-bootstrap';

interface IExistingUserProps {
  email: string;
  onEmailChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onRequestSecurityCodeClick: () => void;
  newUserClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  // onCancelClick: () => void;
}

function RequestCode({
  email,
  onEmailChange,
  onRequestSecurityCodeClick,
  newUserClick,
}: // onCancelClick,
IExistingUserProps) {
  return (
    <div className="m-1">
      <h3 className="text-center">Login</h3>
      <div className="col">
        <div className="control">
          <label htmlFor="email">
            <span>Email:</span>
            <input
              onChange={onEmailChange}
              id="email"
              name="email"
              type="text"
              placeholder="Email"
              value={email}
            />
          </label>
        </div>
        <div className="d-flex">
          <Button
            className="btn btn-lg m-1 w-100 save__btn"
            id="send-code"
            type="button"
            onClick={onRequestSecurityCodeClick}
          >
            Send Code
          </Button>
        </div>
        <h6 className="text-center">
          Don&apos;t have a account?
          <Button
            className="m-1 link__btn"
            id="newUser"
            variant="link"
            onClick={(event) => newUserClick(event)}
          >
            New user
          </Button>
        </h6>
      </div>
    </div>
  );
}

export default RequestCode;
