import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AuthenticationUser from './AuthenticationUser';
import menuItems from './menuItems2';
import menuFilter from '../../../utils/menuFilter';
import { isAdminAccount } from '../../../security/security';
// import { DataAPIContext } from '../../../contexts/data';

import { ReactComponent as SunIcon } from '../../../images/sun.svg';
import { ReactComponent as MoonIcon } from '../../../images/moon-stars.svg';
import { appPubSub, Topic } from '../../../utils/AppPubSub';

// const contextType = DataAPIContext;

export default class TopNavbar extends React.Component {
  static formatKey(val) {
    return val.toLowerCase();
  }

  constructor(props) {
    super(props);
    this.state = {
      showClientView: true,
      name: '',
      scenarioName: '',
    };
  }

  componentDidMount() {
    // appPubSub.subscribe(Topic.Header, (values) => {
    appPubSub.subscribe(Topic.Header, ({ name, scenarioName }) => {
      // const { name, scenarioName } = values;
      if (name) {
        this.setState({ name, scenarioName });
      }
    });
  }

  getMenuItems() {
    const navItems = [];
    let filteredMenu;

    const isAdmin = isAdminAccount();

    if (isAdmin && this.state.showClientView) {
      filteredMenu = menuFilter(menuItems, 'admin');
    } else {
      filteredMenu = menuFilter(menuItems, 'user');
    }

    filteredMenu.forEach((x, iX) => {
      if ('dropdownItems' in x) {
        const ddItems = [];
        x.dropdownItems.forEach((y, iY) => {
          if (y.key) {
            ddItems.push(
              <LinkContainer
                className="link_container"
                key={`${TopNavbar.formatKey(x.key)}_${iY}`}
                to={y.href}
              >
                <NavDropdown.Item
                  className="nav_link"
                  key={`${TopNavbar.formatKey(x.key)}_${iY}`}
                >
                  {y.title}
                </NavDropdown.Item>
              </LinkContainer>
            );
          }
        });
        navItems.push(
          <NavDropdown
            className="nav_link"
            key={`${TopNavbar.formatKey(x.key)}_${iX}`}
            title={x.title}
            id="basic-nav-dropdown"
          >
            {ddItems}
          </NavDropdown>
        );
      } else if (x.key) {
        // top level menu item
        navItems.push(
          <LinkContainer
            className="link_container"
            key={`${TopNavbar.formatKey(x.key)}_${iX}`}
            to={x.href}
          >
            <Nav.Link className="nav_link" key={TopNavbar.formatKey(x.key)}>
              {x.title}
            </Nav.Link>
          </LinkContainer>
        );
      }
    });
    return <Nav className="me-auto">{navItems}</Nav>;
  }

  onSetClientViewHandler = () => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const showClientView = !this.state.showClientView;
    this.setState({
      showClientView,
    });
  };

  render() {
    return (
      <Navbar
        className="navbar-custom sticky-top link_container border-bottom-1"
        variant={this.props.theme}
      >
        <Link to="/">
          <Navbar.Brand>
            <img
              src={this.props.imgSrc}
              alt={this.props.imgName}
              className="navbar__brand--img"
            />
          </Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className="justify-content-end">
          {this.getMenuItems()}
        </Navbar.Collapse>
        <span className="data-description">
          {this.state.name} {this.state.scenarioName ? '/' : ''}{' '}
          {this.state.scenarioName}
        </span>
        <Nav key="1" className="text-left">
          <Nav.Link
            className="nav_link text-left"
            key="theme-control"
            onClick={this.props.onSwitchThemeClick}
          >
            {this.props.theme === 'light' ? (
              <MoonIcon className="navbar__theme--img" />
            ) : (
              <SunIcon className="navbar__theme--img" />
            )}
          </Nav.Link>
        </Nav>
        <AuthenticationUser
          user={this.props.user}
          onLogOut={this.props.onLogOut}
          onSetClientView={this.onSetClientViewHandler}
          showClientView={this.state.showClientView}
        />
      </Navbar>
    );
  }
}
// TopNavBar.contextType = DataAPIContextxxx;

// export default TopNavbar;
// eslint-disable-next-line no-undef
