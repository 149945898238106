import React from 'react';
import { Row } from 'react-bootstrap';
import { ScenarioStats } from '../../dataAPI/scenarioFuncs';
import ScoreCard from './ScoreCard';

interface IScenarioStats {
  scenarioStats: ScenarioStats;
}

function VScoreBoard({ scenarioStats }: IScenarioStats) {
  if (!scenarioStats) return <Row />;

  return (
    <Row className="r-border s-bg gap-1">
      <ScoreCard
        name="Lifetime Baseline Emissions"
        value={scenarioStats.lifetimeBaselineEmissions}
        units="tCO2e"
      />
      <ScoreCard
        name="Lifetime Reduced Emissions"
        value={scenarioStats.lifetimeEmissionReduction}
        units="tCO2e"
      />
      <ScoreCard
        name="Lifetime Reduction %"
        value={scenarioStats.lifetimeReductionPercentage}
        units="%"
      />
      <ScoreCard
        name="Lifetime Abatement Cost"
        value={scenarioStats.lifetimeAbatementCost}
        units="$/tCO2e"
      />
      <ScoreCard name="Net Cost" value={scenarioStats.netCost} units="$" />
      <ScoreCard name="Capital Cost" value={scenarioStats.capCost} units="$" />
      {/* {scenarioStats.costIncludesCarbonTax ? ( */}
      <ScoreCard
        name="Total Carbon Tax Savings"
        value={scenarioStats.carbonTaxSavings}
        units="$"
      />
      <ScoreCard
        name="Offsets Start"
        value={scenarioStats.offsetStartYear}
        units="Year"
        noThousandSeperator
      />
      <ScoreCard
        name="Annual Baseline Emissions"
        value={scenarioStats.annualBaselineEmissions}
        units="tCO2e"
      />
      <ScoreCard
        name="Annual Reduced Emissions"
        value={scenarioStats.annualReduction}
        units="tCO2e"
      />
      <ScoreCard
        name="Annual Reduction %"
        value={scenarioStats.annualReductionPercentage}
        units="%"
      />
      <ScoreCard
        name="First Year Abatement Cost"
        value={scenarioStats.firstYearAbatementCost}
        units="$/tCO2e"
      />
      <ScoreCard name="Number of Projects" value={scenarioStats.projectCount} />
      <ScoreCard
        name="Scope 1 Emissions"
        value={scenarioStats.scope1}
        units="tCO2e"
      />
      <ScoreCard
        name="Scope 2 Emissions"
        value={scenarioStats.scope2}
        units="tCO2e"
      />
      <ScoreCard
        name="Offsets Cost Total"
        value={scenarioStats.lifetimeOffsetCostTotal}
        currency="$"
      />
      <ScoreCard
        name="Scenario cost"
        value={scenarioStats.scenarioCost}
        currency="$"
      />
    </Row>
  );
}

export default VScoreBoard;
