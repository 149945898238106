import * as React from 'react';
import { Button, Row } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { saveExcel } from '../../../data/io/saveData';
import { IScenarioState } from './scenarioTypes';

export interface IProps {
  scenarios: IScenarioState;
}

export default function ScenarioScopeEmissions({ scenarios }: IProps) {
  const STYLE_INDENT = { paddingLeft: '50px' };
  const keys: number[] = [];
  const names: string[] = [];
  const capCost: number[] = [];
  const scope1: number[] = [];
  const scope2: number[] = [];
  const baselineScope1: number[] = [];
  const baselineScope2: number[] = [];
  const annualBaselineEmissions: number[] = [];
  const annualEmissions: number[] = [];
  const annualReduction: number[] = [];
  const carbonTaxSavings: number[] = [];
  const annualElecUsage: number[] = [];
  const annualElectricityUsageDiff: number[] = [];
  const annualVentingVol: number[] = [];
  const annualVentingVolDiff: number[] = [];
  const annualFlareVol: number[] = [];
  const annualFlareVolDiff: number[] = [];
  const annualDieselConsumption: number[] = [];
  const annualDieselConsumptionDiff: number[] = [];
  const annualPropaneConsumption: number[] = [];
  const annualPropaneConsumptionDiff: number[] = [];
  const annualNatGasConsumption: number[] = [];
  const annualNatGasConsumptionDiff: number[] = [];
  const annualGasolineConsumption: number[] = [];
  const annualGasolineConsumptionDiff: number[] = [];
  const { period } = scenarios.timeseries[0];
  // const fromYear = period[0];
  const toYear = period[period.length - 1];

  scenarios.scenarios.forEach((scenario, index) => {
    if (!scenario.show) return;
    keys.push(scenario.key);
    names.push(scenario.name);
    capCost.push(scenarios.stats[index].capCost);
    scope1.push(scenarios.stats[index].scope1);
    scope2.push(scenarios.stats[index].scope2);
    baselineScope1.push(scenarios.stats[index].baselineScope1);
    baselineScope2.push(scenarios.stats[index].baselineScope2);
    annualBaselineEmissions.push(
      scenarios.stats[index].annualBaselineEmissions
    );
    annualEmissions.push(scenarios.stats[index].annualEmissions);
    annualReduction.push(scenarios.stats[index].annualReduction);
    carbonTaxSavings.push(scenarios.stats[index].carbonTaxSavings);
    annualElecUsage.push(scenarios.stats[index].annualElecUsage);
    annualElectricityUsageDiff.push(
      -scenarios.stats[index].annualElecUsage +
        scenarios.stats[0].annualElecUsage
    );
    annualVentingVol.push(scenarios.stats[index].annualVentingVol);
    annualVentingVolDiff.push(
      -scenarios.stats[index].annualVentingVol +
        scenarios.stats[0].annualVentingVol
    );
    annualFlareVol.push(scenarios.stats[index].annualFlareVol);
    annualFlareVolDiff.push(
      -scenarios.stats[index].annualFlareVol + scenarios.stats[0].annualFlareVol
    );
    annualDieselConsumption.push(
      scenarios.stats[index].annualDieselConsumption
    );
    annualDieselConsumptionDiff.push(
      -scenarios.stats[index].annualDieselConsumption +
        scenarios.stats[0].annualDieselConsumption
    );
    annualPropaneConsumption.push(
      scenarios.stats[index].annualPropaneConsumption
    );
    annualPropaneConsumptionDiff.push(
      -scenarios.stats[index].annualPropaneConsumption +
        scenarios.stats[0].annualPropaneConsumption
    );
    annualNatGasConsumption.push(
      scenarios.stats[index].annualNatGasConsumption
    );
    annualNatGasConsumptionDiff.push(
      -scenarios.stats[index].annualNatGasConsumption +
        scenarios.stats[0].annualNatGasConsumption
    );
    annualGasolineConsumption.push(
      scenarios.stats[index].annualGasolineConsumption
    );
    annualGasolineConsumptionDiff.push(
      -scenarios.stats[index].annualGasolineConsumption +
        scenarios.stats[0].annualGasolineConsumption
    );
  });
  const appPercent = (n: any, d: any) => {
    if (n && d) return `${Math.round((n / d) * 100).toLocaleString()} %`;
    return '-';
  };

  return (
    <div className=" r-border p-4 ">
      <Row>
        <div className="overflow-auto mt-2">
          <h3 className="ps-2">Scenario Insight</h3>
          <table className="table table-bordered table-hover table-sm mt-3">
            <thead>
              <tr>
                <td className="col-8">Scenario</td>
                {names.map((name, i) => (
                  // <td className="col-2" key={`k${keys[i]}`}>
                  <td
                    style={{ minWidth: '100px' }}
                    className="text-end"
                    key={`k${keys[i]}`}
                  >
                    {name}
                  </td>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Total Capital Spend until {toYear}</td>
                {capCost.map((cost, i) => (
                  <td className="text-end" key={`k${keys[i]}`}>
                    $ {Math.round(cost).toLocaleString()}
                  </td>
                ))}
              </tr>

              <tr>
                <td>Total Baseline Annual Emissions [tCO2e]</td>
                {annualBaselineEmissions.map((e, i) => (
                  <td className="text-end" key={`k${keys[i]}`}>
                    {Math.round(e).toLocaleString()}
                  </td>
                ))}
              </tr>
              <tr>
                <td style={STYLE_INDENT}>Scope 1 [tCO2e]</td>
                {baselineScope1.map((cost, i) => (
                  <td className="text-end" key={`k${keys[i]}`}>
                    {Math.round(cost).toLocaleString()}
                  </td>
                ))}
              </tr>
              <tr>
                <td style={STYLE_INDENT}>Scope 2 [tCO2e]</td>
                {baselineScope2.map((cost, i) => (
                  <td className="text-end" key={`k${keys[i]}`}>
                    {Math.round(cost).toLocaleString()}
                  </td>
                ))}
              </tr>

              <tr>
                <td>Total Remaining Post-Project Emissions [tCO2e]</td>
                {annualEmissions.map((e, i) => (
                  <td className="text-end" key={`k${keys[i]}`}>
                    {Math.round(e).toLocaleString()}
                  </td>
                ))}
              </tr>
              <tr>
                <td style={STYLE_INDENT}>Scope 1 [tCO2e]</td>
                {scope1.map((cost, i) => (
                  <td className="text-end" key={`k${keys[i]}`}>
                    {Math.round(cost).toLocaleString()}
                  </td>
                ))}
              </tr>
              <tr>
                <td style={STYLE_INDENT}>Scope 2 [tCO2e]</td>
                {scope2.map((cost, i) => (
                  <td className="text-end" key={`k${keys[i]}`}>
                    {Math.round(cost).toLocaleString()}
                  </td>
                ))}
              </tr>

              <tr>
                <td>Annual Reduction</td>
                {annualReduction.map((e, i) => (
                  <td className="text-end" key={`k${keys[i]}`}>
                    {Math.round(e).toLocaleString()}
                  </td>
                ))}
              </tr>

              <tr>
                <td>Total Annual Emissions Reduced by {toYear} [%]</td>
                {annualBaselineEmissions.map((e, i) => (
                  <td className="text-end" key={`k${keys[i]}`}>
                    {appPercent(annualReduction[i], e)}
                  </td>
                ))}
              </tr>
              <tr>
                <td style={STYLE_INDENT}>Scope 1 Reduced by {toYear} [%]</td>
                {baselineScope1.map((e, i) => (
                  <td className="text-end" key={`k${keys[i]}`}>
                    {appPercent(e - scope1[i], e)}
                  </td>
                ))}
              </tr>
              <tr>
                <td style={STYLE_INDENT}>Scope 2 Reduced by {toYear} [%]</td>
                {baselineScope2.map((e, i) => (
                  <td className="text-end" key={`k${keys[i]}`}>
                    {appPercent(e - scope2[i], e)}
                  </td>
                ))}
              </tr>

              {/* <tr>
                <td style={STYLE_INDENT}>Diesel</td>
                {baselineScope1.map((e, i) => (
                  <td className="text-end" key={`k${keys[i]}`}>
                    {appPercent(e - scope1[i], e)}
                  </td>
                ))}
              </tr> */}
              {/* <tr>
                <td style={STYLE_INDENT}>Propane</td>
                {baselineScope1.map((e, i) => (
                  <td className="text-end" key={`k${keys[i]}`}>
                    {appPercent(e - scope1[i], e)}
                  </td>
                ))}
              </tr> */}
              {/* <tr>
                <td style={STYLE_INDENT}>Natural Gas</td>
                {baselineScope1.map((e, i) => (
                  <td className="text-end" key={`k${keys[i]}`}>
                    {appPercent(e - scope1[i], e)}
                  </td>
                ))}
              </tr> */}
              {/* <tr>
                <td>Electricity Usage 1000 M3</td>
                {annualBaselineEmissions.map((e, i) => (
                  <td className="text-end" key={`k${keys[i]}`}>
                    {appPercent(annualReduction[i], e)}
                  </td>
                ))}
              </tr> */}

              <tr>
                <td>Carbon Tax Savings</td>
                {carbonTaxSavings.map((e, i) => (
                  <td className="text-end" key={`k${keys[i]}`}>
                    $ {Math.round(e).toLocaleString()}
                  </td>
                ))}
              </tr>
              <tr>
                <td colSpan={capCost.length + 1}>Commodity Accounting</td>
              </tr>
              <tr>
                <td style={STYLE_INDENT}>Annual Electricity Usage [kWh]</td>
                {annualElecUsage.map((e, i) => (
                  <td className="text-end" key={`k${keys[i]}`}>
                    {Math.round(e).toLocaleString()}
                  </td>
                ))}
              </tr>
              <tr>
                <td style={STYLE_INDENT}>Annual Flare Volume [m3]</td>
                {annualFlareVol.map((e, i) => (
                  <td className="text-end" key={`k${keys[i]}`}>
                    {Math.round(e).toLocaleString()}
                  </td>
                ))}
              </tr>
              <tr>
                <td style={STYLE_INDENT}>Annual Venting Volume [m3]</td>
                {annualVentingVol.map((e, i) => (
                  <td className="text-end" key={`k${keys[i]}`}>
                    {Math.round(e).toLocaleString()}
                  </td>
                ))}
              </tr>
              <tr>
                <td style={STYLE_INDENT}>Annual Diesel Usage [m3]</td>
                {annualDieselConsumption.map((e, i) => (
                  <td className="text-end" key={`k${keys[i]}`}>
                    {Math.round(e).toLocaleString()}
                  </td>
                ))}
              </tr>
              <tr>
                <td style={STYLE_INDENT}>Annual Propane Usage [m3]</td>
                {annualPropaneConsumption.map((e, i) => (
                  <td className="text-end" key={`k${keys[i]}`}>
                    {Math.round(e).toLocaleString()}
                  </td>
                ))}
              </tr>
              <tr>
                <td style={STYLE_INDENT}>Annual Fuel Gas Usage [m3]</td>
                {annualNatGasConsumption.map((e, i) => (
                  <td className="text-end" key={`k${keys[i]}`}>
                    {Math.round(e).toLocaleString()}
                  </td>
                ))}
              </tr>
              <tr>
                <td style={STYLE_INDENT}>Annual Gasoline Usage [m3]</td>
                {annualGasolineConsumption.map((e, i) => (
                  <td className="text-end" key={`k${keys[i]}`}>
                    {Math.round(e).toLocaleString()}
                  </td>
                ))}
              </tr>
              <tr>
                <td colSpan={capCost.length + 1}>Commodity Savings</td>
              </tr>
              <tr>
                <td style={STYLE_INDENT}>Annual Electricity Savings [kWh]</td>
                {annualElectricityUsageDiff.map((e, i) => (
                  <td className="text-end" key={`k${keys[i]}`}>
                    {Math.round(e).toLocaleString()}
                  </td>
                ))}
              </tr>
              <tr>
                <td style={STYLE_INDENT}>Annual Flare Reduction [m3]</td>
                {annualFlareVolDiff.map((e, i) => (
                  <td className="text-end" key={`k${keys[i]}`}>
                    {Math.round(e).toLocaleString()}
                  </td>
                ))}
              </tr>
              <tr>
                <td style={STYLE_INDENT}>Annual Vent Reduction [m3]</td>
                {annualVentingVolDiff.map((e, i) => (
                  <td className="text-end" key={`k${keys[i]}`}>
                    {Math.round(e).toLocaleString()}
                  </td>
                ))}
              </tr>
              {/* <tr>
                <td style={STYLE_INDENT}>Annual Fuel Gas Savings [m3]</td>
                {annualBaselineEmissions.map((e, i) => (
                  <td className="text-end" key={`k${keys[i]}`}>
                    {appPercent(annualReduction[i], e)}
                  </td>
                ))}
              </tr> */}
              <tr>
                <td style={STYLE_INDENT}>Annual Diesel Savings [m3]</td>
                {annualDieselConsumptionDiff.map((e, i) => (
                  <td className="text-end" key={`k${keys[i]}`}>
                    {Math.round(e).toLocaleString()}
                  </td>
                ))}
              </tr>
              <tr>
                <td style={STYLE_INDENT}>Annual Propane Savings [m3]</td>
                {annualPropaneConsumptionDiff.map((e, i) => (
                  <td className="text-end" key={`k${keys[i]}`}>
                    {Math.round(e).toLocaleString()}
                  </td>
                ))}
              </tr>
              <tr>
                <td style={STYLE_INDENT}>Annual Fuel Gas Savings [m3]</td>
                {annualNatGasConsumptionDiff.map((e, i) => (
                  <td className="text-end" key={`k${keys[i]}`}>
                    {Math.round(e).toLocaleString()}
                  </td>
                ))}
              </tr>
              <tr>
                <td style={STYLE_INDENT}>Annual Gasoline Savings [m3]</td>
                {annualGasolineConsumptionDiff.map((e, i) => (
                  <td className="text-end" key={`k${keys[i]}`}>
                    {Math.round(e).toLocaleString()}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>

        <div className="d-flex flex-row-reverse bd-highlight p-3">
          <Button
            type="button"
            variant="primary"
            size="sm"
            className="btn col-2 btn-lg mx-1 save__btn"
            onClick={() => {
              const jsonData = {
                inSights: scenarios.stats,
              };
              saveExcel(jsonData, 'rp-scenario-insight-view.xlsx');
            }}
          >
            Scenario Insight{' '}
            <Icon.CloudArrowDownFill className="save__btn-icon" />
          </Button>
        </div>
      </Row>
    </div>
  );
}
