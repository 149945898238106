import { Button, Container } from 'react-bootstrap';
import React, { useContext } from 'react';
import * as Icon from 'react-bootstrap-icons';
import { DataAPIContext } from '../../contexts/data';
import { auditReportToExcel } from '../../data/io/saveData';

function AuditReport() {
  const { dataClient, unpackResponse } = useContext(DataAPIContext);
  return (
    <Container>
      <h1>Audit Report</h1>
      <Button
        className="btn btn-lg btn_min--width save__btn mx-1"
        onClick={async () => {
          const results = await unpackResponse(dataClient.createAuditReport());
          auditReportToExcel(results, results.fileName);
        }}
      >
        Download Audit Report
        <Icon.CloudArrowDownFill className="save__btn-icon" />
      </Button>
    </Container>
  );
}

export default AuditReport;
