/**
 * The simplest Pub / Sub pattern I could think of.
 * The first implementation is simple to update the title component when
 * file names change or when scenarios change.
 */
const state = {};

export const Topic = {
  Header: 'Header',
};

export const appPubSub = {
  subscribe(topic, subscriber) {
    const t = findOrAddTopic(topic);
    t.subscribers.push(subscriber);
    subscriber(t.values);
  },

  publish(topic, values) {
    const t = findOrAddTopic(topic);
    t.values = { ...t.values, ...values };
    if (t.subscribers) {
      t.subscribers.forEach((sub) => {
        sub(t.values);
      });
    }
  },
};

function findOrAddTopic(topic) {
  if (!(topic in state)) {
    state[topic] = { subscribers: [], values: {} };
  }
  return state[topic];
}
