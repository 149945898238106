import React from 'react';

export function FileSelect(props) {
  const inputFileRef = React.createRef();
  React.useEffect(() => {
    // Update the document title using the browser API
    // document.title = `You clicked ${count} times`;
    inputFileRef.current.click();
  });
  function onFileSelected(e) {
    const file = e.target.files[0];
    // const filetype = file.name.split(".")[1].toLowerCase();
    // So the same file can be loaded multiple times.
    e.target.value = null;
    if (props.process) props.process(file);
  }

  return (
    <>
      Hello world
      <input
        type="file"
        name="filename"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .json"
        ref={inputFileRef}
        onChange={onFileSelected}
      />
    </>
  );
}
