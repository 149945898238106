import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';

function ImplementationDatePicker(props) {
  const setStartDate = (date) => {
    props.setImplementationDate(new Date(date));
  };
  return (
    <>
      <span>Implementation Date</span>
      <DatePicker
        selected={props.implementationDate}
        onChange={(date) => setStartDate(date)}
        dateFormat="yyyy-MM"
        showMonthYearPicker
        showFullMonthYearPicker
      />
    </>
  );
}

function StatusSelector(props) {
  return (
    <Form.Group className="mb-3">
      <Form.Label>Set Project Status To</Form.Label>
      <Form.Select disabled>
        <option>{props.status}</option>
      </Form.Select>
    </Form.Group>
  );
}

function ProjectEditModal(props) {
  const [implementationDate, setImplementationDate] = useState(new Date());
  const [status, setStatus] = useState(props.setStatusTo);

  const onConfirm = () => {
    props.onConfirm({ implementationDate, status });
  };

  return (
    <Modal show={props.show} centered>
      <Modal.Body>
        <h4>{props.message}</h4>
        <p>
          {props.setStatusTo ?? (
            <StatusSelector status={status} setStatus={setStatus} readOnly />
          )}
          {props.includeDate ?? (
            <ImplementationDatePicker
              implementationDate={implementationDate}
              setImplementationDate={setImplementationDate}
            />
          )}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onConfirm}>
          Yes
        </Button>
        <Button variant="secondary" onClick={props.onDecline}>
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ProjectEditModal;
