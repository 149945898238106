import * as React from 'react';
import Plot from 'react-plotly.js';
import { PlotMouseEvent } from 'plotly.js-dist-min';

import { SiteView } from '../../../dataAPI/Views';
import { IPieChartProps } from '../chartTypes';
import equipmentPieChartDataBuilder from './pieChartDataBuilder';
import { getConfig, getLayout } from '../Layouts';

export default function PieChart({
  typeData,
  onClick,
  title = '',
  text = '',
  hole = 0,
  theme = 'light',
}: IPieChartProps) {
  const data: SiteView[] = [];
  const onClickHandler = onClick
    ? (e: PlotMouseEvent) => onClick(e)
    : undefined;

  if (typeData && typeData.length > 0) {
    typeData.forEach((site) => {
      data.push({
        key: site.key,
        name: site.name,
        lat: site.lat,
        long: site.long,
        yearlyEmissions: site.yearlyEmissions,
        type: site.type,
      });
    });
  }
  const pieChartData = equipmentPieChartDataBuilder(data, title, hole);
  const layout = getLayout(title, text, '', true, false, '', 0, theme);
  return (
    <Plot
      data={pieChartData as any}
      layout={layout}
      useResizeHandler
      style={{ width: '100%', height: '100%' }}
      onClick={onClickHandler}
      config={getConfig()}
    />
  );
}
