import { Container, Nav } from 'react-bootstrap';
import React from 'react';
import { Link } from 'react-router-dom';
// import useLocalStorage from "use-local-storage";

const emtLogoW = require('../../images/EMT-LOGO-10-10_W.png');
// const emtLogoD = require("../../images/EMT-LOGO-10-10.png");

function NotFound() {
  // const defaultDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
  // const theme = useLocalStorage("theme", defaultDark ? "dark" : "light");

  return (
    <Container className="not-found">
      <Container className="not-found__container">
        <div className="col-md-5 d-flex flex-row-reverse">
          <img className="not-found__image" src={emtLogoW} alt="EMT logo" />
        </div>
        <div className="col-md-6 align-self-center">
          <h1>404 Page Not Found</h1>
          <p>
            The page you are looking for does not exist. How you got here is a
            mystery. But you can click the button below to go back to the
            homepage.
          </p>
          <Nav.Link
            as={Link}
            to="/"
            type="button"
            className="btn  btn-lg mx-1 nav_link_button"
          >
            Back To Home
          </Nav.Link>
        </div>
      </Container>
    </Container>
  );
}

export default NotFound;
