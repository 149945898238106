import React, { useEffect } from 'react';
import Plot from 'react-plotly.js';
import { Button } from 'react-bootstrap';
import { SiteTypeView, SiteView } from '../../../dataAPI/Views';
import { getConfig, getLayout } from '../../plots/Layouts';
import isDarkMode from '../../../utils/lightDarkMode';
import { Dark24 } from '../../../utils/Colors';
import { convertArrayToObject } from '../../../utils/convertArrayToObject';

export interface IProps {
  sites: SiteView[];
  siteTypeList: SiteTypeView[];
}

export default function SiteTypeVsAnnualEmissions({
  sites,
  siteTypeList,
}: IProps) {
  if (!sites || sites.length === 0) return <> </>;

  const [inputs, setInputs] = React.useState(true);
  const [theme, setTheme] = React.useState(isDarkMode());
  const [siteTypeLookup, setSiteTypeLookup] = React.useState(
    convertArrayToObject(siteTypeList, 'key', ['key', 'name'])
  );

  useEffect(() => {
    setTheme(isDarkMode());
  });

  useEffect(() => {
    setSiteTypeLookup(
      convertArrayToObject(siteTypeList, 'key', ['key', 'name'])
    );
  }, [siteTypeList]);

  const handleChange = (isBar: boolean) => {
    setInputs(isBar);
  };

  const summary: { [key: string]: [value: number] } = sites.reduce(
    (p: any, c: any): any => {
      const key = c.type;
      const name = c.name;
      let value = c.yearlyEmissions;
      value += p[key] ? p[key].value : 0;
      return { ...p, [key]: { key, value, name } };
    },
    {}
  );

  const orderedList: any = Object.values(summary).sort(
    (a: any, b: any) => a.value - b.value
  );

  const x = orderedList.map((e: { key: string }) => {
    const name = siteTypeLookup[e.key]?.name ?? e.key;
    return name.substring(0, 30);
  });

  const y = orderedList.map((e: { value: number }) => e.value);
  let trace1;

  if (inputs)
    trace1 = {
      labels: x,
      values: y,
      type: 'pie',
      hovertemplate: '%{label}<br><b>%{value:,.0f}</b><extra></extra>',
      hole: 0.4,
      hoverinfo: 'label',
      marker: {
        colors: Dark24,
      },
      domain: { x: [0, 0.5] },
    };
  else
    trace1 = {
      x,
      y,
      type: 'bar',
      hovertemplate: '%{x}<br><b>%{y}</b><extra></extra>',
      marker: {
        colors: Dark24,
      },
      domain: { x: [0, 0.5] },
    };

  const layout = getLayout(
    'Site Type vs Annual Emissions',
    'Activity Type',
    'Annual Emissions (tCO\u2082e)',
    inputs, // Only show legend for Pie graph
    false,
    '',
    1,
    theme,
    450, // height
    'pie'
  );

  const traces = [trace1];

  return (
    <div className="chart__container  r-border">
      <div className="btn__container">
        <Button
          className="chart__toggle-btn btn btn-primary"
          onClick={() => handleChange(!inputs)}
        >
          {inputs ? 'Switch to Bar' : 'Switch to Pie'}
        </Button>
      </div>
      <Plot
        data={traces as any}
        layout={layout}
        useResizeHandler
        config={getConfig()}
      />
    </div>
  );
}
