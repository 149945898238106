import React, { useState } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import ProjectEditModal from '../../../popups/ProjectEditModal';
import ProjectMacc from '../../../plots/ProjectMacc';
import ProjList from '../../../lists/ProjList';
import { ProjectState } from '../../../../data/ProjectState.js';
import { SortFilterWidget } from '../../../filters/projectFilter';
import LineChart from '../../../plots/ScenarioTimeseries';
import { SortFilter } from '../../../../utils/SortingFuncs';
import RpModal from '../../../utilities/RpModal';

const potential = ProjectState.Potential;
const completed = ProjectState.Completed;

let changeToStatus = null;
let modalConfirmMsg = '';

function ActiveProjectCard(props) {
  const {
    onProjectEdit,
    committedProjects,
    colorMap,
    scenarioTimeseries,
    scenarioName,
  } = props;
  const [selectedProjects, onSelect] = useState([]);
  const [showProjectEditModal, setShowProjectEditModal] = useState(false);
  const [selProjectCount, setSelProjectCount] = useState(100);
  const [sortDirection, setSortDirection] = useState('asc');
  const [sortBy, setSortBy] = useState('lifetimeAbatementCost');
  const [showMacc, setShowMacc] = useState(false);

  function handleShowMacc() {
    setShowMacc(true);
  }

  const unCommitProjects = () => {
    changeToStatus = potential;
    modalConfirmMsg = `Are you sure you want to edit ${selectedProjects.length} project(s)?`;
    setShowProjectEditModal(true);
  };
  const completeProjects = () => {
    changeToStatus = completed;
    modalConfirmMsg = `Are you sure you want to edit ${selectedProjects.length} project(s)?`;
    setShowProjectEditModal(true);
  };

  const onConfirm = (propertiesToSet) => {
    setShowProjectEditModal(false);
    onProjectEdit(selectedProjects, propertiesToSet);
  };

  const onDecline = () => {
    setShowProjectEditModal(false);
  };

  const onSetSelProjectCount = (r) => {
    setSelProjectCount(r.target.value);
  };

  return (
    <>
      {showProjectEditModal && (
        <ProjectEditModal
          show={showProjectEditModal}
          setStatusTo={changeToStatus}
          onConfirm={onConfirm}
          onDecline={onDecline}
          message={modalConfirmMsg}
        />
      )}
      <Row>
        <Col className="mt-3 mb-3">
          <h2>Current Scenario (Committed Projects)</h2>
        </Col>
      </Row>
      <Row>
        <Col className="col-6">
          <Row>
            <Col className="col-4">
              <Button
                className=" btn-lg w-100 save__btn  mb-1"
                disabled={selectedProjects.length > 0 ? '' : true}
                onClick={unCommitProjects}
              >
                Uncommit Projects
              </Button>
              <Button
                className=" btn-lg w-100 save__btn mb-1"
                disabled={selectedProjects.length > 0 ? '' : true}
                onClick={completeProjects}
              >
                Complete Projects
              </Button>
            </Col>
            <Col className="col-8">
              <SortFilterWidget
                cardID="active"
                selected={selProjectCount}
                onChange={onSetSelProjectCount}
                sortDirection={sortDirection}
                handleSortDirection={setSortDirection}
                sortBy={sortBy}
                handleSortBy={setSortBy}
                sortOptions={[
                  {
                    key: 'dd-abatementCost',
                    value: 'lifetimeAbatementCost',
                    text: 'Abatement Cost',
                  },
                  { key: 'dd-cost', value: 'capCost', text: 'Cost' },
                  {
                    key: 'dd-emissionReduction',
                    value: 'lifetimeReduction',
                    text: 'Emissions Reduction',
                  },
                ]}
              />
            </Col>
          </Row>
          <ProjList
            cardID={1}
            projects={SortFilter(
              committedProjects,
              sortBy,
              sortDirection,
              selProjectCount
            )}
            showStatus
            showImplementationDate
            onSelect={onSelect}
            onClickRow={(r) => console.log(r)}
          />
        </Col>
        <Col className="col-6">
          <Row>
            <Col>
              <div className="r-border h-100">
                <RpModal show={showMacc} setShow={setShowMacc}>
                  <ProjectMacc
                    title="Project MACC"
                    cardID="active-projects"
                    projects={committedProjects}
                    onClick={(r) => console.log(r)}
                    colorMap={colorMap}
                  />
                </RpModal>
                <ProjectMacc
                  title="Project MACC"
                  cardID="active-projects"
                  projects={committedProjects}
                  onClick={(r) => console.log(r)}
                  colorMap={colorMap}
                  handleShowClick={handleShowMacc}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col className=" m-0 mt-3">
              {scenarioTimeseries ? (
                <LineChart
                  timeseries={[scenarioTimeseries]}
                  scenarioNames={[scenarioName]}
                  requestedSeries={{
                    emissions: true,
                    cost: false,
                    carbonTax: false,
                  }}
                  showlegend={false}
                />
              ) : undefined}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default ActiveProjectCard;
