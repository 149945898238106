/**
    Approximate the lat long based on a DLS or NTS for example
    - 01-01-001-01W4
    (from the BC Energy Regulator)
    - DLS: 16-12-084-14
    - NTS: B 038-J/094-H-02

 The results are close but not correct. I think good enough.

Roughly where the formula came from

https://stackoverflow.com/questions/7477003/calculating-new-longitude-latitude-from-old-n-meters

The number of kilometers per degree of longitude is approximately
(pi/180) * r_earth * cos(theta*pi/180)
where theta is the latitude in degrees and r_earth is approximately 6378 km.
    3959 miles

The number of kilometers per degree of latitude is approximately the same at all locations, approx
(pi/180) * r_earth = 111 km / degree

https://en.wikipedia.org/wiki/Dominion_Land_Survey

https://www.ihsenergy.ca/support/documentation_ca/Harmony_Enterprise/2018_1/content/html_files/ref_materials/general_concepts/gis_theory/gis_theory.htm#NTS

 Good Converter:
https://locator.scadalink.com/dls2latlng/

*/
import { ntsToLatLong } from './ntsToLatLong.ts';

// Big Note. Leave these here. They have been looked up
// and are correct. Our calc is an approximation only.
const testData = [
  { dls: '01-01-001-01W4', lat: 49.001589, long: -110.007852 },
  { dls: '01-01-010-01W4', lat: 49.787574, long: -110.007896 },
  { dls: '01-01-020-01W4', lat: 50.660955, long: -110.007988 },
  { dls: '01-01-030-01W4', lat: 51.534289, long: -110.008039 },
  { dls: '01-01-040-01W4', lat: 52.406653, long: -110.008054 },
  { dls: '01-01-050-01W4', lat: 53.279753, long: -110.008692 },
  { dls: '01-01-060-01W4', lat: 54.153157, long: -110.008755 },
  { dls: '01-01-100-01W4', lat: 57.64359, long: -110.009381 },
  { dls: '01-01-001-01W4', lat: 49.001589, long: -110.007852 },
  { dls: '02-02-002-02W4', lat: 49.088211, long: -110.170304 },
  { dls: '13-05-054-13W5', lat: 53.641114, long: -115.890379 },
  { dls: '15-27-054-14W5', lat: 53.699361, long: -115.976348 },
  { dls: '05-34-055-21W4', lat: 53.794371, long: -113.047049 },
  { dls: '08-17-029-22W4', lat: 51.479534, long: -113.055377 },
  { dls: '01-01-001-01W4', lat: 49.001592, long: -110.007886 },
  { dls: '01-01-001-11W4', lat: 48.999342, long: -111.344673 },
  { dls: '01-01-001-21W4', lat: 49.000738, long: -112.679924 },
];

export function testConvert() {
  try {
    const converted = testData.map((d) => {
      const [lat, long] = locationToLatLong(d.dls);
      return [d.dls, d.lat, d.long, lat, long];
    });
    download(converted);
  } catch (e) {
    console.error(e);
  }
}

/**
 * Download in csv format
 *
 * @param {Array} rows of data to download
 */
export function download(rows) {
  const csvContent = `data:text/csv;charset=utf-8,${rows
    .map((e) => e.join(','))
    .join('\n')}`;
  window.open(csvContent);
}

/**
 * conversion table
 */
const merToLong = {
  W2: 102,
  W3: 106,
  W4: 110,
  W5: 114,
  W6: 118,
  W7: 122,
};
/**
 * Approximate the lat long of a DLS location.
 *
 * @param {String} location format: 01-01-001-01W4
 */
export function locationToLatLong(location) {
  let dls = location;
  if (location) {
    if (location.slice(0, 4) === 'DLS:') {
      dls = `${location.slice(5)}W6`;
    } else if (location.slice(0, 4) === 'NTS:') {
      return ntsToLatLong(location.slice(5));
    }
  } else {
    throw new Error('No location given.');
  }

  // The following tries to convert dls to latlong...

  const parts = dls.split('-');
  if (parts.length !== 4) {
    throw new Error(
      `DLS must have 4 parts only ${parts.length} found in "${dls}".`
    );
  }
  const [lsd, sec, twp, rngmer] = parts;
  // console.log(lsd, sec, twp, rngmer);
  if (isNaN(lsd) || lsd < 1 || lsd > 16) {
    throw new Error(`LSD must be between 1 - 16, "${lsd}" is not.`);
  }
  if (isNaN(sec) || sec < 1 || sec > 36) {
    throw new Error(`Section must be between 1 - 36, "${sec}" is not.`);
  }
  if (isNaN(twp) || twp < 1 || twp > 132) {
    throw new Error(`Township must be between 1 - 132, "${twp}" is not.`);
  }
  const rng = rngmer.slice(0, 2);
  if (isNaN(rng) || rng < 1 || rng > 33) {
    throw new Error(`Range must be between 1 - 33, "${rng}" is not.`);
  }
  const mer = rngmer.slice(2, 4);

  const lat = degNorthOfBase(lsd, sec, twp);
  const long = degEastOfBase(mer, lat, rng, sec, lsd);
  return [lat, long];
}

/**
 * Calculate the degrees north of base.
 */
export function degNorthOfBase(lsd, sec, twp) {
  const radiusEarthMiles = 3959;
  const MilesPerDegree = (Math.PI / 180) * radiusEarthMiles;
  const sectionRow = Math.ceil(sec / 6) - 1;
  const lsdAdjust = (Math.ceil(lsd / 4) - 1) / 4 + 1 / 8;
  const milesNorth = (twp - 1) * 6 + sectionRow + lsdAdjust;
  const degNorth = milesNorth / MilesPerDegree;
  // I found that if do this correction it is very close
  const correction = twp * 0.00048 - 0.0007;
  const lat = 49 + degNorth + correction;
  // console.log(milesNorth, degNorth, lat);
  return lat;
}

const sectionColOffset = [
  0, // Ignore base zero attribute
  0,
  1,
  2,
  3,
  4,
  5,
  5,
  4,
  3,
  2,
  1,
  0,
  0,
  1,
  2,
  3,
  4,
  5,
  5,
  4,
  3,
  2,
  1,
  0,
  0,
  1,
  2,
  3,
  4,
  5,
  5,
  4,
  3,
  2,
  1,
  0,
];

const lsdColOffset = [
  0, // Ignore base zero attribute
  0, // Ignore base zero attribute
  0,
  1,
  2,
  3,
  3,
  2,
  1,
  0,
  0,
  1,
  2,
  3,
  3,
  2,
  1,
  0,
];

export function degEastOfBase(mer, lat, rng, sec, lsd) {
  // (pi/180) * r_earth * cos(theta*pi/180)
  const radiusEarthMiles = 3959;
  const MilesPerDegree =
    (Math.PI / 180) * radiusEarthMiles * Math.cos((lat * Math.PI) / 180);

  const baseLong = merToLong[mer];
  if (!baseLong) {
    throw new Error(
      `Meridian not handled must be between W2 - W7, "${mer}" is not.`
    );
  }

  const secNum = parseInt(sec);
  const lsdNum = parseInt(lsd);

  const milesEast =
    (rng - 1) * 6 +
    sectionColOffset[secNum] +
    (lsdColOffset[lsdNum] * 0.25 + 0.125);
  const degEast = milesEast / MilesPerDegree;

  const correction = rng * 0.00125;

  const long = (baseLong + degEast + correction) * -1;

  return long;
}
