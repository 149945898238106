export const formatNumNavigator = (num) => {
  if (num) {
    // eslint-disable-next-line no-undef
    return num.toLocaleString(navigator.language);
  }
  return null;
};

export const formatNumRoundTwoDecimals = (num) => {
  if (typeof num === 'number') {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  }
  return null;
};

export function numberWithCommas(x) {
  if (x) return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
  return 0;
}
