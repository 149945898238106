import React from 'react';
import { Col, Button, Nav, Row } from 'react-bootstrap';

import { isAdminAccount } from '../../../security/security';

class AuthenticationUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
    };
  }

  componentDidMount() {
    this.setState({
      user: this.props.user,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        user: this.props.user,
      });
    }
  }

  render() {
    return (
      <div className="authentication-user">
        {this.state.user ? (
          <Row>
            <Col>
              {isAdminAccount() ? (
                <Button
                  onClick={() => this.props.onSetClientView()}
                  className="btn  btn-lg mx-1 btn_min--width save__btn"
                >
                  {this.props.showClientView
                    ? 'Switch to Client View'
                    : 'Switch to Admin View'}
                </Button>
              ) : null}
            </Col>
            <Col>
              <Nav.Link
                className="nav_link authentication-user__link"
                key="logout"
                onClick={this.props.onLogOut}
              >
                logout {this.state.user.fname}
              </Nav.Link>
            </Col>
          </Row>
        ) : null}
      </div>
    );
  }
}

export default AuthenticationUser;
