import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import * as Icon from 'react-bootstrap-icons';
import { Col, Row, Button } from 'react-bootstrap';
import SiteList from '../lists/SiteList';
import { DataAPIContext } from '../../contexts/data';
import { Site } from '../../data/Site';
import { saveExcel } from '../../data/io/saveData';
import ListFilter from '../utilities/ListFilter';

function SiteAll() {
  const { dataClient, unpackResponse } = useContext(DataAPIContext);

  const [mainOpt, setMainOpt] = useState<string>('');
  const [siteList, setSiteList] = useState<Site[]>([]);
  const [filterList, setFilterList] = useState<Site[]>([]);

  useEffect(() => {
    setMainOpt('list');
    getSites();
  }, []);

  useEffect(() => {
    setFilteredList(siteList);
  }, [siteList]);

  const getSites = async () => {
    const sites = await unpackResponse(dataClient.getSites());
    setSiteList(sites);
  };

  const setFilteredList = (filtered: Site[]) => {
    setFilterList(filtered);
  };

  return (
    <>
      <Row className="space-between">
        <Col>
          <h2 className="p-3">All Sites</h2>
        </Col>
        <Col>
          <ListFilter
            list={siteList}
            setFilteredList={setFilteredList}
            selectOptions={{
              key: 'Key',
              name: 'Name',
              type: 'Type',
              // lat: "Lat", // does not like numbers yet
              // long: "Long",
            }}
          />
        </Col>
        <Col>
          <div className="d-flex flex-row-reverse p-3">
            <Button
              type="button"
              variant="primary"
              size="sm"
              className="btn  btn-lg btn_min--width save__btn mx-1"
              onClick={() => {
                const jsonData = { filterList };
                saveExcel(jsonData, 'rp-site-view.xlsx');
              }}
            >
              Sites Save <Icon.CloudArrowDownFill className="save__btn-icon" />
            </Button>
          </div>
        </Col>
      </Row>
      {mainOpt === 'list' && <SiteList siteList={filterList} sortable />}
    </>
  );
}
export default SiteAll;
