import Plotly from 'plotly.js-dist-min';

const ChartColors = {
  font: {
    dark: 'rgba(255, 255, 255, .5)',
    light: 'rgba(0,0,0,.3)',
  },
  title: {
    dark: 'rgba(255, 255, 255,1)',
    light: 'rgba(0,0,0,.9)',
  },
  axisTitle: {
    dark: 'rgba(255, 255, 255,.7)',
    light: 'rgba(0,0,0,.9)',
  },
  grid: {
    dark: 'rgba(255, 255, 255,.15)',
    light: 'rgba(0,0,0,.2)',
  },
  hoverLabel: {
    dark: 'rgba(255, 255, 255,.6)',
    light: 'rgba(0,0,0,9)',
  },
  zeroLine: {
    dark: '#727272',
    light: 'rgba(0,0,0,.9)',
  },
  legend: {
    dark: 'rgba(255, 255, 255,.5)',
    light: 'rgba(0,0,0,.9)',
  },
  modeBar: {
    dark: 'rgba(255, 255, 255,.6)',
    light: 'rgba(0,0,0,.9)',
  },
  paperBg: {
    dark: 'rgba(250,250,250, 0.02)',
    light: '#f8f8f8',
  },
};

export function getLayout(
  title = '',
  xTitle = '',
  yTitle = '',
  showLegend = false,
  hasSecondAxis = false,
  y2Title = '',
  rows = 1,
  theme = 'light',
  height = 450,
  type = 'bar'
) {
  const layout: Partial<Plotly.Layout> = {
    font: {
      color: theme === 'dark' ? ChartColors.font.dark : ChartColors.font.light,
      size: 14,
      family: 'Suisse Regular, sans-serif',
    },
    title: {
      text: title,
      font: {
        size: 20,
        color:
          theme === 'dark' ? ChartColors.title.dark : ChartColors.title.light,
        family: 'Suisse Medium, sans-serif',
      },
      x: 0,
      pad: { t: 20, b: 20, l: 20, r: 20 },
    },
    height,
    hoverlabel: {
      bgcolor: '#fff',
      font: {
        size: 14,
        color: '#000',
      },
    },
    showlegend: showLegend,
    margin: { t: 70, r: 50, l: 70 },
    yaxis: {
      automargin: true,
      title: {
        text: yTitle,
        font: {
          size: 16,
          color:
            theme === 'dark'
              ? ChartColors.axisTitle.dark
              : ChartColors.axisTitle.light,
          family: 'Suisse Medium, sans-serif',
        },
      },
      hoverformat: ',.0f',
      zerolinecolor:
        theme === 'dark'
          ? ChartColors.zeroLine.dark
          : ChartColors.zeroLine.light,
      zerolinewidth: 1,
      gridcolor:
        theme === 'dark' ? ChartColors.grid.dark : ChartColors.grid.light,
    },

    xaxis: {
      automargin: true,
      title: {
        text: xTitle,
        font: {
          size: 16,
          color:
            theme === 'dark'
              ? ChartColors.axisTitle.dark
              : ChartColors.axisTitle.light,
          family: 'Suisse Medium, sans-serif',
        },
      },
      gridcolor:
        theme === 'dark' ? ChartColors.grid.dark : ChartColors.grid.light,
    },

    paper_bgcolor:
      theme === 'dark' ? ChartColors.paperBg.dark : ChartColors.paperBg.light,
    plot_bgcolor: 'transparent',
    modebar: {
      bgcolor: 'transparent',
      color:
        theme === 'dark' ? ChartColors.modeBar.dark : ChartColors.modeBar.light,
    },
    grid: {
      rows,
      columns: 1,
      subplots: ['xy', 'xy2'],
    },
    legend: {
      font: {
        size: 12,
        color:
          theme === 'dark' ? ChartColors.legend.dark : ChartColors.legend.light,
      },
      bgcolor: 'transparent',
      orientation: 'v',
      tracegroupgap: 1,
      yanchor: 'top',
      xanchor: 'left',
      x: type === 'bar' ? 1 : 0.75,
    },
  };
  if (hasSecondAxis) {
    layout.grid = {
      rows: 2,
      ygap: 0.25,
      columns: 1,
      subplots: ['xy', 'xy2'],
    };
    layout.yaxis2 = {
      title: {
        text: y2Title,
        font: {
          size: 14,
          color:
            theme === 'dark'
              ? ChartColors.axisTitle.dark
              : ChartColors.axisTitle.light,
        },
      },
      zerolinecolor:
        theme === 'dark'
          ? ChartColors.zeroLine.dark
          : ChartColors.zeroLine.light,
      zerolinewidth: 2,
      gridcolor:
        theme === 'dark' ? ChartColors.grid.dark : ChartColors.grid.light,
    };
  }
  return layout;
}

export function getConfig() {
  const config: Partial<Plotly.Config> = {
    displayModeBar: true,
    displaylogo: false,
    responsive: true,
    modeBarButtonsToRemove: [
      'pan3d',
      'lasso2d',
      'select2d',
      'zoomIn2d',
      'zoomOut2d',
      'pan2d',
      'zoom2d',
    ],
  };
  return config;
}
