const EMAIL = 'sm-email';
const SESSION_ID = 'sm-session';
// const HOST = "http://127.0.0.1:4000";
const HOST = 'https://q2ms0ur908.execute-api.ca-central-1.amazonaws.com/Prod/';
const SECURITY = '/security';

let user = null;

/**
 * Get the user for the account
 *
 * @returns user
 */
// get session cookie and email cookie from browser
// if sessionId and email are valid
//     return current user from database
// else
//   return null
export async function appGetUser() {
  try {
    if (user) return user;

    const sessionId = appGetSessionId();
    const email = appGetEmail();
    if (sessionId && email) {
      const resp = await mainFetch(SECURITY, { sessionId, email });
      user = resp.user;
      return user;
    }
    return null;
  } catch (err) {
    // eslint-disable-next-line no-undef
    console.log(err);
    return 'System error, ask support rep to check the console.';
  }
}

export async function appRequestSecurityCode(obj) {
  try {
    if (obj.userType === 'newUser') {
      if (!obj.fname) return 'First Name required';
      if (!obj.lname) return 'Last Name required';
      if (!validateEmail(obj.email)) return 'Not a valid email.';
      const resp = await mainFetch(SECURITY, obj);
      return resp.msg;
    }
    if (!validateEmail(obj.email)) return 'Not a valid email.';
    setCookie(EMAIL, obj.email, 365);
    const resp = await mainFetch(SECURITY, obj);
    return resp.msg;
  } catch (err) {
    // eslint-disable-next-line no-undef
    console.log(err);
    return 'System error, ask support rep to check the console.';
  }
}

export async function appConfirmSecurityCode(email, securityCode) {
  try {
    const resp = await mainFetch(SECURITY, { email, linkId: securityCode });
    if (resp.status === 0) {
      setCookie(SESSION_ID, resp.sessionId, 35);
    }
    return resp;
  } catch (err) {
    // eslint-disable-next-line no-undef
    console.log('*** We have a problem:', err);
    return String(err);
  }
}

export function appGetEmail() {
  return getCookie(EMAIL);
}

export function appGetSessionId() {
  return getCookie(SESSION_ID);
}

// todo@ larry and nathan - revoke session cookies on logoff
export async function deleteCookies() {
  // const resp = await mainFetch(SECURITY, {
  //     userType: "loggedOut",
  //     email,
  //     sessionId: "",
  //     linkId: "",
  // });
  // document.cookie = "sm-email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  // eslint-disable-next-line no-undef
  document.cookie =
    'sm-session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
}

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function isAdminAccount() {
  return true;
  // uncomment the following to enable admin access
  // const email = appGetEmail();
  // return email.includes('@highwoodemissions.com');
}

function getCookie(cname) {
  const name = `${cname}=`;
  // eslint-disable-next-line no-undef
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  // eslint-disable-next-line no-undef
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
}

const mainFetch = async (route, info) => {
  // eslint-disable-next-line no-undef
  const resDat = await fetch(HOST + route, {
    // credentials: 'include',
    method: 'POST',
    // headers: { 'Content-type': 'application/json' },
    body: JSON.stringify(info),
  });
  const jsonDat = await resDat.json();
  return jsonDat;
};
