import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import * as Icon from 'react-bootstrap-icons';
import { Col, Row, Button, Container } from 'react-bootstrap';
import { DataAPIContext } from '../../../contexts/data';
import { Site } from '../../../data/Site';
import { saveExcel } from '../../../data/io/saveData';
import ListFilter from '../../utilities/ListFilter';
import { IFormatLookUp } from '../../plots/chartTypes';
import PieChart from '../../plots/pie/PieChart';
import SiteTable from './SiteTable';
import BarGraphOld from '../../plots/bar/BarGraphOld';
import { SiteView } from '../../../dataAPI/Views';
import { SortFilterWidget } from '../../filters/projectFilter';
import SiteList from '../../lists/SiteList';
import { SortFilter } from '../../../utils/SortingFuncs';
import isDarkMode from '../../../utils/lightDarkMode';

export default function SiteDashboard() {
  const { dataClient, unpackResponse } = useContext(DataAPIContext);

  const [siteList, setSiteList] = useState<SiteView[]>([]);
  const [filterList, setFilterList] = useState<SiteView[]>([]);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [selectCount, setSelectCount] = useState<number | 'All'>('All');
  const [sortBy, setSortBy] = useState('equipmentCount');

  const [theme, setTheme] = React.useState(isDarkMode());

  const annualEmissionsLookup: IFormatLookUp = {
    xVal: 'key',
    yVal: 'yearlyEmissions',
  };
  const siteEquipmentLookup: IFormatLookUp = {
    xVal: 'key',
    yVal: 'equipmentCount',
  };

  useEffect(() => {
    getSites();
  }, []);

  useEffect(() => {
    setTheme(isDarkMode());
  });

  useEffect(() => {
    const sorted = SortFilter(siteList, sortBy, sortDirection, selectCount);
    setFilterList(sorted);
  }, [sortDirection, selectCount, sortBy, siteList]);

  const getSites = async () => {
    const sites = await unpackResponse(dataClient.getSites());
    setSiteList(sites);
  };

  const onHandleFilteredList = (filtered: Site[]) => {
    setFilterList(filtered);
  };

  const onSetSelectCount = (e: Event) => {
    const { value } = e.target as HTMLInputElement;

    setSelectCount(value as number | 'All');
  };

  return (
    <Container>
      <h2 className="mt-3 mb-3 p-3">All Sites</h2>
      <Row className="m-1">
        <Col className="col-6">
          <div className="mt-3 mb-3 r-border">
            <BarGraphOld
              type="site"
              typeData={filterList}
              title="Site Location vs. Annual Baseline Emissions Profile"
              xTitle="Site Location"
              yTitle="Annual Emissions (tCO2e)"
              axisValues={annualEmissionsLookup}
              extraHooverRows={[
                { key: 'key', value: 'Key' },
                { key: 'equipmentCount', value: 'Equipment Count' },
                { key: 'lat', value: 'Lat' },
                { key: 'long', value: 'Long' },
                { key: 'type', value: 'Type' },
                { key: 'yearlyEmissions', value: 'Yearly Emissions' },
              ]}
              theme={theme}
            />
          </div>
        </Col>
        <Col className="col-6">
          <div className="mt-3 mb-3 r-border">
            <BarGraphOld
              type="site"
              typeData={filterList}
              title="Site Location vs. Equipment Count"
              xTitle="Site Location"
              yTitle="Equipment Count"
              axisValues={siteEquipmentLookup}
              format="number"
              extraHooverRows={[
                { key: 'key', value: 'Key' },
                { key: 'equipmentCount', value: 'Equipment Count' },
                { key: 'lat', value: 'Lat' },
                { key: 'long', value: 'Long' },
                { key: 'type', value: 'Type' },
                { key: 'yearlyEmissions', value: 'Yearly Emissions' },
              ]}
              theme={theme}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="col-6">
          <div className="r-border">
            <PieChart
              typeData={filterList}
              title="Site Type vs Annual Emissions"
              format="number"
              theme={theme}
            />
          </div>
        </Col>
        <Col className="col-6">
          <div className="r-border s-bg">
            <SiteTable typeData={filterList} title="Site Type" />
          </div>
        </Col>
      </Row>
      <Row>
        <div className="r-border s-bg mt-3 p-3">
          <Col className="col-6">
            <Row>
              <SortFilterWidget
                cardID="sites"
                selected={selectCount}
                onChange={onSetSelectCount}
                sortDirection={sortDirection}
                handleSortDirection={setSortDirection}
                sortBy={sortBy}
                handleSortBy={setSortBy}
                sortOptions={[
                  {
                    key: 'dd-equipmentCount',
                    value: 'equipmentCount',
                    text: 'Equipment Count',
                  },
                  {
                    key: 'dd-yearlyEmissions',
                    value: 'yearlyEmissions',
                    text: 'Yearly Emissions',
                  },
                  { key: 'dd-type', value: 'type', text: 'Type' },
                ]}
              />
            </Row>
            <Row>
              <ListFilter
                list={siteList}
                setFilteredList={onHandleFilteredList}
                selectOptions={{
                  key: 'Key',
                  name: 'Name',
                  type: 'Type',
                }}
              />
            </Row>
          </Col>
          <Row>
            <Col className="col-12">
              <hr className="w-90 m-auto mt-3 mb-3" />
              <SiteList siteList={filterList} />

              <hr className="w-90 m-auto mt-3 mb-3" />
              <div className="d-flex flex-row-reverse p-3">
                <Button
                  type="button"
                  variant="primary"
                  size="sm"
                  className="btn btn-lg mx-1 btn_min--width save__btn"
                  onClick={() => {
                    const jsonData = { filterList };
                    saveExcel(jsonData, 'rp-site-view.xlsx');
                  }}
                >
                  Sites Save{' '}
                  <Icon.CloudArrowDownFill className="save__btn-icon" />
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Row>
    </Container>
  );
}
