import React from 'react';
import './loading-spinner.css';

class LoadingSpinner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
      spinner: null,
    };
  }

  componentDidMount() {
    this._runSpinner = true;
    if (this.props.spinnerText) {
      this.setState({ text: this.props.spinnerText });
    } else {
      this.setState({ text: 'Loading' });
    }
    this.incrementSpinner();
  }

  componentWillUnmount() {
    this._runSpinner = false;
  }

  incrementSpinner = () => {
    const { spinner } = this.state;
    let newSpinner;
    if (spinner === null) {
      newSpinner = '.';
    } else if (spinner === '.') {
      newSpinner = '..';
    } else if (spinner === '..') {
      newSpinner = '...';
    } else {
      newSpinner = null;
    }
    setTimeout(() => {
      if (this._runSpinner) {
        this.setState({ spinner: newSpinner }, () => {
          this.incrementSpinner();
        });
      }
    }, 500);
  };

  render() {
    return (
      <div className="spinner">
        {/*className={*/}
        {/*  this.props.className ? `spinner ${this.props.className}` : 'spinner'*/}
        {/*}*/}
        <p>
          {this.state.text}
          {this.state.spinner}
        </p>
      </div>
    );
  }
}

export default LoadingSpinner;
