import React, { useEffect, useState } from 'react';
import 'react-tabulator/lib/styles.css';
import 'react-tabulator/lib/css/tabulator.min.css';
import { ReactTabulator } from 'react-tabulator';

function SiteTypeTableList(props) {
  useEffect(() => {
    loadColumns();
  }, [props.siteTypeList]);

  useEffect(() => {
    loadColumns();
  }, [props.siteTypeEquipmentProfile]);

  const [columns, setColumns] = useState();

  const cellEdited = (cellComponent) => {
    const siteType = cellComponent._cell.row.data.key;
    const equipmentType = cellComponent._cell.column.field;
    const { value } = cellComponent._cell;
    props.updateSiteType(siteType, equipmentType, value);
  };

  const loadColumns = () => {
    const { siteTypeList, siteTypeEquipmentProfile } = props;
    if (siteTypeList && siteTypeEquipmentProfile) {
      const { siteTypeEquipmentProfile } = props;
      const result = [];
      result.push({
        title: 'order',
        field: 'order',
        hozAlign: 'center',
        sorter: 'number',
      });
      result.push({ title: 'key', field: 'key', visible: false });
      result.push({ title: 'name', field: 'name', width: 200 });
      result.push({
        title: 'count',
        field: 'count',
        width: 100,
        editor: props.editableCount ? 'input' : null,
        hozAlign: 'right',
        sorter: 'number',
      });

      if (siteTypeEquipmentProfile) {
        siteTypeEquipmentProfile.forEach((x) => {
          result.push({
            title: x,
            field: x,
            editor: props.editableColumns ? 'input' : null,
            hozAlign: 'right',
            headerSort: true,
            sorter: 'number',
          });
        });
      }

      setColumns(result);
    }
  };

  const { siteTypeList } = props;

  return (
    <div className="text-center">
      {columns && (
        <ReactTabulator
          placeholder="No Data Available"
          data={siteTypeList}
          initialSort={[{ column: 'order', dir: 'asc' }]}
          columns={columns}
          // tooltips
          // cellEdited={cellEdited}
          events={{ cellEdited }}
          layout="fitDataTable"
        />
      )}
    </div>
  );
}

export default SiteTypeTableList;
