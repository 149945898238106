// import React from "react";
import React, { useState } from 'react';
import { locationToLatLong, download } from '../../utils/locationToLatLong.js';

function DLSToLatLong() {
  const [textarea, setTextarea] = useState('');
  const handleChange = (event) => {
    setTextarea(event.target.value);
  };

  function onConvert() {
    if (textarea) {
      const locations = textarea.split('\n');
      const lines = locations.map((l) => {
        try {
          const [lat, long] = locationToLatLong(l);
          return [l, lat, long];
        } catch (e) {
          return [l, '', '', e.message];
        }
      });
      lines.unshift(['lsd', 'lat', 'long', 'Message']);
      download(lines);

      // display message?? todo: add message
    }
  }

  return (
    <>
      <h1>DLS Conversion to Lat Long</h1>
      <div>
        This is an approximation only. <br />
        Enter as many locations as you wish. Each one must be on a new line.{' '}
        <br />
        01-01-001-01W4
        <br />
        The result will be downloaded into a csv file.
      </div>
      <textarea value={textarea} onChange={handleChange} rows="10" />
      <button type="button" onClick={onConvert}>
        Convert
      </button>
    </>
  );
}

export default DLSToLatLong;
