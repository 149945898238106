import * as React from 'react';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Container, Button, Card, ListGroup, Row } from 'react-bootstrap';
// import {
//   deleteProjects,
//   generateProjects,
// } from "../../data/generate/generatePotentialProjects";

import { DataAPIContext } from '../../contexts/data';

function Calculations() {
  const { dataClient, unpackResponse } = useContext(DataAPIContext);

  const getProjectCount = async () => {
    const response = await unpackResponse(dataClient.getProjectCount());
    // eslint-disable-next-line no-console
    console.log(response);
  };

  const calcEmissionsCosts = async () => {
    await unpackResponse(dataClient.calcEmissionsCosts());
  };

  const generateProjects = async () => {
    await unpackResponse(dataClient.generateProjects());
  };

  const deleteProjects = async () => {
    await unpackResponse(dataClient.deleteProjects());
  };

  const printDataObj = async () => {
    // console.log(dataClient);
    await unpackResponse(dataClient.printDataObj());
  };

  const lfsInitData = async (parms: any) => {
    await unpackResponse(dataClient.lfsInitData(parms));
  };

  return (
    <Container>
      <h1 className="mb-3 mt-5">Calculations</h1>
      <br />
      <Row>
        <Card style={{ width: '35rem' }}>
          <ol>
            <li>
              <Button
                //                 className="link__btn"
                // create a temp api for calcEmissionsCosts
                onClick={() => {
                  calcEmissionsCosts();
                }}
              >
                Emission and Cost Calculations
              </Button>
            </li>
            <li>
              <Button className="link__btn" onClick={() => generateProjects()}>
                Generate Potential Projects
              </Button>
            </li>
            <li>
              <Button
                className="link__btn"
                onClick={() => {
                  deleteProjects();
                }}
              >
                Delete Potential Projects
              </Button>
            </li>
            <li>
              <Link to="/dlstolatlong/:filt?">
                <Button className="link__btn">DLS to lat long</Button>
              </Link>
            </li>
            <li>
              <Button className="link__btn" onClick={() => printDataObj()}>
                console.log(data)
              </Button>
            </li>
            <li>
              <Button className="link__btn" onClick={() => getProjectCount()}>
                Project Count
              </Button>
            </li>
            <li>
              <Button
                className="link__btn"
                onClick={async () => {
                  const ts = await unpackResponse(
                    dataClient.getPathwayTimeseries()
                  );
                  // eslint-disable-next-line no-console
                  console.log(ts);
                }}
              >
                {' '}
                Test getPathwayTimeseries API
              </Button>
            </li>
            <li>
              <Button
                className="link__btn"
                onClick={async () => {
                  const ts = await unpackResponse(
                    dataClient.getProjectTimeseries('cp-1-TkFlrDes')
                  );
                  // eslint-disable-next-line no-console
                  console.log(ts);
                }}
              >
                {' '}
                Test getProjectTimeseries API
              </Button>
            </li>
            <li>
              <Button
                className="link__btn"
                onClick={() => {
                  console.log('Play with Performance');
                }}
              >
                Play with performance
              </Button>
            </li>
          </ol>
        </Card>
        {/* ------------------- */}
        <Card style={{ width: '18rem' }}>
          <Card.Header>Optimize</Card.Header>
          <Button
            className="btn btn-lg btn_min--width save__btn mx-1"
            onClick={() => lfsInitData({ value: 'someValue3' })}
          >
            lfsInitData
          </Button>

          <ListGroup variant="flush">
            <ListGroup.Item>Cras justo odio</ListGroup.Item>
            <ListGroup.Item>Dapibus ac facilisis in</ListGroup.Item>
            <ListGroup.Item>Vestibulum at eros</ListGroup.Item>
            <Button className="btn btn-lg btn_min--width save__btn mx-1">
              Push Me
            </Button>
            <Button className="btn btn-lg btn_min--width save__btn mx-1">
              Push Me
            </Button>
            <Button className="btn btn-lg btn_min--width save__btn mx-1">
              Push Me
            </Button>
          </ListGroup>
        </Card>
        <Card style={{ width: '18rem' }}>
          <Card.Header>Featured</Card.Header>
          <ListGroup variant="flush">
            <Button
              className="btn btn-lg btn_min--width save__btn mx-1"
              onClick={async () => {
                console.log('Report Audit Douglas Look Here');
                const results = await unpackResponse(
                  dataClient.createAuditReport()
                );
                console.log(results);
              }}
            >
              Integration Test - WIP
            </Button>
            <ListGroup.Item>Cras justo odio</ListGroup.Item>
            <ListGroup.Item>Dapibus ac facilisis in</ListGroup.Item>
            <ListGroup.Item>Vestibulum at eros</ListGroup.Item>
            <Button
              className="btn btn-lg btn_min--width save__btn mx-1"
              onClick={() => {
                console.log('Big Array');
                const s = new Array(1024).join('x');
                console.log(s);
              }}
            >
              Big Array
            </Button>
            <Button
              className="btn btn-lg btn_min--width save__btn mx-1"
              onClick={() => {
                console.log('add bloat');
                unpackResponse(dataClient.lfsAddBloat());
              }}
            >
              add bloat
            </Button>
            <Button
              className="btn btn-lg btn_min--width save__btn mx-1"
              onClick={() => {
                console.log('remove bloat');
                unpackResponse(dataClient.lfsRemoveBloat());
              }}
            >
              remove bloat
            </Button>
            <Button className="btn btn-lg btn_min--width save__btn mx-1">
              Push Me
            </Button>
            <Button className="btn btn-lg btn_min--width save__btn mx-1">
              Push Me
            </Button>
            <Button className="btn btn-lg btn_min--width save__btn mx-1">
              Push Me
            </Button>
          </ListGroup>
        </Card>
      </Row>
    </Container>
  );
}

export default Calculations;
