import React, { useContext, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { customListStyles } from '../../utils/customStyles';
import { formatNumNavigator } from '../../utils/format';
import EquipmentList from './EquipmentList';
import { DataAPIContext } from '../../contexts/data';

function SiteList(props) {
  const { dataClient, unpackResponse } = useContext(DataAPIContext);

  const columns = [
    {
      name: 'Key',
      selector: (row) => row.key,
      sortable: props.sortable,
      left: true,
      reorder: true,
      grow: 2,
      selectedAttr: 'key',
      cell: (row) => <span>{row.key}</span>,
    },
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: props.sortable,
      left: true,
      reorder: true,
      grow: 5,
      selectedAttr: 'name',
      cell: (row) => <span>{row.name}</span>,
    },
    {
      name: '# of Equipment',
      selector: (row) => row.equipmentCount,
      sortable: props.sortable,
      center: true,
      reorder: true,
      cell: (row) => formatNumNavigator(row.equipmentCount),
      selectedAttr: 'equipmentCount',
    },
    {
      name: 'Yearly Emissions',
      selector: (row) => row.yearlyEmissions,
      format: (row) => Math.round(row.yearlyEmissions).toLocaleString(),
      // format: (row) => formatNumNavigator(row.yearlyEmissions),
      sortable: props.sortable,
      right: true,
      reorder: true,
      selectedAttr: 'yearlyEmissions',
    },
    {
      name: 'Type',
      selector: (row) => row.type,
      sortable: props.sortable,
      center: true,
      reorder: true,
      selectedAttr: 'type',
    },
    {
      name: 'Lat',
      selector: (row) => row.lat,
      sortable: props.sortable,
      center: true,
      reorder: true,
      selectedAttr: 'lat',
    },
    {
      name: 'Long',
      selector: (row) => row.long,
      sortable: props.sortable,
      center: true,
      reorder: true,
      selectedAttr: 'long',
    },
  ];

  let selected = false;
  let ScrollHeight = '65vh';

  if (props.selected) {
    selected = true;
  }

  if (props.ScrollHeight) {
    ScrollHeight = props.ScrollHeight;
  }

  function ExpandableComponent(row) {
    const [equipmentList, setEquipmentList] = useState([]);

    useEffect(() => {
      getEquipmentBySite();
    }, []);

    const getEquipmentBySite = async () => {
      const equipmentList = await unpackResponse(
        dataClient.getEquipmentBySite(row.data.key)
      );
      setEquipmentList(equipmentList);
    };

    return (
      <EquipmentList
        equipmentList={equipmentList}
        onEquipmentClicked={props.onEquipmentClicked}
        sortable
      />
    );
  }

  return (
    <DataTable
      columns={columns}
      data={props.siteList}
      pagination
      paginationPerPage={25}
      paginationRowsPerPageOptions={[25, 100, 1000]}
      noHeader
      fixedHeader
      fixedHeaderScrollHeight={ScrollHeight}
      highlightOnHover
      selectableRows={selected}
      onSelectedRowsChange={props.onSelectedRowsChange}
      clearSelectedRows={props.toggledClearRows}
      dense
      expandableRows
      expandableRowsComponent={ExpandableComponent}
      customStyles={customListStyles}
    />
  );
}

export default SiteList;
