import React, { useState, useEffect, useContext } from 'react';

import { Container, Col, Row } from 'react-bootstrap';
import { GetGroups } from '../../../utils/GroupProjectsFuncs';
import GroupedCard from './cards/groupedCard';
import ProjectCard from './cards/projectCard';
import ActiveProjectCard from './cards/activeProjectCard';
import ScenarioMenu from './scenarioMenu';
import { ProjectState } from '../../../data/ProjectState.js';
import { DataAPIContext } from '../../../contexts/data';
import ScenarioEditModal from '../../popups/ScenarioEditModal';
import ScenarioGenerator from './ScenarioGenerator';
import { appPubSub, Topic } from '../../../utils/AppPubSub';
import OffsetCard from './OffsetCard.jsx';
import VScoreBoard from '../../scoreboard/VScoreboard';

let colorMap = {};
let isNewScenario = false;
let isDelete = false;

function ScenarioPlanner() {
  //  Define Project State Enums
  const POTENTIAL = ProjectState.Potential;
  //  Define Global Context
  const { dataClient, unpackResponse, addNewMessage } =
    useContext(DataAPIContext);

  // --- States --- //
  const [committedProjects, setCommittedProjects] = useState([]);
  const [allProjects, setAllProjects] = useState([]);
  const [allPotentialProjects, setAllPotentialProjects] = useState([]);
  const [potentialProjects, setPotentialProjects] = useState([]);
  const [potentialGroupedProjects, setPotentialGroupedProjects] = useState([]);
  // const [groupByType, SetGroupByType]  = useState(initGroupBy);
  // const [completedProjects, setCompletedProjects]  = useState([]);
  const [projectTypes, setProjectTypes] = useState([]);
  const [scenarios, setScenarios] = useState([]);
  const [currentScenario, setCurrentScenario] = useState({
    name: '',
    key: undefined,
  });
  const [scenarioTimeseries, setScenarioTimeseries] = useState();
  const [scenarioStats, setScenarioStats] = useState({
    lifetimeBaselineEmissions: 0,
    emissionReduction: 0,
    netCost: 0,
    capCost: 0,
    carbonTaxSavings: 0,
    projectCount: 0,
    abatementCost: 0,
  });
  const [showScenarioModal, setShowScenarioModal] = useState(false);
  const [mode, setMode] = useState('Scenario');
  const [baseYear, setBaseYear] = useState(0);

  //  On Component Mount
  useEffect(() => {
    const InitCurrentScenario = async () => {
      const pathwayValues = await unpackResponse(dataClient.getPathwayValues());
      setBaseYear(pathwayValues.baseYear);
      const rScenarios = await unpackResponse(dataClient.getScenarios());
      setScenarios(rScenarios);
      const scenario = getCurrentScenario(
        rScenarios,
        pathwayValues.currentScenario
      );
      setCurrentScenario(scenario);
      appPubSub.publish(Topic.Header, { scenarioName: scenario.name });

      refreshData(pathwayValues.currentScenario);
    };

    InitCurrentScenario();
  }, []);

  const getCurrentScenario = (scenarioList, currentScenarioKey) => {
    const scenariokey = parseInt(currentScenarioKey);
    return scenarioList.filter((m) => m.key === scenariokey)[0];
  };

  const onProjectGroupClick = (clickedProjectGroup = null) => {
    if (clickedProjectGroup !== null) {
      setPotentialProjects(clickedProjectGroup.projectList);
    } else {
      setPotentialProjects(allPotentialProjects);
    }
  };

  // const onProjectClick = (selectedKey) => {
  //   // eslint-disable-next-line no-undef
  //   // Should Flash on Corresponding Images
  //   console.log('onProjectClick', selectedKey);
  // };

  const onProjectEdit = async (selectedProjects, propsToSet = null) => {
    const updatedProjects = selectedProjects.map((p) => ({
      key: p.key,
      ...propsToSet,
    }));
    await unpackResponse(dataClient.updateProjects(updatedProjects));
    refreshData(currentScenario.key);
  };

  const onAddScenario = () => {
    isNewScenario = true;
    setShowScenarioModal(true);
  };

  const onEditScenario = () => {
    isNewScenario = false;
    setShowScenarioModal(true);
  };

  const onDeleteScenario = () => {
    if (scenarios.length > 1) {
      isDelete = true;
      setShowScenarioModal(true);
    } else {
      addNewMessage('At least one scenario is required', 'error');
    }
  };

  const onConfirmEditScenario = async (r) => {
    let scenarioKey = 0;
    if (isDelete) {
      const _nextScenario = scenarios
        .filter((s) => s.key != currentScenario.key)
        .pop();
      await unpackResponse(dataClient.deleteScenario(currentScenario.key));
      await unpackResponse(dataClient.selectScenario(_nextScenario.key));
      scenarioKey = _nextScenario.key;
    } else if (isNewScenario) {
      const scenario = await unpackResponse(dataClient.addScenario(r));
      scenarioKey = scenario.key;
    } else {
      await unpackResponse(dataClient.editScenario(r));
      scenarioKey = currentScenario.key;
    }
    const rScenarios = await unpackResponse(dataClient.getScenarios());
    setScenarios(rScenarios);

    const scenario = getCurrentScenario(rScenarios, scenarioKey);
    setCurrentScenario(scenario);
    appPubSub.publish(Topic.Header, { scenarioName: scenario.name });

    await refreshData(scenarioKey);

    isNewScenario = false;
    isDelete = false;
    setShowScenarioModal(false);
  };

  const onDeclineEditScenario = () => {
    isNewScenario = false;
    setShowScenarioModal(false);
  };

  const onSelectScenario = async (e) => {
    if (currentScenario.key !== e.target.value) {
      const scenarioKey = await unpackResponse(
        dataClient.selectScenario(e.target.value)
      );
      const scenario = getCurrentScenario(scenarios, scenarioKey);
      setCurrentScenario(scenario);
      appPubSub.publish(Topic.Header, { scenarioName: scenario.name });
      refreshData(scenarioKey);
    }
  };

  const refreshData = async (scenarioKey) => {
    const { allProjects, potential, committed, grouped } = await loadProjects();
    const rScenarioStats = await unpackResponse(dataClient.getScenarioStats());
    colorMap = grouped.reduce(
      (obj, item) => ((obj[item.key] = item.color), obj),
      {}
    );
    const rScenarioTimeseries = await unpackResponse(
      dataClient.getScenarioTimeseries(scenarioKey)
    );
    setScenarioStats(rScenarioStats);
    setAllProjects(allProjects);
    setPotentialProjects(potential);
    setPotentialGroupedProjects(grouped);
    setCommittedProjects(committed);
    setScenarioTimeseries(rScenarioTimeseries);
    setAllPotentialProjects(potential);
  };

  const loadProjects = async () => {
    const projectTypes = await unpackResponse(dataClient.getProjectTypes());
    const allProjects = await unpackResponse(dataClient.getProjects());
    const potential = allProjects
      .filter((p) => p.status === POTENTIAL)
      .filter((p) => p.lifetimeReduction > 0);
    const committed = allProjects.filter((p) => p.status !== POTENTIAL);
    const grouped = GetGroups(potential, projectTypes, 'projectType', [
      'yearlyAbatementCost',
      'capCost',
      'lifetimeReduction',
    ]);
    setProjectTypes(projectTypes);
    return {
      allProjects,
      potential,
      committed,
      grouped,
    };
  };
  // const onOffsetStartYearChange = (date) => {
  //   const scenarioList = [...scenarios];
  //   scenarioList.find((s) => s.key === currentScenario.key).offsetStartYear =
  //     date;
  //   setScenarios(scenarioList);
  // };
  // const onOffsetReductionPercentChange = (percent) => {
  //   const scenarioList = [...scenarios];
  //   scenarioList.find(
  //     (s) => s.key === currentScenario.key
  //   ).lifetimeReductionPercentage = percent;
  //   setScenarios(scenarioList);
  // };

  return (
    <Container className="mt-3">
      {showScenarioModal && (
        <ScenarioEditModal
          show={showScenarioModal}
          onConfirm={onConfirmEditScenario}
          onDecline={onDeclineEditScenario}
          inputs={{
            name: isNewScenario ? '' : currentScenario.name,
            offsetStartYear: currentScenario.offsetStartYear
              ? currentScenario.offsetStartYear
              : baseYear,
            offsetReductionPercent: currentScenario.offsetReductionPercent
              ? currentScenario.offsetReductionPercent
              : 100,
          }}
          isDelete={isDelete}
          isNewScenario={isNewScenario}
        />
      )}
      <Row>
        <Col className="col-12 mt-3 mb-3">
          <div className="r-border s-bg p-1">
            <ScenarioMenu
              scenarios={scenarios}
              mode={mode}
              onNew={onAddScenario}
              currentScenario={currentScenario.key}
              onDelete={onDeleteScenario}
              onSelect={onSelectScenario}
              onEdit={onEditScenario}
              onSetMode={setMode}
              // offsetStartYear={currentScenario.offsetStartYear}
              // lifetimeReductionPercentage={
              //   currentScenario.lifetimeReductionPercentage
              // }
              // onOffsetStartYearChange={onOffsetStartYearChange}
              // onOffsetReductionPercentChange={onOffsetReductionPercentChange}
            />
            <OffsetCard currentScenario={currentScenario} />
          </div>
        </Col>
      </Row>
      {mode === 'Generate' && (
        <Row>
          <Col className="col-12 p-1">
            <div className="border p-1 shadow">
              <ScenarioGenerator
                currentScenario={currentScenario.key}
                projects={allProjects}
                projectTypes={projectTypes}
                refreshData={refreshData}
              />
            </div>
          </Col>
        </Row>
      )}
      {mode === 'Scenario' && (
        <>
          <Row className="mt-3 mb-3">
            <Col className="col-12 p-1">
              <div className="s-bg r-border p-4">
                <ActiveProjectCard
                  committedProjects={committedProjects}
                  onProjectEdit={onProjectEdit}
                  colorMap={colorMap}
                  scenarioTimeseries={scenarioTimeseries}
                  scenarioName={currentScenario.name}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <VScoreBoard scenarioStats={scenarioStats} />
          </Row>
          <Row className="mt-3">
            <Col className="col-6">
              <div className="s-bg h-100 r-border p-4">
                <ProjectCard
                  currentScenario={currentScenario.key}
                  cardID="projects"
                  key="projects"
                  projects={potentialProjects} // name
                  label="Potential Projects"
                  // onClick={onProjectClick}
                  onProjectEdit={onProjectEdit}
                  selectOneOnly={false}
                  colorMap={colorMap}
                />
              </div>
            </Col>
            <Col className="col-6 p-1">
              <div className="s-bg h-100 r-border p-4">
                <GroupedCard
                  cardID="Project Type"
                  key="Project Type"
                  projects={potentialGroupedProjects}
                  label="Potential Project Types (Grouped Projects)"
                  handleClick={onProjectGroupClick}
                  onProjectEdit={onProjectEdit}
                  selectOneOnly
                />
              </div>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
}
export default ScenarioPlanner;
