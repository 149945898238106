import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import * as Icon from 'react-bootstrap-icons';
import { Col, Row, Button, Container } from 'react-bootstrap';
import { DataAPIContext } from '../../../contexts/data';
import { saveExcel } from '../../../data/io/saveData';
import ListFilter from '../../utilities/ListFilter';
import { EquipmentTypeView, EquipmentView } from '../../../dataAPI/Views';
import EquipmentList from '../../lists/EquipmentList';
import EquipmentTable from './EquipmentTable';
import { SortFilterWidget } from '../../filters/projectFilter';
import { SortFilter } from '../../../utils/SortingFuncs';
import EquipmentEmissionsVsType from './EquipmentEmissionsVsType';
import EquipmentNumberVsType from './EquipmentNumberVsType';
import EquipmentEmissionsVsActivityType from './EquipmentEmissionsVsActivityType';
import EquipmentEmissionsVsLocation from './EquipmentEmissionsVsSite';

export default function EquipmentDashboard() {
  const { dataClient, unpackResponse } = useContext(DataAPIContext);

  const [equipmentList, setEquipmentList] = useState<EquipmentView[]>([]);
  const [filterList, setFilterList] = useState<EquipmentView[]>([]);
  const [equipmentTypes, setEquipmentTypeList] = useState<EquipmentTypeView[]>(
    []
  );
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [selectCount, setSelectCount] = useState<number | 'All'>('All');
  const [sortBy, setSortBy] = useState<string>('key');

  useEffect(() => {
    getEquipment();
    getEquipmentTypeList();
    setFilterList(equipmentList);
  }, []);

  useEffect(() => {
    const sorted = SortFilter(
      equipmentList,
      sortBy,
      sortDirection,
      selectCount
    );
    setFilterList(sorted);
  }, [sortDirection, selectCount, sortBy, equipmentList]);

  const getEquipment = async () => {
    const equipment = await unpackResponse(dataClient.getEquipment());
    setEquipmentList(equipment);
  };
  const getEquipmentTypeList = async () => {
    const equipment = await unpackResponse(dataClient.getEquipmentTypes());
    setEquipmentTypeList(equipment);
  };
  const onFilteredList = (filtered: EquipmentView[]) => {
    setFilterList(filtered);
  };

  const onSortDirection = (direction: 'asc' | 'desc') => {
    setSortDirection(direction);
  };

  const onSetSelectCount = (e: Event) => {
    const { value } = e.target as HTMLInputElement;
    setSelectCount(value as number | 'All');
  };

  return (
    <Container>
      <h2 className="ps-3 mt-5  mb-3">All Equipment</h2>

      <Row>
        <Col className="col-6">
          <EquipmentEmissionsVsLocation equipment={filterList} />
        </Col>
        <Col className="col-6">
          <EquipmentEmissionsVsType
            equipment={filterList}
            equipmentTypes={equipmentTypes}
          />
        </Col>
      </Row>

      <Row>
        <Col className="col-6">
          <EquipmentNumberVsType equipment={filterList} />
        </Col>
        <Col className="col-6">
          <EquipmentEmissionsVsActivityType equipment={filterList} />
        </Col>
      </Row>

      <Row>
        <Col className="col-6">
          <EquipmentTable
            typeData={filterList}
            valueType="equipment"
            title="Equipment Type"
          />
        </Col>
        <Col className="col-6">
          <EquipmentTable
            typeData={filterList}
            valueType="activityType"
            title="Activity Type"
          />
        </Col>
      </Row>

      <Row>
        <Col className="col-4">
          <SortFilterWidget
            cardID="equipment"
            selected={selectCount}
            onChange={onSetSelectCount}
            sortDirection={sortDirection}
            handleSortDirection={onSortDirection}
            sortBy={sortBy}
            handleSortBy={setSortBy}
            sortOptions={[
              { key: 'dd-key', value: 'key', text: 'Key' },
              { key: 'dd-name', value: 'name', text: 'Name' },
              { key: 'dd-site', value: 'site', text: 'Site' },
              { key: 'dd-type', value: 'type', text: 'Type' },
              {
                key: 'dd-operational',
                value: 'operational',
                text: 'Operational',
              },
              {
                key: 'dd-OperatingHours',
                value: 'operatingHours',
                text: 'operating Hours',
              },
              {
                key: 'dd-modeOfOperation',
                value: 'modeOfOperation',
                text: 'Mode Of Operation',
              },
              {
                key: 'dd-activityType',
                value: 'activityType',
                text: 'Activity Type',
              },
              {
                key: 'dd-yearlyEmissions',
                value: 'yearlyEmissions',
                text: 'Yearly Emissions',
              },
              {
                key: 'dd-isCombustion',
                value: 'isCombustion',
                text: 'Combustion',
              },
            ]}
          />
        </Col>
        <Col className="col-6">
          <ListFilter
            list={equipmentList}
            setFilteredList={onFilteredList}
            selectOptions={{
              key: 'Key',
              name: 'Name',
              type: 'Type',
            }}
          />
        </Col>
      </Row>
      <EquipmentList equipmentList={filterList} />
      <Row>
        <Col className="col-12">
          <div className="d-flex bd-highlight flex-row-reverse p-3">
            <Button
              type="button"
              className="btn btn-lg btn_min--width save__btn mx-1"
              onClick={() => {
                const jsonData = { filterList };
                saveExcel(jsonData, 'rp-equipment-view.xlsx');
              }}
            >
              Equipment Save{' '}
              <Icon.CloudArrowDownFill className="save__btn-icon" />
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
