// To re-authenticate, request a security code to be sent to your email.
import React, { useState, useEffect } from 'react';
import {
  appRequestSecurityCode,
  appConfirmSecurityCode,
  appGetEmail,
} from './security';
import NewUser from './NewUser';
import RequestCode from './RequestCode';
import ValidateCode from './VaildateCode';

function Login(props) {
  const [userType, setUserType] = useState('existingUser');
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [email, setEmail] = useState('');
  const [securityCode, setSecurityCode] = useState('');
  const [msg, setMsg] = useState('');
  const [waitingForSecurityCode, setWaitingForSecurityCode] = useState(false);

  // One time process
  useEffect(() => {
    // Get email from local storage (Cookie)
    getUserInfo();
  }, []);

  async function getUserInfo() {
    const email = await appGetEmail();
    if (email) {
      setEmail(email);
      setUserType('existingUser');
    }
  }

  async function requestSecurityCode() {
    setMsg(null);
    setWaitingForSecurityCode(false);
    // validate input first
    const check = await appRequestSecurityCode({
      userType,
      fname,
      lname,
      email,
    });

    // set error message
    setMsg(check);
    // If no errors setup the security code input
    if (!check) {
      setWaitingForSecurityCode(true);
    } else {
      setWaitingForSecurityCode(false);
    }
  }

  async function newUserRequestSecurityCode() {
    await requestSecurityCode();
  }

  async function existingUserRequestSecurityCode() {
    await requestSecurityCode();
  }

  async function validateSecurityCodeClickHandler() {
    setMsg(null);
    const resp = await appConfirmSecurityCode(email, securityCode);
    if (resp.status === 0) {
      onCancelClick(false);
      props.setUser(resp.user);
    } else {
      setWaitingForSecurityCode(false);
      setMsg(resp.msg);
    }
  }

  function onCancelClick() {
    setUserType('existingUser');
    setMsg(null);
    setWaitingForSecurityCode(false);
  }

  function newUserClickHandler(event) {
    setUserType('newUser');
    const userType = event.target.id;
    setUserType(userType);
  }

  const onChangeHandler = (event) => {
    if (event.target.name === 'firstName') {
      setFname(event.target.value);
    }
    if (event.target.name === 'lastName') {
      setLname(event.target.value);
    }
    if (event.target.name === 'email') {
      setEmail(event.target.value);
    }
    if (event.target.name === 'securityCode') {
      setSecurityCode(event.target.value);
    }
  };

  return props.isLoading ? null : (
    <div className="container login">
      {msg ? <div className="clMsg">{msg}</div> : null}
      {waitingForSecurityCode ? (
        <div className="clInfoMsg">Check your email for the Security Code!</div>
      ) : null}

      {userType === 'newUser' ? (
        <NewUser
          firstName={fname}
          lastName={lname}
          email={email}
          onChangeHandler={onChangeHandler}
          onRequestSecurityCodeClick={newUserRequestSecurityCode}
          onCancelClick={onCancelClick}
        />
      ) : null}

      {userType === 'existingUser' && !waitingForSecurityCode ? (
        <RequestCode
          email={email}
          onEmailChange={onChangeHandler}
          onRequestSecurityCodeClick={existingUserRequestSecurityCode}
          newUserClick={newUserClickHandler}
          onCancelClick={onCancelClick}
        />
      ) : null}

      {waitingForSecurityCode ? (
        <ValidateCode
          email={email}
          securityCode={securityCode}
          onChange={onChangeHandler}
          validateSecurityCodeClick={validateSecurityCodeClickHandler}
          onCancelClick={onCancelClick}
        />
      ) : null}
    </div>
  );
}

export default Login;
