import React, { useContext, useEffect, useRef } from 'react';
import { LatLngExpression } from 'leaflet';
import { Button, Col, Row, Form } from 'react-bootstrap';
import { MapContainer, TileLayer, FeatureGroup } from 'react-leaflet';
import * as Icon from 'react-bootstrap-icons';
import { SiteView } from '../../../dataAPI/Views';
import SiteMarker from './siteMarker';
import { DataAPIContext } from '../../../contexts/data';

export interface IProps {
  sites: SiteView[];
  handleShowClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}
export default function SiteMap({ sites, handleShowClick }: IProps) {
  const { addNewMessage } = useContext(DataAPIContext);
  const [size, setSize] = React.useState(50);
  const zoom = 12;
  const position: LatLngExpression = [55, -115];
  const mapRef = useRef<L.Map>(null);
  const groupRef = useRef<L.FeatureGroup>(null);
  useEffect(() => {
    try {
      focusMap();
    } catch (e) {
      addNewMessage('Could not focus map, probably no lat longs.', 'info');
      // console.log('Could not focus map, probably no lat longs...', e);
    }
  }, [sites]);
  const focusMap = () => {
    if (mapRef.current && groupRef.current) {
      mapRef.current.fitBounds(groupRef.current.getBounds());
    }
    return null;
  };
  const siteEmissions = sites.map((s) => s.yearlyEmissions as number);
  const SOURCEMAX = Math.max(...siteEmissions);
  const SOURCEMIN = Math.min(...siteEmissions);
  const handleOnRadiusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSize(parseInt(value, 10));
  };
  return (
    <div className="site-map">
      <div className="btn__container-map">
        <div className="btn__container">
          <Button
            className="map__toggle-btn"
            id="FocusMap"
            variant="secondary"
            onClick={focusMap}
          >
            <Icon.Search className="map-icon" />
          </Button>
          <Button
            className="map__toggle-btn"
            id="FullscreenMap"
            variant="secondary"
            onClick={handleShowClick}
          >
            <Icon.Fullscreen className="map-icon" />
          </Button>
        </div>
        <div className="map__range__container">
          <Form.Range
            max={1000}
            min={1}
            value={size}
            onChange={handleOnRadiusChange}
          />
        </div>
      </div>
      <Col>
        <Row>
          <MapContainer
            className="map__container"
            center={position}
            ref={mapRef}
            zoom={zoom}
            scrollWheelZoom
            zoomSnap={0.1} // zoom sensitivity
            zoomDelta={0.1} // zoom sensitivity
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <FeatureGroup ref={groupRef}>
              {sites.map((s) => (
                <SiteMarker
                  site={s}
                  key={s.key}
                  sourceRange={[SOURCEMIN, SOURCEMAX]}
                  size={size}
                />
              ))}
            </FeatureGroup>
          </MapContainer>
        </Row>
      </Col>
    </div>
  );
}

SiteMap.defaultProps = {
  handleShowClick: null,
};
