import React, { createContext, useState, useEffect } from 'react';

import { toast } from 'react-toastify';
import LoadingSpinner from '../components/utilities/LoadingSpinner';
import { appPubSub, Topic } from '../utils/AppPubSub';

export const DataAPIContext = createContext();
let msgs = [];
export function DataAPIContextProvider(props) {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [dataClient, setDataClient] = useState(null);

  //  Run Use effect on Props because it takes some time to startup
  useEffect(() => {
    loadDefaultData();
  }, [props.dataClient]);

  const loadDefaultData = async () => {
    if (props.dataClient !== null && dataClient === null) {
      await unpackResponse(props.dataClient.loadDefaultData());
      const pathwayValues = await unpackResponse(
        props.dataClient.getPathwayValues()
      );
      // setHeaderName(pathwayValues.name);
      appPubSub.publish(Topic.Header, { name: pathwayValues.name });

      // console.log(props.dataClient, pathwayValues);
      setDataClient(props.dataClient);
      setHasLoaded(true);
    }
  };

  const addNewMessage = (message, messageType) => {
    toast[messageType](message, { position: toast.POSITION.BOTTOM_RIGHT });
    const datetime = new Date();
    const msgStructure = { datetime, message, type: messageType };
    msgs.push(msgStructure);
  };

  const unpackResponse = async (response) => {
    let data = null;
    try {
      const payload = await response;
      if (payload.data !== undefined) {
        data = payload.data;
      }
      if (payload.messages !== undefined) {
        payload.messages.forEach((message) => {
          addNewMessage(message.message, message.type);
        });
      }
    } catch (e) {
      addNewMessage(`System error call a developer! ${e.message}`, 'error');
      console.error(e);
      throw e;
    }
    return data;
  };

  const getMessages = () => msgs;
  const deleteMessages = () => (msgs = []);

  return (
    <DataAPIContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        dataClient,
        getMessages,
        deleteMessages,
        unpackResponse,
        addNewMessage,
      }}
    >
      {hasLoaded ? props.children : <LoadingSpinner />}
    </DataAPIContext.Provider>
  );
}
