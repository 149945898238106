import React, { useState } from 'react';
import { Button, ButtonGroup, Dropdown } from 'react-bootstrap';
import Plotly from 'plotly.js-dist-min';
import createPlotlyComponent from 'react-plotly.js/factory';
import * as Icon from 'react-bootstrap-icons';
import { getLayout, getConfig } from './Layouts';
import GetMACCData from './Macc';
import GetBubbleData from './Bubble';
import isDarkMode from '../../utils/lightDarkMode';

// Required for minimal package of Plotly components
const Plot = createPlotlyComponent(Plotly);
export default function ProjectMacc(props) {
  const [useMacc, setUseMacc] = useState(true);
  const [theme, setTheme] = React.useState(isDarkMode());
  const [groupBy, setGroupBy] = useState('project');

  const [plotData, setPlotData] = useState(
    createPlotData(props.projects, useMacc)
  );

  React.useEffect(() => {
    setTheme(isDarkMode());
  });

  React.useEffect(() => {
    setPlotData(createPlotData(props.projects, useMacc).sort());
  }, [props.projects, useMacc, groupBy]);

  const groupByOptionsObj = {
    project: 'Project',
    projectType: 'Project Type',
    cost: 'Cost',
    yearlyAbatementCost: 'Yearly Abatement Cost',
    lifetimeReduction: 'Lifetime Reduction',
  };

  // GroupBy can be projectType, name, lifetimeReduction, yearlyAbatementCost,
  // cost,
  function groupProjectsByParameter(projects, groupBy) {
    if (groupBy === 'project') {
      return projects.map((p) => ({
        name: p.key,
        description: p.name,
        x: p.lifetimeReduction,
        y: p.yearlyAbatementCost,
        cost: p.capCost,
        color: props.colorMap[p.projectType],
      }));
    }

    const groupedProjects = {};
    const groupedProjectsAverages = {};
    projects.forEach((project) => {
      const {
        projectType,
        name,
        lifetimeReduction,
        yearlyAbatementCost,
        capCost,
        [groupBy]: groupByKey,
      } = project;

      if (!groupedProjects[groupByKey]) {
        groupedProjects[groupByKey] = {
          type: 'bar',
          name: projectType,
          description: name,
          x: lifetimeReduction,
          y: yearlyAbatementCost,
          cost: capCost,
          color: props.colorMap[projectType],
          lifetimeReduction,
          yearlyAbatementCost,
          capCost,
          count: 1,
        };
      } else {
        groupedProjects[groupByKey] = {
          ...groupedProjects[groupByKey],
          x: groupedProjects[groupByKey].x + lifetimeReduction,
          y: groupedProjects[groupByKey].y + yearlyAbatementCost,
          cost: groupedProjects[groupByKey].cost + capCost,
          lifetimeReduction:
            groupedProjects[groupByKey].lifetimeReduction + lifetimeReduction,
          yearlyAbatementCost:
            groupedProjects[groupByKey].yearlyAbatementCost +
            yearlyAbatementCost,
          capCost: groupedProjects[groupByKey].capCost + capCost,
          count: groupedProjects[groupByKey].count + 1,
        };
      }

      groupedProjectsAverages[groupByKey] = {
        type: 'bar',
        name: projectType,
        description: name,
        x: groupedProjects[groupByKey].x,
        y:
          groupedProjects[groupByKey].y /
          groupedProjects[groupByKey].lifetimeReduction,
        cost: groupedProjects[groupByKey].cost,
        color: props.colorMap[projectType],
      };
    });

    return Object.values(groupedProjectsAverages);
  }

  function createPlotData(projects, isMacc) {
    const plotData = groupProjectsByParameter(projects, groupBy);
    // Sort the plotData array based on yearlyAbatementCost (y-axis)
    plotData.sort((a, b) => a.y - b.y);
    return isMacc
      ? GetMACCData([...plotData], null)
      : GetBubbleData([...plotData]);
  }

  const maccLayout = getLayout(
    props.title,
    'Total Emissions Reduced (tCO\u2082e)',
    'First Year Abatement Cost  ($/tCO\u2082e)',
    false,
    false,
    '',
    1,
    theme
  );
  const bubbleLayout = getLayout(
    props.title,
    'Capital Cost ($)',
    'Lifetime Abatement Cost ($/tCO\u2082e)',
    false,
    false,
    '',
    1,
    theme
  );

  const handleGroupSelect = (e) => {
    setGroupBy(e);
  };

  return (
    <div className="chart__container r-border">
      <div className="btn__container">
        <ButtonGroup>
          <Dropdown onSelect={handleGroupSelect}>
            <Dropdown.Toggle
              className="chart__toggle-btn btn btn-primary"
              id="dropdown-basic"
            >
              {groupBy === 'project' ? 'Project' : groupByOptionsObj[groupBy]}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item eventKey="project">Project</Dropdown.Item>
              <Dropdown.Item eventKey="projectType">Project Type</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Button
            className="chart__toggle-btn btn-primary"
            onClick={() => setUseMacc(!useMacc)}
          >
            {useMacc ? 'Show as Bubble' : 'Show as MACC'}
          </Button>
          <Button
            className="chart__toggle-btn btn-primary"
            onClick={props.handleShowClick}
          >
            <Icon.Fullscreen className="map-icon" />
          </Button>
        </ButtonGroup>
      </div>
      {plotData ? (
        <Plot
          data={plotData}
          layout={useMacc ? maccLayout : bubbleLayout}
          useResizeHandler
          onClick={(e) => props.onClick(e.points[0].data.name)}
          config={getConfig()}
        />
      ) : (
        <p>No Committed Projects</p>
      )}
    </div>
  );
}
