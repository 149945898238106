import React, { useContext, useEffect, useState } from 'react';
import { Col, Row, Button, Container } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import ListFilter from '../utilities/ListFilter';
import { saveExcel } from '../../data/io/saveData';

import { DataAPIContext } from '../../contexts/data';

function ProjectDetail() {
  const { dataClient, unpackResponse } = useContext(DataAPIContext);
  const [response, setResponse] = useState();
  const [filteredList, setFilteredList] = useState([]);

  useEffect(() => {
    getResponse();
  }, []);

  const getResponse = async () => {
    // eslint-disable-next-line no-undef
    const [scenario, periods] = await Promise.all([
      unpackResponse(dataClient.getCurrentScenario()),
      unpackResponse(dataClient.getScenarioPeriodDetail()),
    ]);
    setFilteredList(periods);
    setResponse({ scenario, periods });
  };

  const colDef = (name) => {
    const head = name.charAt(0).toUpperCase() + name.slice(1);
    return {
      name: `${head}`,
      selector: (row) => row[name],
      sortable: true,
    };
  };

  const colDefNum = (name) => {
    const col = colDef(name);
    return {
      ...col,
      cell: (row) => Math.round(row[name]).toLocaleString(),
      right: true,
    };
  };

  const columns = [
    {
      ...colDef('project'),
      cell: (row) => (
        <Link
          className="clLink"
          to={`/projectdetail/${encodeURIComponent(row.project)}`}
        >
          {row.project}
        </Link>
      ),
    },
    colDef('year'),
    colDefNum('carbonTaxSavings'),
    colDefNum('emissionsReduction'),
    colDefNum('capEx'),
    colDefNum('opEx'),
    colDefNum('grantFunding'),
  ];

  return (
    <Container>
      {response && (
        <Col className="col-md-8 offset-md-2">
          <h2 className="mt-5 mb-3">Period Detail</h2>
          <div className="p-3 mt-5 mb-3 r-border s-bg">
            <h4>{`${response.scenario.key} - ${response.scenario.name}`}</h4>
            <div>
              <Row>
                <ListFilter
                  list={response.periods}
                  setFilteredList={(list) => setFilteredList(list)}
                  selectOptions={{
                    project: 'Project',
                  }}
                />
                <hr className="w-90 m-auto mt-3 mb-3" />
              </Row>
              <Row className="mt-3 mb-3">
                <Col>
                  <DataTable
                    columns={columns}
                    data={filteredList}
                    pagination
                    paginationPerPage={15}
                    paginationRowsPerPageOptions={[15, 500, 1000]}
                    dense
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <hr className="w-90 m-auto mt-3 mb-3" />
                  <div className="d-flex flex-row-reverse bd-highlight p-3">
                    <Button
                      type="button"
                      variant="primary"
                      size="sm"
                      className="btn  btn-lg mx-1 btn_min--width save__btn"
                      onClick={() => {
                        const jsonData = { periods: response.periods };
                        saveExcel(jsonData, 'rp-periods-view.xlsx');
                      }}
                    >
                      Projects Save{' '}
                      <Icon.CloudArrowDownFill className="save__btn-icon" />
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
            {response.periods.length === 0 && (
              <div>No projects have been selected for this Scenario.</div>
            )}
          </div>
        </Col>
      )}
    </Container>
  );
}

export default ProjectDetail;
