import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
// import { formatNumRoundTwoDecimals } from '../../utils/format.js';

function ScenarioForm(props) {
  return (
    <>
      <Row>
        <Form.Group className="mb-3">
          <Form.Label className="form-control-label" htmlFor="name">
            Scenario Name
          </Form.Label>
          <Form.Control
            name="name"
            type="text"
            placeholder="Enter name"
            value={props.input.name}
            onChange={props.handleChange}
            disabled={props.readOnly}
          />
        </Form.Group>
      </Row>
      {/*<Row className="justify-content-md-center">*/}
      <Row>
        <Col sm={4}>
          <Form.Label className="form-control-label" htmlFor="offsetStartYear">
            Offset Start Year
          </Form.Label>
          <Form.Control
            name="offsetStartYear"
            value={props.input.offsetStartYear}
            onChange={props.handleChange}
            type="number"
            min={1900}
            max={2200}
            id="offsetStartYear"
            placeholder="YYYY"
          />
        </Col>
        <Col sm={6}>
          <Form.Label
            className="form-control-label"
            htmlFor="offsetReductionPercent"
          >
            Offset Reduction Percent
          </Form.Label>
          <Form.Control
            name="offsetReductionPercent"
            value={props.input.offsetReductionPercent}
            onChange={props.handleChange}
            type="number"
            min={0}
            max={100}
            id="offsetReductionPercent"
            placeholder="0-100"
          />
        </Col>
      </Row>
    </>
  );
}

function ScenarioEditModal(props) {
  const [inputs, setInputs] = useState(props.inputs);

  const { isDelete } = props;
  const { isNewScenario } = props;
  const onConfirm = () => {
    props.onConfirm(inputs);
  };
  const onDecline = () => {
    props.onDecline();
  };
  const handleChange = (e) => {
    const name = e.target.name;
    const value =
      e.target.type === 'number' ? parseInt(e.target.value) : e.target.value;
    setInputs((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const headingText = (_isDelete, _isNewScenario) => {
    let out;
    if (_isDelete) {
      out = 'Delete Current Scenario';
    } else if (_isNewScenario) {
      out = 'Add New Scenario';
    } else {
      out = 'Edit Scenario';
    }
    return out;
  };
  return (
    <Modal show={props.show} centered>
      <Modal.Body>
        <h4>{headingText(isDelete, isNewScenario)} </h4>
        <ScenarioForm
          input={inputs}
          handleChange={handleChange}
          readOnly={isDelete}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onConfirm}>
          Accept
        </Button>
        <Button variant="secondary" onClick={onDecline}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ScenarioEditModal;
