import React from 'react';

import Equipment from '../../entities/Equipment';
import PathwayValuesCard from '../../entities/PathwayValuesCard';
import ProjectTypeAll from '../../entities/ProjectTypeAll';
import ProjectListAll from '../../lists/ProjectListAll';
import SiteAll from '../../entities/SiteAll';
import EquipmentTypeAll from '../../entities/EquipmentTypeAll';
import DevUtils from '../../utilities/DevUtils';
import Calculations from '../../utilities/Calculations';
import File from '../../utilities/File';
import InventoryPlanner from '../../entities/InventoryPlanner';
import DLSToLatLong from '../../utilities/DLSToLatLong';
import ScenarioPlanner from '../../dashboards/scenarioPlanner/ScenarioPlanner';
import MessageLog from '../../utilities/MessageLog';
import ProjectTypeCalcList from '../../lists/ProjectTypeCalcList';
import AuditReport from '../../entities/AuditReport';
// eslint-disable-next-line
import DebugEquipment from '../../utilities/EquipmentDebug';
// eslint-disable-next-line
import DebugEquipmentParam from '../../utilities/EquipmentDebug';
import ProjectDetail from '../../entities/ProjectDetail';
import PeriodDetail from '../../entities/PeriodDetail';
import SiteDashboard from '../../dashboards/sites/SiteDashboard';
import EquipmentDashboard from '../../dashboards/equipment/EquipmentDashboard';
import ScenarioCompare from '../../dashboards/scenarioCompare/ScenarioCompare';
import Share from '../../utilities/Share';
import ScenarioDashboard from '../../dashboards/ScenarioDashboard2';
import RpList from '../../utilities/RpList';
import Phil from '../../plots/phil';

export const HighwoodRoutes = {
  PathwayValuesCard: <PathwayValuesCard />,
  ProjectTypeAll: <ProjectTypeAll />,
  ProjectListAll: <ProjectListAll />,
  SiteAll: <SiteAll />,
  EquipmentAll: <Equipment />,
  EquipmentTypeAll: <EquipmentTypeAll />,
  DevUtils: <DevUtils />,
  Calculations: <Calculations />,
  AdminFile: <File />,
  InventoryPlanner: <InventoryPlanner />,
  DLSToLatLong: <DLSToLatLong />,
  ScenarioPlanner: <ScenarioPlanner />,
  MessageLog: <MessageLog />,
  ProjectTypeCalcList: <ProjectTypeCalcList />,
  DebugEquipment: <DebugEquipment />,
  DebugEquipmentParam: <DebugEquipmentParam />,
  ProjectDetail: <ProjectDetail />,
  PeriodDetail: <PeriodDetail />,
  SiteDashboard: <SiteDashboard />,
  EquipmentDashboard: <EquipmentDashboard />,
  ScenarioCompare: <ScenarioCompare />,
  ScenarioDashboard: <ScenarioDashboard />,
  Share: <Share />,
  RpList: <RpList />,
  Phil: <Phil />,
  AuditReport: <AuditReport />,
};

const menuItems = [
  {
    key: 'file',
    title: 'File',
    component: 'RpList',
    href: '/file',
    roles: ['admin', 'user'],
  },
  {
    key: 'admin',
    title: 'Admin',
    roles: ['admin'],
    dropdownItems: [
      {
        title: 'Scenario Dashboard',
        component: 'ScenarioDashboard',
        href: '/scenariodashboard/:scenarioKey',
        roles: ['admin', 'user'],
      },
      {
        title: 'Scenario Dashboard',
        component: 'ScenarioDashboard',
        href: '/larry/:scenarioKey',
        roles: ['admin', 'user'],
      },
      {
        title: 'Project Detail',
        component: 'ProjectDetail',
        href: '/projectDetail/:projectKey',
        roles: ['admin'],
      },
      {
        title: 'Equipment Calcs',
        component: 'DebugEquipmentParam',
        href: '/debug/equipment/:equipmentKey',
        roles: ['admin'],
      },
      {
        key: 'audit',
        title: 'Audit Report',
        component: 'AuditReport',
        href: '/AuditReport',
        devOnly: true,
        roles: ['admin'],
      },
      {
        key: 'share',
        title: 'Share',
        component: 'Share',
        href: '/share',
        devOnly: true,
        roles: ['admin'],
      },
      {
        key: 'calculations',
        title: 'Calculations',
        component: 'Calculations',
        href: '/calculations',
        devOnly: true,
        roles: ['admin'],
      },
      {
        key: 'dev-utils',
        title: 'Development',
        component: 'DevUtils',
        href: '/devUtils',
        devOnly: true,
        roles: ['admin'],
      },
      {
        key: 'debug-equipment',
        title: 'Equipment Calcs',
        component: 'DebugEquipment',
        href: '/debug/equipment',
        devOnly: true,
        roles: ['admin'],
      },
      {
        key: 'file-manager',
        title: 'File Manager',
        component: 'AdminFile',
        href: '/adminFile',
        roles: ['admin'],
      },
    ],
  },
  {
    title: 'Phil Pie',
    component: 'Phil',
    href: '/phil',
    roles: ['admin', 'user'],
  },
  {
    // Scenario compare is the root login page
    // thus is why is has "/" as the href
    key: 'scenario-compare',
    title: 'Scenario Compare',
    component: 'ScenarioCompare',
    href: '/ScenarioCompare',
    roles: ['admin', 'user'],
  },
  {
    key: 'scenario-planner',
    title: 'Scenario Planner',
    component: 'ScenarioPlanner',
    href: '/scenarioPlanner',
    roles: ['admin'],
  },
  {
    key: 'inventory',
    title: 'Inventory',
    roles: ['admin'],
    dropdownItems: [
      {
        key: 'inventory-planner',
        title: 'Inventory Planner',
        component: 'InventoryPlanner',
        href: '/inventoryPlanner',
        roles: ['admin'],
      },
      {
        key: 'projects',
        title: 'Projects',
        component: 'ProjectListAll',
        href: '/projectListAll',
        roles: ['admin'],
      },
      {
        key: 'pathway-values',
        title: 'Pathway Values',
        component: 'PathwayValuesCard',
        href: '/pathwayValues',
        roles: ['admin'],
      },
      {
        key: 'sites-all',
        title: 'Sites',
        component: 'SiteAll',
        href: '/sites',
        roles: ['admin'],
      },
      {
        key: 'site-dashboard',
        title: 'Site Dashboard',
        component: 'SiteDashboard',
        href: '/siteDashboard',
        roles: ['admin'],
      },
      {
        key: 'equipment-all',
        title: 'Equipment',
        component: 'EquipmentAll',
        href: '/equipment',
        roles: ['admin'],
      },
      {
        key: 'equipment-dashboard',
        title: 'Equipment Dashboard',
        component: 'EquipmentDashboard',
        href: '/equipmentDashboard',
        roles: ['admin'],
      },
      {
        key: 'equipment-types',
        title: 'Equipment Types',
        component: 'EquipmentTypeAll',
        href: '/equipmentTypes',
        roles: ['admin'],
      },
      {
        key: 'project-types',
        title: 'Project Types',
        component: 'ProjectTypeAll',
        href: '/projectTypeAll',
        roles: ['admin'],
      },
      {
        key: 'project-type-calcs',
        title: 'Project Type Calc',
        component: 'ProjectTypeCalcList',
        href: '/projectTypeCalcList',
        roles: ['admin'],
      },
      {
        key: 'period-detail',
        title: 'Period Detail',
        component: 'PeriodDetail',
        href: '/periodDetail',
        roles: ['admin'],
      },
    ],
  },
  {
    key: 'message-log',
    title: 'Logs',
    component: 'MessageLog',
    href: '/logs',
    roles: ['admin', 'user'],
  },
];

export default menuItems;
