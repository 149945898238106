import React, { useContext, useState } from 'react';

import { Button, Container } from 'react-bootstrap';
import { FileSelect } from './FileSelect';
import { saveExcel, downloadToFile } from '../../data/io/saveData';
import { DataAPIContext } from '../../contexts/data';

function File() {
  const { dataClient, unpackResponse } = useContext(DataAPIContext);
  const [fileSelect, setFileSelect] = useState(false);
  // const [scenarioKey, setScenarioKey] = useState('');

  async function loadSystemData(file) {
    await unpackResponse(dataClient.loadSystemData(file));
  }

  const jsonPrepareSystemDownloadDataAPI = async () => {
    const jsonData = await unpackResponse(
      dataClient.prepareSystemDownloadDataAPI()
    );
    downloadToFile(jsonData, 'defaults.json', 'text/plain');
  };

  const jsonPrepareClientDownloadDataAPI = async () => {
    const jsonData = await unpackResponse(
      dataClient.prepareClientDownloadDataAPI()
    );
    downloadToFile(jsonData, 'reductionPathway.json', 'text/plain');
  };

  const excelPrepareClientDownloadDataAPI = async () => {
    const jsonData = await unpackResponse(
      dataClient.prepareClientDownloadDataAPI()
    );
    const json = JSON.parse(jsonData);
    saveExcel(json, 'data.xlsx');
  };

  const excelPrepareSystemDownloadDataAPI = async () => {
    const jsonData = await unpackResponse(
      dataClient.prepareSystemDownloadDataAPI()
    );
    const json = JSON.parse(jsonData);
    saveExcel(json, 'system-data.xlsx');
  };
  //
  // const saveToLocalStore = async () => {
  //   const response = await unpackResponse(dataClient.saveClientData());
  //   // eslint-disable-next-line no-undef
  //   console.log('saveToLocalStore:', response);
  // };
  //
  // const readFromLocalStore = async () => {
  //   const json = await unpackResponse(dataClient.readClientData());
  //   // eslint-disable-next-line no-undef
  //   console.log('readFromLocalStore:', json);
  // };
  //
  // const larryPlay = () => {
  //   // eslint-disable-next-line no-undef
  //   console.log('Joke is on you... nothing happens.');
  // };
  //
  // const getScenarioTimeseriesInfoTest = async () => {
  //   const json = await unpackResponse(dataClient.getScenarioTimeseriesInfo());
  //   console.log('getScenarioTimeseriesInfo', json);
  // };
  //
  // const getScenarioStatsForCurrentScenario = async () => {
  //   const json = await unpackResponse(dataClient.getScenarioStats());
  //   console.log('getScenarioTimeseriesInfo', json);
  // };
  //
  // const getAllProjects = async () => {
  //   const json = await unpackResponse(dataClient.getProjects());
  //   console.log('getProjects', json);
  // };
  //
  // const getAllSites = async () => {
  //   const json = await unpackResponse(dataClient.getSites());
  //   console.log('getSites', json);
  // };
  //
  // const getScenarioStatsForProvidedScenario = async (key) => {
  //   const json = await unpackResponse(dataClient.getScenarioStats(key));
  //   console.log('getScenarioTimeseriesInfo', json);
  //   setScenarioKey('');
  // };
  //
  // const getProjectsByScenario = async (key, filterParams) => {
  //   const json = await unpackResponse(
  //     dataClient.getProjectsByScenario(key, filterParams)
  //   );
  //   console.log(`getProjectsByScenario ${key}`, json);
  //   setScenarioKey('');
  // };
  //
  // const getSitesByScenario = async (key) => {
  //   const json = await unpackResponse(dataClient.getSitesByScenario(key));
  //   console.log(`getSitesByScenario ${key}`, json);
  //   setScenarioKey('');
  // };

  return (
    <Container>
      <div className="col-6">
        <h2>File</h2>
        <ol>
          <li>
            <Button onClick={() => jsonPrepareSystemDownloadDataAPI()}>
              Save System Data to JSON
            </Button>
          </li>
          <li>
            <Button onClick={() => jsonPrepareClientDownloadDataAPI()}>
              Save Client Data to JSON
            </Button>
          </li>
          <li>
            <Button onClick={() => excelPrepareSystemDownloadDataAPI()}>
              Save System Data to Excel
            </Button>
          </li>
          <li>
            <Button onClick={() => excelPrepareClientDownloadDataAPI()}>
              Save Client Data to Excel
            </Button>
          </li>
          <li>
            <Button onClick={() => setFileSelect(true)}>
              Load System Data from JSON or Excel
            </Button>
          </li>
          {/*<li>--- Local Storage ---</li>*/}
          {/*<li className="clLink" onClick={() => saveToLocalStore()}>*/}
          {/*  Save data to local store*/}
          {/*</li>*/}
          {/*<li className="clLink" onClick={() => readFromLocalStore()}>*/}
          {/*  Read data from local store*/}
          {/*</li>*/}
          {/*<li className="clLink" onClick={() => larryPlay()}>*/}
          {/*  Larry Play*/}
          {/*</li>*/}
          {/*<li*/}
          {/*  className="clLink"*/}
          {/*  onClick={() => getScenarioTimeseriesInfoTest()}*/}
          {/*>*/}
          {/*  Test getScenarioTimeseriesInfo API call*/}
          {/*</li>*/}
          {/*<li*/}
          {/*  className="clLink"*/}
          {/*  onClick={() => getScenarioStatsForCurrentScenario()}*/}
          {/*>*/}
          {/*  Test getScenarioStats API call with no arguments (current scenario)*/}
          {/*</li>*/}
          {/*<li className="clLink" onClick={() => getAllProjects()}>*/}
          {/*  Test getProjects API call with no arguments (all projects in*/}
          {/*  pathway)*/}
          {/*</li>*/}
          {/*<li className="clLink" onClick={() => getAllSites()}>*/}
          {/*  Test getSites API call with no arguments (all sites in pathway)*/}
          {/*</li>*/}
          {/*<label>Scenario Key</label>*/}
          {/*// eslint-disable-next-line*/}
          {/*<input*/}
          {/*  type="text"*/}
          {/*  value={scenarioKey}*/}
          {/*  onChange={(e) => setScenarioKey(e.target.value)}*/}
          {/*/>*/}
          {/*// eslint-disable-next-line*/}
          {/*<li*/}
          {/*  className="clLink"*/}
          {/*  onClick={() => getScenarioStatsForProvidedScenario(scenarioKey)}*/}
          {/*>*/}
          {/*  Test getScenarioStats API call with provided scenario key*/}
          {/*</li>*/}
          {/*// eslint-disable-next-line jsx-a11y/click-events-have-key-events*/}
          {/*<li*/}
          {/*  className="clLink"*/}
          {/*  onClick={() => getProjectsByScenario(scenarioKey)}*/}
          {/*>*/}
          {/*  Test getProjectsByScenario API call with provided scenario key*/}
          {/*</li>*/}
          {/*// eslint-disable-next-line*/}
          {/*jsx-a11y/no-noninteractive-element-interactions*/}
          {/*<li*/}
          {/*  className="clLink"*/}
          {/*  onClick={() =>*/}
          {/*    getProjectsByScenario(scenarioKey, {*/}
          {/*      include: {},*/}
          {/*      exclude: { status: 'Potential' },*/}
          {/*    })*/}
          {/*  }*/}
          {/*>*/}
          {/*  Test getProjectsByScenario API call with provided scenario key and*/}
          {/*  exclude Potential projects*/}
          {/*</li>*/}
          {/*// eslint-disable-next-line jsx-a11y/click-events-have-key-events //*/}
          {/*eslint-disable-next-line*/}
          {/*jsx-a11y/no-noninteractive-element-interactions*/}
          {/*<li*/}
          {/*  className="clLink"*/}
          {/*  onClick={() => getSitesByScenario(scenarioKey)}*/}
          {/*>*/}
          {/*  Test getSites API call with provided scenario key*/}
          {/*</li>*/}
        </ol>
      </div>

      {fileSelect && <FileSelect process={loadSystemData} />}
    </Container>
  );
}

export default File;
