import React, { useContext, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Button, Col, Container, Row } from 'react-bootstrap';

import { DataAPIContext } from '../../../contexts/data';
import { ProjectTypeView, ProjectView } from '../../../dataAPI/Views';
import LoadingSpinner from '../../utilities/LoadingSpinner';
import { saveExcel } from '../../../data/io/saveData';
import ProjectTypes from './ProjectTypes';
import NumberInput from '../../utilities/NumberInput';

export interface IProps {
  projects: ProjectView[];
  projectTypes: ProjectTypeView[];
  refreshData: Function;
  currentScenario: number;
}
interface InputsStrings {
  maxAbatement: string;
  maxCost: string;
  maxCap: string;
  maxReduction: string;
  maxPercent: string;
  maxProjects: string;
  maxCapYearly: string;
}
interface InputsOthers {
  method: string;
  freshStart: boolean;
  negativeProjects: boolean;
  selectedProjectTypes: never[];
}
interface IInputs extends InputsStrings, InputsOthers {}

let selectedProjectTypes: string[] = [];

export default function ScenarioGenerator({
  projects,
  projectTypes,
  refreshData,
  currentScenario,
}: IProps) {
  const defaultInputsState: IInputs = {
    method: 'abatement',
    maxAbatement: '',
    maxCost: '',
    maxCap: '',
    maxReduction: '',
    maxPercent: '',
    maxProjects: '',
    freshStart: true,
    maxCapYearly: '',
    negativeProjects: false,
    selectedProjectTypes: [],
  };

  const { dataClient, unpackResponse } = useContext(DataAPIContext);
  const [implementationDate, setImplementationDate] = useState(new Date());
  const [calculating, setCalculating] = useState(false);
  const [inputs, setInputs] = useState({
    ...defaultInputsState,
    implementationDate,
  });
  const [initPTSelected, setInitPTSelected] = useState<string[]>([]);

  useEffect(() => {
    const impdt = new Date();
    impdt.setMonth(impdt.getMonth() + 3);
    impdt.setDate(1); // avoid 30 & 31 day months
    setImplementationDate(impdt);
  }, []);

  useEffect(() => {
    getScenarioInputs().then((scenarioInputs: any) => {
      if (scenarioInputs) {
        const inputsObj = JSON.parse(scenarioInputs);
        selectedProjectTypes = inputsObj.selectedProjectTypes;
        setInitPTSelected(inputsObj.selectedProjectTypes);
        setInputs({
          ...inputsObj,
          implementationDate: new Date(inputsObj.implementationDate),
        });
      } else {
        setInputs({ ...defaultInputsState, implementationDate: new Date() });
        selectedProjectTypes = [];
        setInitPTSelected([]);
      }
    });
  }, [currentScenario]);

  useEffect(() => {
    saveScenarioInputs();
  }, [inputs]);

  const getScenarioInputs = async () =>
    unpackResponse(dataClient.getScenarioInputs(currentScenario));

  const saveScenarioInputs = async () => {
    unpackResponse(
      dataClient.saveScenarioInputs(
        currentScenario,
        JSON.stringify({ ...inputs, ...{ selectedProjectTypes } })
      )
    );
  };

  const setSelectedProjectTypes = (p: any) => {
    selectedProjectTypes = p.map((x: any) => x.key);
    saveScenarioInputs();
  };

  const handleChange = async (key: string, value: any) => {
    setInitPTSelected(selectedProjectTypes);
    setInputValue(key, value);
  };

  const setInputValue = (key: string, value: any) => {
    setInputs((prevState: any) => ({ ...prevState, [key]: value }));
  };

  const genScenario = async () => {
    setInitPTSelected(selectedProjectTypes);
    setCalculating(true);

    const results = await unpackResponse(
      dataClient.generateScenario({ ...inputs, selectedProjectTypes })
    );
    saveExcel(results, 'rp-scenario-view.xlsx');
    setCalculating(false);
    refreshData();
  };

  if (calculating) {
    return (
      <div className="text-center" style={{ height: '600px' }}>
        <LoadingSpinner spinnerText="Calculating" />
      </div>
    );
  }
  if (currentScenario === 1) {
    return (
      <Container>
        <Row style={{ height: '600px' }}>
          <h3 className="mx-auto" style={{ paddingTop: '200px' }}>
            Base Case can not be changed.
          </h3>
        </Row>
      </Container>
    );
  }

  return (
    <>
      <Row>
        <Col className="col-8 mx-auto mb-3 mt-3">
          <h2>Scenario Generation</h2>
        </Col>
      </Row>
      <Row>
        <Col className="col-8 generationCard mx-auto p-3">
          <Row className="mb-3">
            <Col>
              <label htmlFor="projectOrder">
                Project Order
                <select
                  id="projectOrder"
                  className="form-control"
                  name="method"
                  value={inputs.method}
                  onChange={(e) => handleChange('method', e.target.value)}
                >
                  <option value="abatement">
                    method Lowest First Year Abatement Cost
                  </option>
                  <option value="emissions">Highest Emissions</option>
                  <option value="carbonTax">
                    Carbon Tax then Abatement Cost
                  </option>
                  <option value="scope1">Scope1 Emissions Only</option>
                </select>
              </label>
            </Col>

            <Col>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="implementationDate">
                Starting Implementation Date:
                <DatePicker
                  id="implementationDate"
                  name="implementationDate"
                  className="date-picker"
                  selected={inputs.implementationDate}
                  onChange={(date) => setInputValue('implementationDate', date)}
                  dateFormat="yyyy-MM"
                  showMonthYearPicker
                  showFullMonthYearPicker
                  portalId="root"
                />
              </label>
            </Col>

            <Col>
              <label htmlFor="freshStart">
                Fresh Start Projects
                <br />
                <input
                  name="freshStart"
                  type="checkbox"
                  checked={inputs.freshStart ?? 'checked'}
                  onChange={(e) => handleChange('freshStart', e.target.checked)}
                />
              </label>
            </Col>
            <Col>
              <NumberInput
                id="maxCapYearly"
                name="maxCapYearly"
                label="Max Capital Yearly"
                value={inputs.maxCapYearly}
                className="form-control"
                onChange={(value: string) =>
                  handleChange('maxCapYearly', value)
                }
                placeholder="1,000,000"
              />
            </Col>
            <Col>
              <label htmlFor="negativeProjects">
                Allow Negative Projects
                <br />
                <input
                  id="negativeProjects"
                  name="negativeProjects"
                  type="checkbox"
                  checked={inputs.negativeProjects}
                  onChange={(e) =>
                    handleChange('negativeProjects', e.target.checked)
                  }
                />
              </label>
            </Col>
          </Row>
          <Row>
            <Col>
              <NumberInput
                id="maxAbatement"
                name="maxAbatement"
                label="Max Abatement Cost"
                value={inputs.maxAbatement}
                className="form-control"
                onChange={(value: string) =>
                  handleChange('maxAbatement', value)
                }
                placeholder="1,000"
              />
            </Col>
            <Col>
              <NumberInput
                id="maxCost"
                name="maxCost"
                label="Max Cost"
                value={inputs.maxCost}
                className="form-control"
                onChange={(value: string) => handleChange('maxCost', value)}
                placeholder="10,000"
              />
            </Col>
            <Col>
              <NumberInput
                id="maxCap"
                name="maxCap"
                label="Max Capital"
                value={inputs.maxCap}
                className="form-control"
                onChange={(value: string) => handleChange('maxCap', value)}
                placeholder="100,000"
              />
            </Col>
            <Col>
              <NumberInput
                id="maxReduction"
                name="maxReduction"
                label="Max Reduction"
                value={inputs.maxReduction}
                className="form-control"
                onChange={(value: string) =>
                  handleChange('maxReduction', value)
                }
                placeholder="100,000"
              />
            </Col>
            <Col>
              <NumberInput
                id="maxPercent"
                name="maxPercent"
                label="Max %"
                value={inputs.maxPercent}
                className="form-control"
                onChange={(value: string) => handleChange('maxPercent', value)}
                placeholder="10"
              />
            </Col>
            <Col>
              <NumberInput
                id="maxProjects"
                name="maxProjects"
                label="Max Projects"
                value={inputs.maxProjects}
                className="form-control"
                onChange={(value: string) => handleChange('maxProjects', value)}
                placeholder="20"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ProjectTypes
                projects={projects}
                projectTypes={projectTypes}
                selectedProjectTypes={initPTSelected}
                onUpdate={setSelectedProjectTypes}
              />
            </Col>
          </Row>
          <div className="d-flex bd-highlight flex-row-reverse p-3">
            <Button
              className="btn btn-lg btn_min--width save__btn mx-1"
              onClick={genScenario}
            >
              Generate Scenario
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
}
