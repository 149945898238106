import React from 'react';

import { TableColumn } from 'react-data-table-component';
import { ScenarioTimeseriesView } from '../../dataAPI/Views';
import AppDataTable from '../utilities/AppDataTable';

interface IProps {
  timeseries: ScenarioTimeseriesView;
}

function OffsetsList({ timeseries }: IProps) {
  if (!timeseries) return null;
  const records = timeseries.period.map((r, i) => ({
    period: r,
    index: i,
    emissions: timeseries.emissions[i],
    emissionsTarget: timeseries.emissionsTarget,
    offsetsEmissions: timeseries.offsetsEmissions[i],
    offsetsCostPer: timeseries.offsetsCostPer[i],
    offsetsCostTotal: timeseries.offsetsCostTotal[i],
  }));
  const columns: TableColumn<any>[] = [
    {
      name: 'Period',
      selector: (row) => row.period,
      sortable: true,
      right: true,
      // width: '300px',
    },
    {
      name: 'Remaining Emissions',
      selector: (row) => Math.round(row.emissions).toLocaleString(),
      sortable: true,
      right: true,
      // width: '300px',
    },
    {
      name: 'Emissions Target',
      selector: (row) => Math.round(row.emissionsTarget).toLocaleString(),
      sortable: true,
      right: true,
      // width: '300px',
    },
    {
      name: 'Offsets Required',
      selector: (row) => Math.round(row.offsetsEmissions).toLocaleString(),
      sortable: true,
      right: true,
      // width: '300px',
    },
    {
      name: 'Price Per',
      selector: (row) => Math.round(row.offsetsCostPer).toLocaleString(),
      sortable: true,
      right: true,
      // width: '300px',
    },
    {
      name: 'Annual Cost',
      selector: (row) => Math.round(row.offsetsCostTotal).toLocaleString(),
      sortable: true,
      right: true,
      // width: '300px',
    },
  ];
  return (
    <>
      <h3 className="mt-3 mb-3 ps-3">
        Offsets for {timeseries.reductionRatio * 100}% of baseline.
      </h3>
      <AppDataTable columns={columns} data={records} />
    </>
  );
}

export default OffsetsList;
