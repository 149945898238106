import React from 'react';
import DataTable from 'react-data-table-component';
import * as Icon from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
// import { customListStyles } from "../../utils/customStyles";
import EquipmentPTList from './EquipmentPTList';

function EquipmentList(props) {
  const columns = [
    {
      name: 'Key',
      selector: (row) => row.key,
      sortable: props.sortable,
      // left: true,
      reorder: true,
      grow: 2,
      // selectedAttr: 'key',
    },
    {
      name: 'Name',
      selector: (row) => row.name,
      cell: (row) => (
        <>
          <Link className="clLink" to={`/debug/equipment/${row.key}`}>
            <Icon.Bug className="icon-bug" />
          </Link>
          {row.name}
        </>
      ),
      sortable: props.sortable,
      // left: true,
      minWidth: '170px',
      reorder: true,
      grow: 2,
      // selectedAttr: 'name',
    },
    {
      name: 'Site',
      selector: (row) => row.site,
      sortable: props.sortable,
      center: true,
      reorder: true,
      grow: 3,
      // selectedAttr: 'site',
      cell: (row) => <span>{row.site}</span>,
      // minWidth: 150,
    },
    {
      name: 'Type',
      selector: (row) => row.type,
      sortable: props.sortable,
      center: true,
      minWidth: '150px',
      reorder: true,
      grow: 1,
      // selectedAttr: 'type',
    },
    {
      name: 'Operational',
      selector: (row) => row.operational,
      sortable: props.sortable,
      center: true,
      reorder: true,
      grow: 1,
      // selectedAttr: 'operational',
    },
    {
      name: (
        <div>
          Operating
          <br />
          Hours
        </div>
      ),
      selector: (row) => row.operatingHours | 0,
      cell: (row) => Math.round(row.operatingHours).toLocaleString(),
      // cell: (row) => row.operatingHours,
      sortable: props.sortable,
      center: true,
      reorder: true,
      grow: 1,
      // selectedAttr: 'operatingHours',
    },
    {
      name: (
        <>
          Mode Of
          <br />
          Operation
        </>
      ),
      selector: (row) => row.modeOfOperation,
      sortable: props.sortable,
      // left: true,
      reorder: true,
      grow: 1,
      // selectedAttr: 'modeOfOperation',
    },
    {
      name: (
        <>
          Activity
          <br />
          Type
        </>
      ),
      selector: (row) => row.activityType,
      sortable: props.sortable,
      // left: true,
      reorder: true,
      grow: 1,
      // selectedAttr: 'activityType',
    },
    {
      name: (
        <>
          Yearly
          <br />
          Emissions
        </>
      ),
      selector: (row) => row.yearlyEmissions,
      cell: (row) => Math.round(row.yearlyEmissions).toLocaleString(),
      sortable: props.sortable,
      center: true,
      reorder: true,
      grow: 1,
      // selectedAttr: 'yearlyEmissions',
    },
    {
      name: (
        <>
          Baseline
          <br />
          Emissions
        </>
      ),
      selector: (row) => row.baselineEmissions,
      cell: (row) => Math.round(row.baselineEmissions).toLocaleString(),
      sortable: props.sortable,
      center: true,
      reorder: true,
      grow: 1,
      // selectedAttr: 'baseLineEmissions',
    },
    {
      name: 'Combustion',
      selector: (row) => `${row.isCombustion}`,
      sortable: props.sortable,
      center: true,
      reorder: true,
      grow: 1,
      // selectedAttr: 'isCombustion',
    },
    {
      name: (
        <>
          Fuel
          <br />
          Type
        </>
      ),
      selector: (row) => `${row.fuelType ? row.fuelType : ''}`,
      sortable: props.sortable,
      center: true,
      reorder: true,
      grow: 1,
      // selectedAttr: 'fuelType',
    },
    {
      name: (
        <>
          Yearly
          <br />
          Fuel
        </>
      ),
      selector: (row) =>
        `${
          row.annualFuelConsumption
            ? Math.round(row.annualFuelConsumption).toLocaleString()
            : ''
        }`,
      sortable: props.sortable,
      right: true,
      reorder: true,
      grow: 1,
      // selectedAttr: 'annualFuelConsumption',
    },
    {
      name: (
        <>
          Yearly
          <br />
          Vented
        </>
      ),
      selector: (row) =>
        `${
          row.annualVentingVol
            ? Math.round(row.annualVentingVol).toLocaleString()
            : ''
        }`,
      sortable: props.sortable,
      right: true,
      reorder: true,
      grow: 1,
      // selectedAttr: 'annualVentingVol',
    },
    {
      name: (
        <>
          Yearly
          <br />
          Flared
        </>
      ),
      selector: (row) =>
        `${
          row.annualFlareVol
            ? Math.round(row.annualFlareVol).toLocaleString()
            : ''
        }`,
      sortable: props.sortable,
      right: true,
      reorder: true,
      grow: 1,
      // selectedAttr: 'annualFlareVol',
    },
    {
      name: (
        <>
          Yearly
          <br />
          Electricity
        </>
      ),
      selector: (row) =>
        `${
          row.annualElecUsage
            ? Math.round(row.annualElecUsage).toLocaleString()
            : ''
        }`,
      sortable: props.sortable,
      right: true,
      reorder: true,
      grow: 1,
      // selectedAttr: 'annualElecUsage',
    },
    {
      name: <>Fuel Energy Content</>,
      selector: (row) =>
        `${
          row.fuelEnergyContent
            ? Math.round(row.fuelEnergyContent).toLocaleString()
            : ''
        }`,
      sortable: props.sortable,
      right: true,
      reorder: true,
      grow: 1,
    },
  ];

  const selected = false;
  let ScrollHeight = '65vh';

  if (props.ScrollHeight) {
    ScrollHeight = props.ScrollHeight;
  }

  const expandableComponent = (obj) => {
    const equipment = obj.data;
    return (
      <div
        style={{ border: 'solid', borderWidth: 'thin', width: 'min-content' }}
      >
        <EquipmentPTList
          equipment={equipment}
          onEquipmentPTClicked={(eq, key) =>
            props.onEquipmentPTClicked(eq, key)
          }
        />
      </div>
    );
  };

  return (
    <DataTable
      columns={columns}
      data={props.equipmentList}
      pagination
      paginationPerPage={20}
      paginationRowsPerPageOptions={[20, 500, 1000]}
      noHeader
      fixedHeader
      fixedHeaderScrollHeight={ScrollHeight}
      highlightOnHover
      selectableRows={selected}
      onSelectedRowsChange={props.onSelectedRowsChange}
      dense
      expandableRows
      expandableRowsComponent={expandableComponent}
      // customStyles={customListStyles}
    />
  );
}

export default EquipmentList;
