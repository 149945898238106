import React, { useEffect, useState } from 'react';

interface NumberInputProps {
  id: string;
  name: string;
  label: string;
  value: string; // Changed value type to number
  onChange: (value: string) => void;
  className: string;
  placeholder?: string;
}

function NumberInput({
  id,
  name,
  label,
  value,
  onChange,
  className,
  placeholder,
}: NumberInputProps) {
  // State to manage the input number value with commas
  const [inputValue, setInputValue] = useState<string>('');

  useEffect(() => {
    setInputValue(NumbersFormatter(value.toString()));
  }, [value]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setInputValue(NumbersFormatter(newValue)); // Update inputValue with formatted value
    const sanitizedValue = newValue.replace(/,/g, ''); // Convert to number after removing commas
    onChange(sanitizedValue);
  };

  function NumbersFormatter(input: string): string {
    const sanitizedInput = `${input}`.replace(/,/g, '');
    return sanitizedInput.replace(/\B(?=(\d{3})+(?!\d))/g, ','); // Return the formatted value here
  }

  return (
    <label htmlFor={id}>
      {label}
      <input
        id={id}
        name={name}
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        className={className}
        placeholder={placeholder}
      />
    </label>
  );
}

NumberInput.defaultProps = {
  placeholder: '',
};

export default NumberInput;
