/* eslint-disable no-console */
import MainThing from './MainThing';

interface IStuff {
  getStuff: () => string;
  setStuff: (name: string) => void;
}

interface IValues {
  name: string;
  value: number;
}
// Simple object
interface IObject {
  [key: string]: [value: string];
}

interface IComplexObject {
  [key: string]: { [secondKey: string]: [secondValue: string] };
}

enum CostGroup {
  OPPEX = 'OPPEX',
  CAPEX = 'CAPEX',
}

interface ICostGroups {
  [CostGroup.CAPEX]?: string;
  [CostGroup.OPPEX]?: string;
}

export function larry(data: any, parms: any) {
  console.log(parms);
  console.log('before', data);
  data.initData();
  console.log('after', data);

  // data.addPathwayValue(parms);
  // console.log(data.pathwayValues);
  // console.log(lfs1());
  // console.log(lfs2());
  // console.log(useRealMap());

  // data.calcEmissionsCosts();
  // console.log(data);
}
// can the key of a typescript object be a type or enum
// https://stackoverflow.com/questions/44243060/use-enum-as-restricted-key-type-in-typescript

export function doItBetter() {
  const cg1: ICostGroups = {};
  // cg1[CostGroup.CAPEX] = 'asdf';
  Object.values(CostGroup).forEach((cg) => {
    console.log(cg);
  });

  const cg2: { key: string; value: string } = { key: 'value', value: 'asdf' };

  interface Cg3 {
    [name: string]: number;
  }
  const cg3: Cg3 = { key: 1, value: 2, whaEver: 3 };
  cg3.asdf = 5;
  const s3 = 'dsfg';
  cg3[s3] = 6;

  const cg4: any = {};
  cg4.whatever = 123;

  // cg2['asdf'] = 1;
  const addItem = (key: string) => {
    // let group:CostGroup = CostGroup[key];
    // const g: keyof typeof CostGroup = key;
    const g: CostGroup = CostGroup[key as keyof typeof CostGroup];
    cg1[g] = 'asdf';
  };

  addItem('CAPEX');
  addItem('poop');
  console.log(cg1);

  const o1: ICostGroups = {};
  const obj: IObject = {};
  const cobj: IComplexObject = {};
  return [obj, cobj, o1, cg1, cg2, cg3, cg4];
}

type ProjectStatus = 'Active' | 'Hold' | 'Done';

class Stuff extends MainThing implements IStuff, IValues {
  p1: string;

  p2: string;

  status: ProjectStatus;

  name: string;

  value: number;

  constructor(p1: string, p2: string, status: ProjectStatus) {
    super();
    this.p1 = p1;
    this.p2 = p2;
    this.name = '';
    this.value = 0;
    this.status = status;
  }

  getStuff() {
    return this.p1;
  }

  setStuff(str: any) {
    this.p2 = str;
  }

  showMyStuff() {
    //   const keys = Object.keys(this);
    //   const stuff = keys.map((k:string) => this[k]);
    const key = 'p2';
    // const value = this[key];
    // this.things = value;
    // return `My stuff: ${keys.join()} and ${stuff.join}`
    return this[key];
  }

  toString() {
    return `This let's me work. ${this.status}`;
  }
}

export function lfs1() {
  const stuff = new Stuff('one', 'two', 'Done');
  return `lfs ${stuff.p1} showMyStuff: ${stuff.showMyStuff()}`;
}

export function lfs2() {
  const obj = {
    a: 'asdf',
    b: 'The next big thing',
  };
  const stuff = new Stuff('one', 'two', 'Active');
  return `lfs ${stuff.p1} ${stuff.p2} ${obj.a}`;
}

export function useRealMap() {
  const map1 = new Map<number, string>();
  map1.set(1, 'one');
  map1.set(2, 'two');
  map1.set(3, 'three');

  console.log(map1);
  console.log(map1.get(1));
  console.log(map1.has(12));
  map1.forEach((value, key) => {
    console.log(key, value);
  });
  return map1;
}
// Just force an error... I know it's ugly...
export function createAnError(a: number, b: number, c: any) {
  const { thing } = c;
  console.log(thing.whatever);
  return a / b;
}
