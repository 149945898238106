import * as React from 'react';
import { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { formatNumNavigator } from '../../../utils/format';
import { ISiteList } from '../../../data/Types';
import { customTableStyles } from '../../../utils/customStyles';
import { SiteView } from '../../../dataAPI/Views';

interface SiteTableProps {
  typeData: SiteView[];
  title: string;
}

export default function SiteTable({ typeData, title }: SiteTableProps) {
  const [siteList, setSiteList] = useState<SiteView[]>([]);
  const [filteredList, setFilteredList] = useState<SiteView[]>([]);

  useEffect(() => {
    setFilteredList(typeData);
  }, [typeData]);

  useEffect(() => {
    setSiteList(getSiteTableValues() as SiteView[]);
  }, [filteredList]);

  const getSiteTableValues = (): SiteView[] => {
    const siteValues: ISiteList = {};
    filteredList.forEach((site) => {
      if (site.type) {
        const equipmentCount = site.equipmentCount!;
        if (siteValues[site.type]) {
          siteValues[site.type].siteCount! += 1;
          siteValues[site.type].yearlyEmissions! += site.yearlyEmissions
            ? site.yearlyEmissions
            : 0;
          siteValues[site.type].equipmentCount! += equipmentCount;
        } else {
          siteValues[site.type] = {
            key: site.type,
            name: site.name,
            type: site.type,
            siteCount: 1,
            yearlyEmissions: site.yearlyEmissions,
            equipmentCount,
          };
        }
      }
    });
    return Object.values(siteValues);
  };

  const columns: any = [
    {
      name: 'Site Information',
      selector: (row: ISiteList) => row.key,
      sortable: true,
      left: true,
      reorder: true,
    },
    {
      name: 'Number of Sites',
      selector: (row: ISiteList) => row.siteCount,
      sortable: true,
      right: true,
      reorder: true,
    },
    {
      name: 'Annual Emissions (tCO2e)',
      selector: (row: ISiteList) => row.yearlyEmissions,
      format: (row: SiteView) =>
        Math.round(row.yearlyEmissions!).toLocaleString(),
      // format: (row: ISiteList) => formatNumNavigator(row.yearlyEmissions),
      sortable: true,
      right: true,
      reorder: true,
    },
    {
      name: 'Equipment Count',
      selector: (row: ISiteList) => row.equipmentCount,
      format: (row: ISiteList) => formatNumNavigator(row.equipmentCount),
      sortable: true,
      right: true,
      reorder: true,
    },
  ];

  return (
    <DataTable
      title={title}
      columns={columns}
      data={siteList}
      defaultSortFieldId={1}
      striped
      pagination
      paginationPerPage={25}
      paginationRowsPerPageOptions={[25, 50, 100, 250, 500, 1000]}
      fixedHeader
      customStyles={customTableStyles}
      highlightOnHover
      dense
    />
  );
}
