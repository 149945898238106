import React from 'react';
import DataTable, { TableProps } from 'react-data-table-component';
import { TableStyles } from 'react-data-table-component/dist/src/DataTable/types';

function AppDataTable<T>({ ...props }: TableProps<T>) {
  const dataTableStyle: TableStyles = {
    table: {
      style: {
        marginTop: '1rem',
        marginBottom: '1rem',
        borderBottom: 'none',
        borderRadius: '4px',
        height: 'auto',
        width: 'auto',
      },
    },
    rows: {
      style: {
        height: '32px',
        minHeight: '102px', // override the row height
        width: '100%',
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
        whiteSpace: 'normal',
      },
    },
    cells: {
      style: {
        paddingLeft: '16px',
        paddingRight: '16px',
      },
    },
  };

  return (
    <div className=" r-border  m-0">
      <DataTable
        title={props.title}
        columns={props.columns}
        data={props.data}
        defaultSortFieldId={1}
        fixedHeader
        highlightOnHover
        responsive
        pointerOnHover
        persistTableHead
        dense
        customStyles={dataTableStyle}
        striped
        pagination
        paginationPerPage={25}
        paginationRowsPerPageOptions={[25, 50, 100, 250, 500, 1000]}
      />
    </div>
  );
}

export default AppDataTable;
