import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

export default function ListFilter(props) {
  const [filteredText, setFilteredText] = useState('');
  const [searchKey, setSearchKey] = useState('');

  useEffect(() => {
    // default to the first one.
    setSearchKey(Object.keys(props.selectOptions)[0]);
  }, []);

  useEffect(() => {
    filter();
  }, [searchKey]);

  const filter = () => {
    const filtered = props.list.filter((item) => {
      let bool = true;
      try {
        if (searchKey) {
          bool =
            item[searchKey]
              .toString()
              .toLowerCase()
              .indexOf(filteredText.toLowerCase()) !== -1;
        }
      } catch (e) {
        // Leave these console logs
        // eslint-disable-next-line no-undef
        console.log(
          `*** Error *** searchKey:'${searchKey}' filteredText: '${filteredText}'`,
          item
        );
        // eslint-disable-next-line no-undef
        console.log(e);
      }
      return bool;
    });
    props.setFilteredList(filtered);
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setFilteredText(value);
  };

  const handleSelectChange = (event) => {
    const { value } = event.target;
    setSearchKey(value);
  };

  return (
    <Row className="p-3">
      <Col className="col-3">
        <select
          className="custom-select block align-middle"
          value={searchKey}
          onChange={handleSelectChange}
        >
          {Object.keys(props.selectOptions).map((key) => (
            <option key={key} value={key}>
              {props.selectOptions[key]}
            </option>
          ))}
        </select>
      </Col>
      <Col className="col-9">
        <input
          className="w-100 rounded align-middle"
          onKeyUp={filter}
          type="text"
          id="filterText"
          name="filteredText"
          onChange={handleChange}
          value={filteredText}
        />
      </Col>
    </Row>
  );
}
