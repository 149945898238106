import React, { useContext, useEffect, useState } from 'react';

import { Container, Col, Row } from 'react-bootstrap';

import { useParams } from 'react-router-dom';
import { DataAPIContext } from '../../contexts/data';
import { ProjectState } from '../../data/ProjectState';
import { GetGroups } from '../../utils/GroupProjectsFuncs';
import ProjectMacc from '../plots/ProjectMacc';
import HScoreBoard from '../scoreboard/HScoreboard';
import ScenarioTimeseries from '../plots/ScenarioTimeseries';
import EquipmentEmissionsVsActivityType from './equipment/EquipmentEmissionsVsActivityType';
import EquipmentEmissionsVsType from './equipment/EquipmentEmissionsVsType';
import SiteTypeVsAnnualEmissions from './sites/SiteTypeVsAnnualEmissions';
import SiteTable from './sites/SiteTable';
import EquipmentTable from './equipment/EquipmentTable';
import SiteMap from '../plots/maps/siteMap';
import Offsets from '../lists/OffsetsList';
import { appPubSub, Topic } from '../../utils/AppPubSub';
import RpModal from '../utilities/RpModal';

let colorMap = {};
function ScenarioDashboard() {
  const params = useParams();
  const { scenarioKey } = params;

  const POTENTIAL = ProjectState.Potential;
  //  Define Global Context
  const { dataClient, unpackResponse } = useContext(DataAPIContext);

  // --- States --- //
  const [equipmentList, setEquipmentList] = useState([]);
  const [siteList, setSiteList] = useState([]);
  const [siteTypeList, setSiteTypeList] = useState([]);
  const [equipmentTypes, setEquipmentTypeList] = useState([]);
  const [committedProjects, setCommittedProjects] = useState([]);
  const [header, setHeader] = useState({});
  const [currentScenario, setCurrentScenario] = useState({
    name: scenarioKey,
    key: undefined,
  });
  const [scenarioTimeseries, setScenarioTimeseries] = useState();
  const [scenarioStats, setScenarioStats] = useState({
    lifetimeBaselineEmissions: 0,
    emissionReduction: 0,
    netCost: 0,
    capCost: 0,
    carbonTaxSavings: 0,
    projectCount: 0,
    abatementCost: 0,
  });

  const [show, setShow] = useState(false);
  const [showMacc, setShowMacc] = useState(false);
  function handleShow() {
    setShow(true);
  }
  function handleShowMacc() {
    setShowMacc(true);
  }
  //  On Component Mount
  useEffect(() => {
    const initCurrentScenario = async () => {
      await unpackResponse(dataClient.selectScenario(scenarioKey));
      const rScenarios = await unpackResponse(dataClient.getScenarios());
      const scenario = getCurrentScenario(rScenarios, scenarioKey);
      setCurrentScenario(scenario);
      appPubSub.publish(Topic.Header, { scenarioName: scenario.name });
      const pathwayValues = await unpackResponse(dataClient.getPathwayValues());
      setHeader({
        baseYear: pathwayValues.baseYear,
        endYear: pathwayValues.baseYear + pathwayValues.periods - 1,
      });
      getEquipmentTypeList();
      getEquipment();
      getSites();
      getSitesTypes();

      refreshData(scenarioKey);
    };

    initCurrentScenario();
  }, []);

  const getCurrentScenario = (scenarioList, currentScenarioKey) => {
    const scenariokey = parseInt(currentScenarioKey);
    return scenarioList.filter((m) => m.key === scenariokey)[0];
  };

  const getEquipment = async () => {
    const equipment = await unpackResponse(dataClient.getEquipment());
    setEquipmentList(equipment);
  };

  const getEquipmentTypeList = async () => {
    const equipmentTypes = await unpackResponse(dataClient.getEquipmentTypes());
    setEquipmentTypeList(equipmentTypes);
  };
  const getSites = async () => {
    const sites = await unpackResponse(dataClient.getSites());
    setSiteList(sites);
  };
  const getSitesTypes = async () => {
    const siteTypes = await unpackResponse(dataClient.getSiteTypeList());
    setSiteTypeList(siteTypes);
  };

  const refreshData = async (scenarioKey) => {
    const { committed, grouped } = await loadProjects();
    const rScenarioStats = await unpackResponse(dataClient.getScenarioStats());

    colorMap = grouped.reduce((obj, item) => {
      obj[item.key] = item.color;
      return obj;
    }, {});

    const rScenarioTimeseries = await unpackResponse(
      dataClient.getScenarioTimeseries(scenarioKey)
    );
    committed.sort((a, b) => a.abatementCost - b.abatementCost);
    setScenarioStats(rScenarioStats);
    setCommittedProjects(committed);
    setScenarioTimeseries(rScenarioTimeseries);
  };

  const loadProjects = async () => {
    const projectTypes = await unpackResponse(dataClient.getProjectTypes());
    const allProjects = await unpackResponse(dataClient.getProjects());
    const potential = allProjects
      .filter((p) => p.status === POTENTIAL)
      .filter((p) => p.lifetimeReduction > 0);
    const committed = allProjects.filter((p) => p.status !== POTENTIAL);
    const grouped = GetGroups(potential, projectTypes, 'projectType', [
      'yearlyAbatementCost',
      'capCost',
      'lifetimeReduction',
    ]);

    return {
      committed,
      grouped,
    };
  };

  return (
    <div>
      <Container>
        <h2 className="mt-5 mb-5">{currentScenario.name} - Scenario Insight</h2>
      </Container>

      <Container>
        <Row className="mb-3 mt-3">
          <Col className="col-6">
            {scenarioTimeseries ? (
              <ScenarioTimeseries
                timeseries={[scenarioTimeseries]}
                scenarioNames={[currentScenario.name]}
                requestedSeries={{
                  emissions: true,
                  cost: false,
                  carbonTax: false,
                }}
                showlegend={false}
              />
            ) : undefined}
          </Col>
          <Col className="col-6">
            {scenarioTimeseries ? (
              <ScenarioTimeseries
                timeseries={[scenarioTimeseries]}
                scenarioNames={[currentScenario.name]}
                requestedSeries={{
                  emissions: false,
                  cost: true,
                  carbonTax: false,
                }}
                showlegend={false}
              />
            ) : undefined}
          </Col>
        </Row>
        <Row className="mb-3 mt-3">
          <Col className="col-6">
            {scenarioTimeseries ? (
              <div className="r-border">
                <ScenarioTimeseries
                  timeseries={[scenarioTimeseries]}
                  scenarioNames={[currentScenario.name]}
                  requestedSeries={{
                    emissions: false,
                    cost: false,
                    carbonTax: true,
                  }}
                  showlegend={false}
                />
              </div>
            ) : undefined}
          </Col>
          <Col className="col-6">
            <div className="r-border">
              {scenarioTimeseries ? (
                <SiteTypeVsAnnualEmissions
                  sites={siteList}
                  siteTypeList={siteTypeList}
                  title="Site Type vs Annual Emissions"
                  format="number"
                />
              ) : undefined}
            </div>
          </Col>
        </Row>
        <Row className="mb-3 mt-3">
          <Col className="col-6">
            {scenarioTimeseries ? (
              <EquipmentEmissionsVsActivityType equipment={equipmentList} />
            ) : undefined}
          </Col>
          <Col className="col-6">
            {scenarioTimeseries ? (
              <EquipmentEmissionsVsType
                equipment={equipmentList}
                equipmentTypes={equipmentTypes}
              />
            ) : undefined}
          </Col>
        </Row>
        <Row className="mb-3 mt-3">
          <Row className="table_container r-border">
            <RpModal show={showMacc} setShow={setShowMacc}>
              <ProjectMacc
                title="Project MACC"
                cardID="active-projects"
                projects={committedProjects}
                onClick={(r) => console.log(r)}
                colorMap={colorMap}
              />
            </RpModal>
            <ProjectMacc
              title="Project MACC"
              cardID="active-projects"
              projects={committedProjects}
              onClick={(r) => console.log(r)}
              colorMap={colorMap}
              handleShowClick={handleShowMacc}
            />
          </Row>
        </Row>
        <Row className="mb-3 mt-3">
          <Row className="col-12 h-75">
            <Col className="r-border h-25">
              <HScoreBoard scenarioStats={scenarioStats} header={header} />
            </Col>
            <Col className="table_container r-border">
              <RpModal show={show} setShow={setShow}>
                <SiteMap sites={siteList} />
              </RpModal>
              <SiteMap sites={siteList} handleShowClick={handleShow} />
            </Col>
          </Row>
        </Row>
        <Row className="mb-3 mt-3">
          <Col className="col-4 ">
            <div className="table_container h-100 r-border s-bg">
              <SiteTable typeData={siteList} title="Site Type" />
            </div>
          </Col>
          <Col className="col-4">
            <div className="table_container h-100 r-border s-bg">
              <EquipmentTable
                typeData={equipmentList}
                valueType="activityType"
                title="Activity Type"
              />
            </div>
          </Col>
          <Col className="col-4">
            <div className="table_container  h-100 r-border s-bg">
              <EquipmentTable
                typeData={equipmentList}
                valueType="equipment"
                title="Equipment Type"
              />
            </div>
          </Col>
        </Row>
        {/*<Row className="mb-3 mt-3">*/}
        {/*  <Col className="col-4">*/}
        {/*    <div className="table_container h-100 s-border s-bg">*/}
        {/*      /!* <CarbonAccountingTable /> *!/*/}
        {/*    </div>*/}
        {/*  </Col>*/}
        {/*</Row>*/}
        <Row className="justify-content-md-center">
          <Col xs lg="6">
            <Offsets timeseries={scenarioTimeseries} />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default ScenarioDashboard;
