export default function GetBubbleData(data) {
  return [
    {
      name: data.map((p) => p.name),
      text: data.map((p) => p.name),
      x: data.map((p) => p.cost),
      y: data.map((p) => p.y),
      marker: {
        // Emissions
        size: data.map((p) => p.x),
        sizeref: 50,
        sizemode: 'area',
        opacity: Array(data.length).fill(0.5),
        color: data.map((p) => p.color),
      },
      hovertemplate:
        '<b>%{text}</b><br><br>' +
        // `<b>${("description" in p) ? p.description : "no description"}</b><br><br>` +
        '%{xaxis.title.text}: %{x:$,.0f}<br>' +
        '%{yaxis.title.text}: %{y:$,.0f}<br>' +
        'Total Emission Reductions (tCO2e): %{marker.size:.0f}<br><extra></extra>',
      mode: 'markers',
    },
  ];
}
