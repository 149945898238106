import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';

function OffsetCard(props) {
  if (!props.currentScenario) return '';

  const [scenario, setScenario] = useState({});

  useEffect(() => {
    const scenario = props.currentScenario;
    if (scenario) {
      if (!scenario.offsetStartYear) scenario.offsetStartYear = '';
      if (!scenario.offsetReductionPercent) {
        scenario.offsetReductionPercent = '';
      }
      setScenario(scenario);
    }
  }, [props.currentScenario]);

  return (
    <Row className="justify-content-md-center">
      Offset Start Year: {scenario.offsetStartYear}
      &nbsp;&nbsp;&nbsp;&nbsp; Offset Reduction:{' '}
      {scenario.offsetReductionPercent}%
    </Row>
  );
}
export default OffsetCard;
