export interface IMenuItem {
  key?: string;
  title: string;
  href?: string | null;
  devOnly?: boolean | null;
  roles: string[];
  dropdownItems?: IMenuItem[] | null;
}
// this function filters the menu items based on the user's role
// in file ./src/utils/menuFilter.ts
const menuFilter = (menuItems: IMenuItem[], role: string) => {
  const filteredMenuItems: IMenuItem[] = [];
  menuItems.forEach((item) => {
    let dropdowns: IMenuItem[] = [];
    if (item.dropdownItems) {
      if (item.roles.includes(role)) {
        dropdowns = menuFilter(item.dropdownItems, role);
      }
    }
    if (item.roles.includes(role)) {
      const newItem: IMenuItem = {
        title: item.title,
        roles: item.roles,
        key: item.key,
      };
      // following items are optional
      // not all menuItems have these properties
      if (dropdowns.length > 0) {
        newItem.dropdownItems = dropdowns;
      }
      if (item.href) {
        newItem.href = item.href;
      }
      if (item.devOnly) {
        newItem.devOnly = item.devOnly;
      }
      filteredMenuItems.push(newItem);
    }
  });
  return filteredMenuItems;
};

export default menuFilter;
