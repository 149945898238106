import React from 'react';
import DataTable from 'react-data-table-component';
// import { formatNumNavigator } from "../../utils/format";
import { customListStyles } from '../../utils/customStyles';

function EquipmentTypeList(props) {
  const columns = [
    {
      name: 'Key',
      selector: (row) => row.key,
      sortable: true,
      left: true,
      reorder: true,
    },
    {
      name: 'Equipment',
      selector: (row) => row.name,
      sortable: true,
      left: true,
      reorder: true,
    },
    {
      name: 'Fuel Type',
      selector: (row) => row.fuelType,
      sortable: true,
      center: true,
      reorder: true,
    },
    {
      name: 'Emission Type',
      selector: (row) => row.emissionType,
      sortable: true,
      right: true,
      minWidth: '175px',
      reorder: true,
    },
  ];

  let selected = false;
  let ScrollHeight = '65vh';

  if (props.selected) {
    selected = true;
  }

  if (props.ScrollHeight) {
    ScrollHeight = props.ScrollHeight;
  }

  return (
    <DataTable
      columns={columns}
      data={props.equipmentTypeList}
      defaultSortFieldId={1}
      // sortIcon={<SortIcon />}
      pagination
      paginationPerPage={25}
      paginationRowsPerPageOptions={[25, 50, 100, 250, 500, 1000]}
      noHeader
      fixedHeader
      fixedHeaderScrollHeight={ScrollHeight}
      highlightOnHover
      selectableRows={selected}
      onSelectedRowsChange={props.onSelectedRowsChange}
      dense
      customStyles={customListStyles}
    />
  );
}

export default EquipmentTypeList;
