import React from 'react';
import DataTable from 'react-data-table-component';
import { customListStyles } from '../../utils/customStyles';

function MessageLogList(props) {
  const { messages } = props;
  const columns = [
    {
      name: 'time',
      id: 'datetime',
      selector: (row) => row.datetime,
      left: true,
      compact: true,
      sortable: true,
      // AWS Log Date format: 2022-06-13T18:58:09.236-06:00
      cell: (row) => row.datetime.toISOString(),
    },
    {
      name: 'message',
      id: 'message',
      selector: (row) => row.message,
      left: true,
      compact: true,
      sortable: true,
    },
    {
      name: 'type',
      selector: (row) => row.type,
      center: true,
      compact: true,
      sortable: true,
    },
  ];

  const ScrollHeight = '65vh';
  const expandableComponent = (obj) => <div>{obj.data.message}</div>;

  return (
    <DataTable
      columns={columns}
      data={messages}
      defaultSortFieldId="datetime"
      defaultSortAsc={false}
      pagination
      paginationPerPage={10}
      paginationRowsPerPageOptions={[5, 10, 25, 100]}
      fixedHeader
      fixedHeaderScrollHeight={ScrollHeight}
      highlightOnHover
      responsive
      pointerOnHover
      persistTableHead
      // selectableRows
      dense
      customStyles={customListStyles}
      expandableRows
      expandableRowsComponent={expandableComponent}
    />
  );
}

export default MessageLogList;
