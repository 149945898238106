import React, { useState } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import GroupMacc from '../../../plots/GroupMacc';
import GroupedProjectsList from '../../../lists/GroupedProjectsList';
import ProjectEditModal from '../../../popups/ProjectEditModal';
import { ProjectState } from '../../../../data/ProjectState.js';

const committed = ProjectState.Committed;

let modalConfirmMsg = '';

function GroupedCard(props) {
  const [sorter, setSorter] = useState({
    sortBy: 'firstYearAbatementCost',
    direction: 'asc',
  });
  const [showProjectEditModal, setShowProjectEditModal] = useState(false);
  const [selectedProjects, setSelectedProjects] = useState([]);

  const commitprojects = () => {
    modalConfirmMsg = `Are you sure you want to edit ${selectedProjects.length} project(s)?`;
    setShowProjectEditModal(true);
  };

  const onConfirm = (propertiesToSet) => {
    setShowProjectEditModal(false);
    props.onProjectEdit(selectedProjects, propertiesToSet);
  };

  const onDecline = () => {
    setShowProjectEditModal(false);
  };

  const handleClick = (r) => {
    const selectedProjects = projects.filter(
      (projectGroup) => projectGroup.key === r
    );
    props.handleClick(selectedProjects[0]);
  };

  const handleShowAll = () => {
    // Null Will display all project Groups
    props.handleClick();
  };

  const { label, cardID, projects, selectOneOnly } = props;

  const ungroupProjects = (projectGroups) => {
    const selProjects = projectGroups.reduce(
      (temp, projectGroup) => temp.concat(projectGroup.projectList),
      []
    );
    setSelectedProjects(selProjects);
  };

  return (
    <>
      {showProjectEditModal && (
        <ProjectEditModal
          show={showProjectEditModal}
          setStatusTo={committed}
          includeDate
          onConfirm={onConfirm}
          onDecline={onDecline}
          message={modalConfirmMsg}
        />
      )}
      <Row>
        <Col>
          <h2 className="mt-3 mb-3">{label}</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <GroupMacc
            title=""
            cardID={cardID}
            projects={projects}
            onClick={handleClick}
            sorter={sorter}
            extraHooverRows=""
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="d-flex flex-row-reverse bd-highlight p-3">
            <Button
              className="btn btn-lg mx-1 btn_min--width  save__btn"
              onClick={commitprojects}
            >
              Add to Scenario
            </Button>
            <Button
              className="btn btn-lg mx-1 btn_min--width  save__btn"
              onClick={handleShowAll}
            >
              Show all Project Groups
            </Button>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <GroupedProjectsList
            cardID={cardID}
            projects={projects}
            onClickRow={handleClick}
            onSort={setSorter}
            onSelect={ungroupProjects}
            selectOneOnly={selectOneOnly}
          />
        </Col>
      </Row>
    </>
  );
}

export default GroupedCard;
