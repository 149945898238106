import React from 'react';
import { TableColumn } from 'react-data-table-component';
import { ScenarioStats } from '../../../dataAPI/scenarioFuncs';
import AppDataTable from '../../utilities/AppDataTable';

interface IProps {
  scenarios: ScenarioStats[];
}

function ScenarioStatsTable({ scenarios }: IProps) {
  const columns: TableColumn<ScenarioStats>[] = [
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
      width: '300px',
    },
    {
      name: 'Number of Projects',
      selector: (row) => row.projectCount,
      sortable: true,
    },
    {
      name: 'Baseline Emissions (tCO2e)',
      selector: (row) => row.lifetimeBaselineEmissions,
      cell: (row) => Math.round(row.lifetimeBaselineEmissions).toLocaleString(),
      sortable: true,
    },
    {
      name: 'Total Emissions Reduction (tCO2e)',
      selector: (row) => row.lifetimeEmissionReduction,
      cell: (row) => Math.round(row.lifetimeEmissionReduction).toLocaleString(),
      sortable: true,
    },
    {
      name: 'Total Emissions (tCO2e)',
      selector: (row) => row.lifetimeEmissions,
      cell: (row) => Math.round(row.lifetimeEmissions).toLocaleString(),
      sortable: true,
    },
    {
      name: 'First Year Abatement Cost',
      selector: (row) => row.firstYearAbatementCost,
      cell: (row) =>
        (Math.round(row.firstYearAbatementCost * 100) / 100).toLocaleString(),
      sortable: true,
    },
    {
      name: 'Lifetime Abatement Cost',
      selector: (row) => row.lifetimeAbatementCost,
      cell: (row) =>
        (Math.round(row.lifetimeAbatementCost * 100) / 100).toLocaleString(),
      sortable: true,
    },
    {
      name: 'Net Cost ($)',
      selector: (row) => row.netCost,
      cell: (row) => Math.round(row.netCost).toLocaleString(),
      sortable: true,
    },
    {
      name: 'Cap Cost ($)',
      selector: (row) => row.capCost,
      cell: (row) => Math.round(row.capCost).toLocaleString(),
      sortable: true,
    },
    {
      name: 'Carbon Tax Savings ($)',
      selector: (row) => row.carbonTaxSavings,
      cell: (row) => Math.round(row.carbonTaxSavings).toLocaleString(),
      sortable: true,
    },
  ];
  return <AppDataTable columns={columns} data={scenarios} />;
}

export default ScenarioStatsTable;
