import { EquipmentView, SiteView } from '../../../dataAPI/Views';

export default function equipmentPieChartDataBuilder(
  data: SiteView[] | EquipmentView[],
  name: string,
  hole: number
) {
  const values: number[] = [];
  const labels: string[] = [];

  data.forEach((datum) => {
    if (datum.yearlyEmissions && datum.type) {
      values.push(datum.yearlyEmissions);
      labels.push(datum.type.substring(0, 30));
    }
  });

  return [
    {
      values,
      labels,
      hole,
      hoverinfo: '',
      type: 'pie',
    },
  ];
}
