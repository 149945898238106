import React, { useEffect, useContext, useState } from 'react';

import { Button, Container, Row } from 'react-bootstrap';
import { DataAPIContext } from '../../contexts/data';
import LoadingSpinner from '../utilities/LoadingSpinner';

import SiteTypeTableList from '../lists/SiteTypeTableList';
import RpList from '../utilities/RpList';
import { saveExcel } from '../../data/io/saveData';
import { appPubSub, Topic } from '../../utils/AppPubSub';
import ProjectMacc from '../plots/ProjectMacc';
import RpModal from '../utilities/RpModal';

class DataFromTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: 'options',
      // isLoading: true,
    };
  }

  render() {
    const { show } = this.state;
    return (
      <div className="d-flex flex-column justify-content-center h-100">
        {show === 'options' && (
          <InventoryOptions
            onSimulation={() => this.setState({ show: 'simulation' })}
            onShare={() => this.setState({ show: 'share' })}
          />
        )}

        {show === 'simulation' && (
          <InventoryGenerator
            onBack={() => this.setState({ show: 'options' })}
          />
        )}
        {show === 'share' && (
          <RpList onGoBackClick={() => this.setState({ show: 'options' })} />
        )}
      </div>
    );
  }
}
export default DataFromTemplate;

/**
 * InventoryOptions
 */
function InventoryOptions(props) {
  const { dataClient, unpackResponse, addNewMessage } =
    useContext(DataAPIContext);

  const inputFileRef = React.createRef();

  const [isLoading, setIsLoading] = useState(false);

  const [projects, setProjects] = useState([]);

  const [showMacc, setShowMacc] = useState(false);
  const [showBigMacc, setShowBigMacc] = useState(false);

  async function uploadPathwaysData(file) {
    await unpackResponse(dataClient.loadPathwayDataAPI(file));
    // setHeaderName(file.name);
    appPubSub.publish(Topic.Header, { name: file.name, scenarioName: '' });
  }

  async function uploadClairifiData(file) {
    await unpackResponse(dataClient.loadData(file, 'clairifi'));
    appPubSub.publish(Topic.Header, { name: file.name, scenarioName: '' });
  }

  async function uploadPetrinexData(file) {
    await unpackResponse(dataClient.loadData(file, 'petrinex'));
    appPubSub.publish(Topic.Header, { name: file.name, scenarioName: '' });
  }

  async function uploadProjectDirect(file) {
    await unpackResponse(dataClient.loadData(file, 'projectDirect'));
    appPubSub.publish(Topic.Header, { name: file.name, scenarioName: '' });
  }

  async function uploadMaccDirect(file) {
    // console.log(file);
    const results = await unpackResponse(dataClient.loadMaccDirect(file));
    if (results && results.length) {
      setShowMacc(true);
    } else {
      addNewMessage('"macc" tab not found or no valid data.', 'error');
    }

    // console.log(results);
    setProjects(results);
  }

  async function excelPrepareClientDownloadDataAPI() {
    const jsonData = await unpackResponse(
      dataClient.prepareClientDownloadDataAPI()
    );
    const json = JSON.parse(jsonData);
    saveExcel(json, 'data.xlsx');
  }

  async function onFileChange(e) {
    const file = e.target.files[0];

    // So the same file can be loaded multiple times.
    e.target.value = null;

    setIsLoading(true);

    if (file) {
      uploadProcess(file);
    }

    addNewMessage('File uploaded.', 'success');

    setIsLoading(false);
  }

  let uploadProcess = uploadPathwaysData;

  function handleShowBigMacc() {
    setShowBigMacc(true);
  }

  return (
    <Container className="relative mt-5">
      <h2 className="m-3 mb-5">Inventory Planner</h2>
      <div className="r-border s-bg">
        {isLoading && <LoadingSpinner />}
        <div className="d-flex justify-content-center flex-row flex-wrap gap-1 pb-5 pt-5">
          <Button
            className="btn  btn-lg btn_min--width save__btn"
            onClick={() => {
              uploadProcess = uploadPathwaysData;
              inputFileRef.current.click();
            }}
          >
            Local RP Load
          </Button>
          <Button
            className="btn  btn-lg btn_min--width save__btn"
            onClick={() => {
              excelPrepareClientDownloadDataAPI();
            }}
          >
            Local RP Save
          </Button>
          <Button
            className="btn  btn-lg btn_min--width save__btn"
            onClick={() => props.onShare()}
          >
            Remote Load
          </Button>
          <Button
            className="btn  btn-lg btn_min--width save__btn"
            onClick={() => {
              uploadProcess = uploadClairifiData;
              inputFileRef.current.click();
            }}
          >
            Clairifi Load
          </Button>
          <Button
            className="btn  btn-lg btn_min--width save__btn"
            onClick={() => {
              uploadProcess = uploadPetrinexData;
              inputFileRef.current.click();
            }}
          >
            Petrinex Load
          </Button>
          {/* Remove Appcontext and move logic to backend data API */}
          <Button
            className="btn  btn-lg btn_min--width save__btn"
            onClick={() => props.onSimulation()}
          >
            Generate Data
          </Button>
          <Button
            className="btn  btn-lg btn_min--width save__btn"
            onClick={() => {
              uploadProcess = uploadProjectDirect;
              inputFileRef.current.click();
            }}
          >
            Project Emissions and Cost Input
          </Button>
          <Button
            className="btn  btn-lg btn_min--width save__btn"
            onClick={() => {
              uploadProcess = uploadMaccDirect;
              inputFileRef.current.click();
            }}
          >
            MACC Direct
          </Button>
        </div>
        <div className="d-flex justify-content-center flex-row">
          <input
            type="file"
            name="filename"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .json"
            ref={inputFileRef}
            // onClick={this.onClick}
            onChange={onFileChange}
            className="d-none"
          />
        </div>
      </div>
      {showMacc && (
        <Row className="mb-3 mt-3">
          <Row className="table_container r-border">
            <RpModal show={showBigMacc} setShow={setShowBigMacc}>
              <ProjectMacc
                title="Direct MACC"
                cardID="active-projects"
                projects={projects}
                // onClick={(r) => console.log(r)}
                // colorMap={colorMap}
                colorMap={{}}
              />
            </RpModal>
            <ProjectMacc
              title="Direct MACC"
              cardID="active-projects"
              projects={projects}
              onClick={(r) => console.log(r)}
              colorMap={{}}
              // colorMap={colorMap}
              handleShowClick={handleShowBigMacc}
            />
          </Row>
        </Row>
      )}
    </Container>
  );
}
/**
 * InventoryGenerator
 */
function InventoryGenerator(props) {
  const { dataClient, unpackResponse } = useContext(DataAPIContext);
  const [siteTypeList, setSiteTypeList] = useState();
  const [siteTypeEquipmentProfile, setSiteTypeEquipmentProfile] = useState();

  useEffect(() => {
    loadSiteTypeList();
  }, []);

  const loadSiteTypeList = async () => {
    const siteTypeList = await unpackResponse(dataClient.getSiteTypeList());
    const siteTypeEquipmentProfile = await unpackResponse(
      dataClient.siteTypeEquipmentProfile()
    );
    setSiteTypeList(siteTypeList);
    setSiteTypeEquipmentProfile(siteTypeEquipmentProfile);
  };

  async function onGenerate() {
    await unpackResponse(dataClient.generateFromTemplate());
  }

  const updateSiteType = async (siteType, equipmentType, value) => {
    await unpackResponse(
      dataClient.updateSiteType(siteType, equipmentType, value)
    );
  };

  return (
    <>
      <div className="d-flex justify-content-center flex-row ">
        <h2 className="text-center">Generate Inventory</h2>
      </div>
      {siteTypeList ? (
        <SiteTypeTableList
          siteTypeList={siteTypeList}
          siteTypeEquipmentProfile={siteTypeEquipmentProfile}
          updateSiteType={updateSiteType}
          editableColumns
          editableCount
        />
      ) : null}
      <div className="d-flex justify-content-center flex-row ">
        <Button className="btn  btn-lg btn_min--width" onClick={onGenerate}>
          Generate
        </Button>
        <Button
          className="btn  btn-lg btn_min--width"
          onClick={() => props.onBack()}
        >
          Back
        </Button>
      </div>
    </>
  );
}
