import { TableStyles } from 'react-data-table-component/dist/src/DataTable/types.js';

export const customListStyles: TableStyles = {
  table: {
    style: {
      marginTop: '0',
      padding: '5px 10px',
      borderBottom: 'none',
      borderRadius: '4px',
      height: 'auto',
      width: 'auto',
    },
  },
  rows: {
    style: {
      height: '32px',
      minHeight: '102px',
      width: '100%',
    },
  },
  headCells: {
    style: {
      paddingLeft: '8px', // override the cell padding for head cells
      paddingRight: '8px',
      whiteSpace: 'normal',
    },
  },
  cells: {
    style: {
      paddingLeft: '16px',
      paddingRight: '16px',
      color: 'var(--text-primary)',
    },
  },
};

export const customTableStyles: TableStyles = {
  table: {
    style: {
      marginTop: '0',
      padding: '5px 10px',
      borderBottom: 'none',
      borderRadius: '4px',
      height: 'auto',
      width: 'auto',
    },
  },
  rows: {
    style: {
      height: '32px',
      minHeight: '102px',
      width: '100%',
    },
  },
  headCells: {
    style: {
      paddingLeft: '8px', // override the cell padding for head cells
      paddingRight: '8px',
      whiteSpace: 'normal',
    },
  },
};
