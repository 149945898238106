import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { ProjectTypeView, ProjectView } from '../../../dataAPI/Views';

const columns = [
  {
    name: 'Key',
    selector: (row: IType) => row.key,
    sortable: true,
    reorder: true,
    grow: 10,
    minWidth: '150px',
    cell: (row: IType) => row.key,
  },
  {
    name: 'Name',
    selector: (row: IType) => row.name,
    sortable: true,
    reorder: true,
    grow: 10,
    minWidth: '550px',
    cell: (row: IType) => row.name,
  },
  {
    name: 'Projects',
    selector: (row: IType) => row.count,
    sortable: true,
    right: true,
    reorder: true,
  },
  {
    name: 'Annual Emissions Reduced (tCO2e/year)',
    selector: (row: IType) => row.annualReduction,
    cell: (row: IType) => Math.round(row.annualReduction).toLocaleString(),
    sortable: true,
    right: true,
    reorder: true,
  },
  {
    name: 'Cost',
    selector: (row: IType) => row.cost,
    cell: (row: IType) => Math.round(row.cost).toLocaleString(),
    sortable: true,
    right: true,
    reorder: true,
    minWidth: '150px',
  },
];

interface ITypeObj {
  [key: string]: IType;
}

interface IType {
  key: string;
  name: string;
  annualReduction: number;
  cost: number;
  count: number;
  selected: boolean;
}

interface ILookup {
  [key: string]: string;
}

export interface IPTProps {
  projects: ProjectView[];
  projectTypes: ProjectTypeView[];
  selectedProjectTypes: string[];
  onUpdate: Function;
}

function ProjectTypes({
  projects,
  projectTypes,
  selectedProjectTypes,
  onUpdate,
}: IPTProps) {
  const [usedProjectTypes, setUsedProjectTypes] = useState<IType[]>([]);

  useEffect(() => {
    const ptLookup: ILookup = {};
    projectTypes.forEach((pt) => {
      ptLookup[pt.key] = pt.name;
    });
    const lookup = (type: string) => ptLookup[type];

    const types: ITypeObj = {};
    projects.forEach((p) => {
      if (!(p.projectType in types)) {
        types[p.projectType] = {
          key: p.projectType,
          name: lookup(p.projectType),
          annualReduction: 0,
          cost: 0,
          count: 0,
          selected: selectedProjectTypes.includes(p.projectType), // selects row true/false
        };
      }
      const info = types[p.projectType];
      info.annualReduction += p.annualReduction;
      info.cost += p.cost;
      info.count += 1;
    });

    setUsedProjectTypes(Object.values(types));
  }, [selectedProjectTypes]);

  function onChange(selected: any) {
    onUpdate(selected.selectedRows);
  }

  return (
    <div className="mt-3">
      <DataTable
        title="Specific Project Types?"
        data={usedProjectTypes}
        columns={columns}
        defaultSortFieldId={1}
        dense
        pagination
        paginationPerPage={10}
        paginationRowsPerPageOptions={[10, 100]}
        selectableRows
        selectableRowSelected={(row) => row.selected}
        onSelectedRowsChange={onChange}
      />
    </div>
  );
}
export default ProjectTypes;
