import React from 'react';
import { Navigate } from 'react-router-dom';
import { isAdminAccount } from '../../security/security';
import menuSearch from '../../utils/searchMenu';
import menuItems from './topNavBar/menuItems2';

export type ProtectedRouteProps = {
  children: JSX.Element;
};

export default function PrivateRoute({ children }: ProtectedRouteProps) {
  const isAdmin = isAdminAccount();

  const childTypeName = children.type.name;
  const menuItem = menuSearch(menuItems, childTypeName);

  if (isAdmin) {
    // admin can see all pages
    return children;
  }

  if (menuItem) {
    return menuItem.roles.includes('user') ? children : <Navigate to="/404" />; // user can see pages with user role
  }
}
