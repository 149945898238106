import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { ScenarioStats } from '../../dataAPI/scenarioFuncs';
// import {Card} from "react-bootstrap";
import ScoreCard from './ScoreCard';

interface IScenarioStats {
  scenarioStats: ScenarioStats;
  header: { baseYear: number; endYear: number };
}

function ScoreBoard({ scenarioStats, header }: IScenarioStats) {
  if (!scenarioStats) return <Row />;
  return (
    <Col>
      <Row className=" mt-3 p-3 r-border s-bg">
        <ScoreCard
          name={`${header.baseYear} Baseline Emissions`}
          value={scenarioStats.annualBaselineEmissions}
          units="tCO2e / year"
        />
        <ScoreCard
          name={`${header.endYear} Post Project Emissions`}
          value={scenarioStats.annualEmissions}
          units="tCO2e / year"
        />
        <ScoreCard
          name="Reduced Emissions"
          value={scenarioStats.annualReduction}
          units="tCO2e / year"
        />
        <ScoreCard
          name="Reduction"
          value={scenarioStats.annualReductionPercentage}
          units="%"
        />
        <ScoreCard
          name="Capital Cost"
          value={scenarioStats.capCost}
          currency="$"
        />
        <ScoreCard
          name="Total Carbon Tax Savings"
          value={scenarioStats.carbonTaxSavings}
          currency="$"
        />
        <ScoreCard
          name="Number of Projects"
          value={scenarioStats.projectCount}
        />{' '}
        <ScoreCard
          name="Offsets Start"
          value={scenarioStats.offsetStartYear}
          units="Year"
          noThousandSeperator
        />
        <ScoreCard
          name="Offsets Cost Total"
          value={scenarioStats.lifetimeOffsetCostTotal}
          currency="$"
        />
        <ScoreCard
          name="Scenario cost"
          value={scenarioStats.scenarioCost}
          currency="$"
        />
        <ScoreCard
          name="Scope 1 Emissions"
          value={scenarioStats.scope1}
          units="tCO2e / year"
        />
        <ScoreCard
          name="Scope 2 Emissions"
          value={scenarioStats.scope2}
          units="tCO2e / year"
        />
        {/* <ScoreCard name="Lifetime Baseline Emissions" value={scenarioStats.lifetimeBaselineEmissions} units="tCO2e" /> */}
        {/* <ScoreCard name="Lifetime Reduced Emissions" value={scenarioStats.lifetimeEmissionReduction} units="tCO2e" /> */}
        {/* <ScoreCard name="Lifetime Reduction %" value={scenarioStats.lifetimeReductionPercentage} units="%" /> */}
        {/* <ScoreCard name="Lifetime Abatement Cost" value={scenarioStats.lifetimeAbatementCost} units="$/tCO2e" /> */}
        {/* <ScoreCard name="Net Cost" value={scenarioStats.netCost} units="$" /> */}
        {/* {scenarioStats.costIncludesCarbonTax ? ( */}
        {/* <ScoreCard name="First Year Abatement Cost" value={scenarioStats.firstYearAbatementCost} units="$/tCO2e" /> */}
        <ScoreCard
          name="Vent Volume"
          value={scenarioStats.annualVentingVol}
          units="m3 / year"
        />
        <ScoreCard
          name="Flare Volume"
          value={scenarioStats.annualFlareVol}
          units="m3 / year"
        />
        <ScoreCard
          name="Fuel Gas Volume"
          value={scenarioStats.annualFlareVol}
          units="m3 / year"
        />
        <ScoreCard
          name="Electricity Used"
          value={scenarioStats.annualElecUsage}
          units="kWh / year"
        />
        <ScoreCard
          name="Diesel Volume"
          value={scenarioStats.annualDieselConsumption}
          units="m3 / year"
        />
        <ScoreCard
          name="Propane Volume"
          value={scenarioStats.annualPropaneConsumption}
          units="m3 / year"
        />
        <ScoreCard
          name="Gasoline Volume"
          value={scenarioStats.annualGasolineConsumption}
          units="m3 / year"
        />
      </Row>
    </Col>
  );
}

export default ScoreBoard;
