import * as React from 'react';
import { Col, Button, Form, Row } from 'react-bootstrap';
import { useState, useEffect } from 'react';

function ScenarioMenu(props) {
  const [mode, setMode] = useState('');
  const selectedVal = props.currentScenario;
  useEffect(() => {
    setMode(props.mode);
  }, []);
  const onSelect = (r) => {
    props.onSelect(r);
  };
  const onNew = () => {
    props.onNew();
  };
  const onEdit = () => {
    props.onEdit(selectedVal);
  };
  const onDelete = () => {
    props.onDelete(selectedVal);
  };
  const onChangeMode = () => {
    const m = mode === 'Scenario' ? 'Generate' : 'Scenario';
    setMode(m);
    props.onSetMode(m);
  };

  function ScenarioSelector(props) {
    return (
      <Form.Group className="m-2">
        <Form.Select
          as="select"
          value={props.selectScenario}
          onChange={props.onChange}
        >
          {props.scenarios.map((s) => (
            <option key={s.key} value={s.key}>
              {s.name}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
    );
  }
  return (
    <Row>
      <Col sm={6}>
        <ScenarioSelector
          selectScenario={props.currentScenario}
          scenarios={props.scenarios}
          onChange={onSelect}
        />
      </Col>
      <Col sm={6}>
        <Button className=" btn-lg mx-1 save__btn" onClick={onEdit}>
          Edit Scenario
        </Button>
        <Button className=" btn-lg mx-1  save__btn" onClick={onNew}>
          New Scenario
        </Button>
        <Button className=" btn-lg mx-1 cancel__btn" onClick={onDelete}>
          Delete Scenario
        </Button>{' '}
        <Button
          className=" btn-lg mx-1 save__btn float-end"
          onClick={onChangeMode}
        >
          {mode === 'Scenario' ? 'Show Generation' : 'Show Scenario'}
        </Button>
      </Col>
    </Row>
  );
}

export default ScenarioMenu;
