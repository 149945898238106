import React, { useEffect, useState } from 'react';
import Plotly from 'plotly.js-dist-min';
import createPlotlyComponent from 'react-plotly.js/factory';
import { Button } from 'react-bootstrap';
import { ScenarioTimeseriesView } from '../../dataAPI/Views';
import { getConfig, getLayout } from './Layouts';
import { Dark24 } from '../../utils/Colors';

const Plot = createPlotlyComponent(Plotly);

const colors = [...Dark24];

interface IScenarioTS {
  scenarioNames: string[];
  timeseries: ScenarioTimeseriesView[];
  requestedSeries: {
    emissions: boolean;
    cost: boolean;
    carbonTax: boolean;
    offsets: boolean;
  };
  showlegend?: boolean;
}

ScenarioTimeseries.defaultProps = {
  showlegend: true,
};

interface ITimeseries {
  x: number[];
  y: number[];
  legendgroup?: string;
  line: { dash: string; width: number; color?: string };
  xaxis?: string;
  yaxis?: string;
  showlegend?: boolean;
  name: string;
}

interface IGraphs {
  emissions: ITimeseries[];
  cost: ITimeseries[];
  carbonTax: ITimeseries[];
  offsets: ITimeseries[];
}

const isDarkMode = () => {
  const app = document.getElementById('app');
  const mode = app?.getAttribute('data-theme');
  if (mode) {
    return mode;
  }
  return 'light';
};

export default function ScenarioTimeseries({
  timeseries,
  scenarioNames,
  requestedSeries,
  showlegend,
}: IScenarioTS) {
  const [inputs, setInputs] = useState({
    emissions: requestedSeries.emissions,
    cost: requestedSeries.cost,
    carbonTax: requestedSeries.carbonTax,
    offsets: requestedSeries.offsets,
  });
  const [theme, setTheme] = useState(isDarkMode());
  // const showlegend = true;

  useEffect(() => {
    setTheme(isDarkMode());
  });

  useEffect(() => {
    setInputs(requestedSeries);
  }, [inputs]);

  const [timeseriesCumulative, setTimeseriesCumulative] = useState(true);

  const cumulativeSum = (valArray: number[]): number[] => {
    let startval = 0;
    return valArray.map((v) => {
      const outVal = startval + v;
      startval = outVal;
      return outVal;
    });
  };

  function generateTimeseriesData(
    _scenarioName: string,
    _timeseries: ScenarioTimeseriesView,
    isCumulative: boolean,
    color: string,
    groupIndex: number
  ) {
    // const { baselineEmissions, emissions, period, capitalCost, carbonTax } = _timeseries;
    const { emissions, period, capitalCost, carbonTax, offsetsCostTotal } =
      _timeseries;

    // let baselineEmissionsTS: number[] = [];
    let emissionsTS: number[] = [];
    let capitalCostTS: number[] = [];
    let carbonTaxTS: number[] = [];
    let offsetsTS: number[] = [];

    if (isCumulative) {
      // baselineEmissionsTS = cumulativeSum(baselineEmissions);
      emissionsTS = cumulativeSum(emissions);
      capitalCostTS = cumulativeSum(capitalCost);
      carbonTaxTS = cumulativeSum(carbonTax);
      offsetsTS = cumulativeSum(offsetsCostTotal);
    } else {
      // baselineEmissionsTS = [...baselineEmissions];
      emissionsTS = [...emissions];
      capitalCostTS = [...capitalCost];
      carbonTaxTS = [...carbonTax];
      offsetsTS = [...offsetsCostTotal];
    }

    const genSeries: IGraphs = {
      emissions: [
        {
          x: period,
          y: emissionsTS,
          xaxis: 'x',
          yaxis: 'y',
          line: {
            dash: 'solid',
            color,
            width: 3,
          },
          showlegend,
          name: `${_scenarioName}`,
          legendgroup: `group${groupIndex}_0`,
        },
      ],
      cost: [
        {
          x: period,
          y: capitalCostTS,
          xaxis: 'x',
          yaxis: 'y',
          line: {
            dash: 'solid',
            color,
            width: 3,
          },
          showlegend,
          name: `${_scenarioName}`,
          legendgroup: `group${groupIndex}_1`,
        },
      ],
      offsets: [
        {
          x: period,
          y: offsetsTS,
          xaxis: 'x',
          yaxis: 'y',
          line: {
            dash: 'solid',
            color,
            width: 3,
          },
          showlegend,
          name: `${_scenarioName}`,
          legendgroup: `group${groupIndex}_1`,
        },
      ],
      carbonTax: [
        {
          x: period,
          y: carbonTaxTS,
          xaxis: 'x',
          yaxis: 'y',
          line: {
            dash: 'solid',
            color,
            width: 3,
          },
          showlegend,
          name: `${_scenarioName}`,
          legendgroup: `group${groupIndex}_1`,
        },
      ],
    };
    return genSeries;
  }

  // --- Run on render ---
  // let timeseriesData: ITimeseries[] = [];
  const timeseriesData: IGraphs = {
    emissions: [],
    cost: [],
    carbonTax: [],
    offsets: [],
  };

  timeseries.forEach((_, idx) => {
    const newLine = generateTimeseriesData(
      scenarioNames[idx],
      timeseries[idx],
      timeseriesCumulative,
      colors[idx],
      idx
    );
    timeseriesData.emissions = [
      ...timeseriesData.emissions,
      ...newLine.emissions,
    ];
    timeseriesData.cost = [...timeseriesData.cost, ...newLine.cost];
    timeseriesData.carbonTax = [
      ...timeseriesData.carbonTax,
      ...newLine.carbonTax,
    ];
    timeseriesData.offsets = [...timeseriesData.offsets, ...newLine.offsets];
  });

  const LAYOUT_EMISSIONS = getLayout(
    'Emissions',
    'Year',
    'Emissions (tCO\u2082e)',
    true,
    false,
    '',
    1,
    theme
  );
  const LAYOUT_CAPITAL = getLayout(
    'Capital Expense',
    'Year',
    'Capital Expense ($)',
    true,
    false,
    '',
    1,
    theme
  );
  const LAYOUT_OFFSETS = getLayout(
    'Offsets',
    'Year',
    'Offset Cost ($)',
    true,
    false,
    '',
    1,
    theme
  );

  const LAYOUT_CARBON_TAX = getLayout(
    'Carbon Tax Savings',
    'Year',
    'Carbon Tax Savings ($)',
    true,
    false,
    '',
    1,
    theme
  );

  return (
    <div className="chart__container r-border">
      <div className="btn__container">
        <Button
          className="chart__toggle-btn btn btn-primary"
          onClick={() => setTimeseriesCumulative(!timeseriesCumulative)}
        >
          {timeseriesCumulative ? 'Switch to By Year' : 'Switch to Cumulative'}
        </Button>
      </div>
      {timeseries.length > 0 ? (
        <>
          {inputs.emissions && (
            <Plot
              data={timeseriesData.emissions}
              layout={LAYOUT_EMISSIONS}
              useResizeHandler
              config={getConfig()}
            />
          )}
          {inputs.cost && (
            <Plot
              data={timeseriesData.cost}
              layout={LAYOUT_CAPITAL}
              useResizeHandler
              config={getConfig()}
            />
          )}
          {inputs.carbonTax && (
            <Plot
              data={timeseriesData.carbonTax}
              layout={LAYOUT_CARBON_TAX}
              useResizeHandler
              config={getConfig()}
            />
          )}
          {inputs.offsets && (
            <Plot
              data={timeseriesData.offsets}
              layout={LAYOUT_OFFSETS}
              useResizeHandler
              config={getConfig()}
            />
          )}
        </>
      ) : (
        <p>No data selected</p>
      )}
    </div>
  );
}
