/**
 *
 * Utilities to convert NTS to DLS... Info has come from:
 * https://www.ihsenergy.ca/support/documentation_ca/Harmony_Enterprise/2018_1/content/html_files/ref_materials/general_concepts/gis_theory/gis_theory.htm#NTS
 *
 * Lots of code, but it was testable... 2023-03-16 Larry
 */

type LookupRowColT = { [key: string | number]: [number, number] };
type RawT = Array<Array<string | number>>;

export const rawMapArea: RawT = [
  ['M', 'N', 'O', 'P'],
  ['L', 'K', 'J', 'I'],
  ['E', 'F', 'G', 'H'],
  ['D', 'C', 'B', 'A'],
];

export const rawMapSheet: RawT = [
  [13, 14, 15, 16],
  [12, 11, 10, 9],
  [5, 6, 7, 8],
  [4, 3, 2, 1],
];

export const rawQuarterUnits: RawT = [
  ['C', 'D'],
  ['B', 'A'],
];

const mapSheetRowCol = rawToRowColMap(rawMapSheet);
const mapAreaRowCol = rawToRowColMap(rawMapArea);
const quarterUnitsRowCol = rawToRowColMap(rawQuarterUnits);

export function ntsToLatLong(location: string) {
  // dlsMapperInstance();
  const [quarterUnit, unit, block, series, mapArea, mapSheet] =
    location.split(/[.,/ -]/);

  const seriesRow = parseInt(series) % 10;
  const seriesCol = Math.floor(parseInt(series) / 10);
  const seriesLatWidth = 4;
  const seriesLongWidth = 8;
  const serieslat = 40 + seriesLatWidth * seriesRow;
  const serieslong = -48 - seriesLongWidth * seriesCol;

  // Map Area
  const [mapAreaRow, mapAreaCol] = mapAreaRowCol[mapArea as string];
  const mapAreaLatWidth = seriesLatWidth / 4;
  const mapAreaLongWidth = seriesLongWidth / 4;
  const mapAreaLat = mapAreaLatWidth * mapAreaRow;
  const mapAreaLong = mapAreaLongWidth * mapAreaCol;

  // Map Sheet
  const [mapSheetRow, mapSheetCol] = mapSheetRowCol[parseInt(mapSheet)];
  const mapSheetLatWidth = mapAreaLatWidth / 4;
  const mapSheetLongWidth = mapAreaLongWidth / 4;
  const mapSheetLat = mapSheetLatWidth * mapSheetRow;
  const mapSheetLong = mapSheetLongWidth * mapSheetCol;

  // Block
  const [blockRow, blockCol] = mapAreaRowCol[block]; // mapArea and block are the same 1 less row...
  const blockLatWidth = mapSheetLatWidth / 3;
  const blockLongWidth = mapSheetLongWidth / 4;
  const blockLat = blockLatWidth * blockRow;
  const blockLong = blockLongWidth * blockCol;

  // Units
  const [unitRow, unitCol] = calcUnitRowCol(unit);
  const unitLatWidth = blockLatWidth / 10;
  const unitLongWidth = blockLongWidth / 10;
  const unitLat = unitLatWidth * unitRow;
  const unitLong = unitLongWidth * unitCol;

  // Quarter Units
  const [quarterUnitRow, quarterUnitCol] = quarterUnitsRowCol[quarterUnit];
  const quarterUnitLatWidth = unitLatWidth / 2;
  const quarterUnitLongWidth = unitLongWidth / 2;
  const quarterUnitLat = quarterUnitLatWidth * quarterUnitRow;
  const quarterUnitLong = quarterUnitLongWidth * quarterUnitCol;

  const lat =
    Math.round(
      (serieslat +
        mapAreaLat +
        mapSheetLat +
        blockLat +
        unitLat +
        quarterUnitLat +
        0.002083) *
        1000
    ) / 1000;
  const long =
    Math.round(
      (serieslong -
        mapAreaLong -
        mapSheetLong -
        blockLong -
        unitLong -
        quarterUnitLong -
        0.003125) *
        1000
    ) / 1000;

  return [lat, long];
}

/**
 * Given a 2D array of values return a map that will return the row and column from the
 * bottom left corner.
 * @param rawMap
 */
export function rawToRowColMap(rawMap: RawT): LookupRowColT {
  const rows = rawMap.length;
  const cols = rawMap[0].length;
  const rowColMap: LookupRowColT = {};

  rawMap.forEach((row, rowi) => {
    row.forEach((item, coli) => {
      rowColMap[item] = [rows - 1 - rowi, cols - 1 - coli];
    });
  });

  return rowColMap;
}

export function calcUnitRowCol(unit: string): [number, number] {
  return [Math.floor((parseInt(unit) - 1) / 10), (parseInt(unit) - 1) % 10];
}
