import * as XLSX from 'xlsx';

/**
 * Generic save to excel
 */
export function saveExcel(downloadData, fileName) {
  const wb = XLSX.utils.book_new();
  Object.keys(downloadData).forEach((key) => {
    let hasKey = false;
    let ws;
    const entity = downloadData[key];
    if (entity && entity.length > 0 && 'key' in entity[0]) hasKey = true;
    if (hasKey) {
      // if the entity has a key / name we want it in the first two columns
      ws = XLSX.utils.json_to_sheet(entity, {
        header: ['key', 'name'],
        skipHeader: false,
      });
    } else {
      ws = XLSX.utils.json_to_sheet(entity, { skipHeader: false });
    }
    XLSX.utils.book_append_sheet(wb, ws, key);
  });

  XLSX.writeFile(wb, fileName);
}

export function auditReportToExcel(downloadData, fileName) {
  const wb = XLSX.utils.book_new();
  let ws = XLSX.utils.aoa_to_sheet(downloadData.report);
  XLSX.utils.book_append_sheet(wb, ws, 'projects');
  ws = XLSX.utils.aoa_to_sheet(downloadData.mooReport);
  XLSX.utils.book_append_sheet(wb, ws, 'moo');
  XLSX.writeFile(wb, fileName);
}

/**
 * Download data utility.
 *
 * @param {string} content to be downloaded
 * @param {string} filename name to call the file
 * @param {string} contentType one option is 'text/plain'
 */

export function downloadToFile(content, filename, contentType) {
  const a = document.createElement('a');
  const file = new Blob([content], { type: contentType });

  a.href = URL.createObjectURL(file);
  a.download = filename;
  a.click();

  URL.revokeObjectURL(a.href);
}
