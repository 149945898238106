import React from 'react';
import { Route } from 'react-router-dom';
import menuItems, { HighwoodRoutes } from './topNavBar/menuItems2';

import Home from '../entities/Home';
import NotFound from '../entities/NotFound';

import PrivateRoute from './PrivateRoute';
import { flattenMenu } from '../../utils/searchMenu';

const navSwitches = flattenMenu(menuItems).map((item) => {
  const el = (
    <PrivateRoute key={`${item.key}pr`}>
      {HighwoodRoutes[item.component]}
    </PrivateRoute>
  );
  return <Route key={`${item.key}_r`} path={item.href} element={el} />;
});

// const navSwitches = flattenMenu(menuItems).map((item) => (
//   <Route
//     key={`${item.key}_r`}
//     path={item.href}
//     element={
//       <PrivateRoute key={`${item.key}pr`}>
//         {HighwoodRoutes[item.component]}
//       </PrivateRoute>
//     }
//   />
// ));

navSwitches.push(<Route key="home_r" path="/" element={<Home />} />);
navSwitches.push(<Route key="not-found_r" path="*" element={<NotFound />} />);
export default navSwitches;
