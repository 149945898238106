import React, { useState } from 'react';
import { Button, Col, FormControl, Row } from 'react-bootstrap';

// larry@highwoodemissions.com, douglas@highwoodemissions.com, nathan@highwoodemissions.com
interface ShareAddUserProps {
  addUserToList: (users: string[]) => void;
}

export default function ShareAddUser({ addUserToList }: ShareAddUserProps) {
  const [commaSeparatedUsers, setCommaSeparatedUsers] = useState('');

  const onAddUserClickHandler = () => {
    const regMapObj: { [key: string]: string } = {
      ' ': '',
      '\n': '',
      ';': ',',
    };

    if (commaSeparatedUsers !== '') {
      const usersList = commaSeparatedUsers
        .replace(/\s|\n|;/gi, (matched: string) => regMapObj[matched])
        .split(',');
      addUserToList(usersList);
      setCommaSeparatedUsers('');
    }
  };

  const onchangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement;
    setCommaSeparatedUsers(value);
  };

  return (
    <div className="">
      <div>
        <h3 className="mt-5 mb-5">Users</h3>
        <Row>
          <Col className="col-11">
            <h6>
              Enter Email Address(
              <span className="fst-italic font-monospace">s</span>):
            </h6>
            <FormControl
              rows={3}
              placeholder="Enter email addresses separated by commas"
              name="commaSeparatedUsers"
              value={commaSeparatedUsers}
              onChange={onchangeHandler}
              as="textarea"
              aria-label="With textarea"
            />
          </Col>
          <Col className="col-1 d-flex align-items-end d-flex justify-content-center">
            <Button
              onClick={onAddUserClickHandler}
              className="btn btn-lg mx-1 btn_min--width save__btn"
            >
              Add Users
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
}
