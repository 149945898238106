import React from 'react';
// import { useState } from "react";
import { Button } from 'react-bootstrap';

interface INewUserProps {
  onRequestSecurityCodeClick: () => void;
  onChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onCancelClick: () => void;
  firstName: string;
  lastName: string;
  email: string;
}
function NewUser({
  firstName,
  lastName,
  email,
  onRequestSecurityCodeClick,
  onCancelClick,
  onChangeHandler,
}: INewUserProps) {
  return (
    <div className="m-1">
      <h3 className="text-center">New User</h3>
      <div className="col">
        <div className="control">
          <label htmlFor="lastName">
            <span>First Name:</span>
            <input
              onChange={onChangeHandler}
              name="firstName"
              type="text"
              placeholder="First Name"
              value={firstName}
            />
          </label>
        </div>
        <div className="control">
          <label htmlFor="lastName">
            <span>Last Name:</span>
            <input
              onChange={onChangeHandler}
              name="lastName"
              type="text"
              placeholder="Last Name"
              value={lastName}
            />
          </label>
        </div>
        <div className="control">
          <label htmlFor="email">
            <span>Email:</span>
            <input
              onChange={onChangeHandler}
              name="email"
              type="text"
              placeholder="Email"
              value={email}
            />
          </label>
        </div>
        <div className="d-flex">
          <Button
            className="btn-lg m-1 w-50 save__btn"
            type="button"
            onClick={onRequestSecurityCodeClick}
          >
            Request Security Code
          </Button>
          <Button
            onClick={onCancelClick}
            className="btn-lg m-1 w-50 save__btn"
            type="button"
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
}

export default NewUser;
